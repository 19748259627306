import React, { CSSProperties } from 'react'
import ReactSelect, { StylesConfig } from 'react-select'
import { useObserver } from 'mobx-react-lite'
import { OptionProps } from 'react-select/src/components/Option'

const black = '#232427'
const white = 'white'
const grey = '#CCC'
const greySel = '#CCC'
const disabled = '#CCC'
export interface SelectProps {
  onChange: (e: any) => void
  options?: object[]
  className?: string
  placeholder?: string
  border?: string
  borderRadius?: string
  backgroundColor?: string
  name?: string
  error?: boolean
  preventResizingOnOpen?: boolean
  noOptionsMessage?: string
  onChangeMembership?: (arg0: any) => void
  isSearchable?: boolean
  origin?: string
  onMenuOpen?: () => void
  onMenuClose?: () => void
  menuIsOpen?: boolean
  hasOptions?: boolean
  value?: { label: string | undefined; value: string | number | undefined }
}
export const CustomSelector = ({
  onChange,
  placeholder,
  options,
  name,
  className,
  border,
  value,
  backgroundColor,
  borderRadius,
  error,
  preventResizingOnOpen,
  noOptionsMessage,
  isSearchable,
  origin,
  onChangeMembership,
  onMenuOpen,
  onMenuClose,
  hasOptions,
  menuIsOpen
}: SelectProps) => {
  const selectStyle: StylesConfig<any, any> = {
    control: (provided: CSSProperties, state) => ({
      ...provided,
      border: error ? `1px solid red` : border ? border : `1px solid ${black}`,
      boxShadow: 'none',
      width: '100%',
      '&:hover': {
        border: border ? border : `1px solid ${black}`,
      },
      backgroundColor: backgroundColor ? backgroundColor : black,
      color: white,
      padding: 10,
      borderTopLeftRadius: borderRadius ? borderRadius : '1rem',
      borderTopRightRadius: borderRadius ? borderRadius : '1rem',
      borderBottomLeftRadius: !state.selectProps.menuIsOpen ? (borderRadius ? borderRadius : '1rem') : 0,
      borderBottomRightRadius: !state.selectProps.menuIsOpen ? (borderRadius ? borderRadius : '1rem') : 0,
      height: !preventResizingOnOpen && state.selectProps.menuIsOpen ? '50px' : '54px',
    }),
    noOptionsMessage: (base) => ({
      ...base,
      textAlign: 'left', // Alinea el mensaje a la izquierda
      color: '#e53e3e', // Color rojo para el mensaje
      marginLeft: '-12px',
    }),
    placeholder: (provided: CSSProperties) => ({
      ...provided,
      color: white,
    }),
    singleValue: (provided: CSSProperties) => ({
      ...provided,
      color: white,
    }),
    multiValue: (provided: CSSProperties) => ({
      ...provided,
      borderBottomRightRadius: borderRadius ? borderRadius : '1rem',
      borderBottomLeftRadius: borderRadius ? borderRadius : '1rem',
      padding: 10,
    }),
    menuList: (provided: CSSProperties) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: backgroundColor ? backgroundColor : black,
      borderBottomRightRadius: borderRadius ? borderRadius : '1rem',
      borderBottomLeftRadius: borderRadius ? borderRadius : '1rem',
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      marginTop: '-0',
      height: 'auto',
      borderBottomLeftRadius: borderRadius ? borderRadius : '1rem',
      borderBottomRightRadius: borderRadius ? borderRadius : '1rem',
      boxShadow: 'none',
      backgroundColor: backgroundColor ? backgroundColor : black,
    }),
    indicatorSeparator: (provided: CSSProperties) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (provided: CSSProperties) => ({
      ...provided,
      color: 'white',
      backgroundColor: 'transparent',
      width: 'auto',
      '&:hover': {
        color: 'white',
      },
    }),
    option: (provided: CSSProperties, state: OptionProps<any, any>) => {
      return {
        ...provided,
        backgroundColor: state.isDisabled
          ? disabled
          : state.isSelected
          ? grey
          : state.isFocused
          ? greySel
          : backgroundColor
          ? backgroundColor
          : black,
        color: state.isDisabled ? disabled : state.isSelected ? black : state.isFocused ? black : white,
        '&:hover': {
          color: state.isDisabled ? disabled : black,
        },

        borderBottom: '1px dashed rgba(80, 80, 80, .5)',
      }
    },
  }

  const getNoOptionsMessage = () => {
    return noOptionsMessage ? noOptionsMessage : "No options";
  };

  return useObserver(() => (
    <div className={className}>
      <ReactSelect
        options={options}
        name={name}
        placeholder={placeholder}
        id={name ? name : placeholder}
        value={value}
        onChange={(e) => {
          onChange(e)
        }}
        noOptionsMessage={getNoOptionsMessage}
        onChangeMembership={onChangeMembership}
        isSearchable={isSearchable}
        styles={selectStyle}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={menuIsOpen}
      />
    </div>
  ))
}
