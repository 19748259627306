import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FC, ReactElement, useState } from 'react';

interface CustomAccordionProps {
  array: { summary: string; body: any }[];
  style?: {
    borderRadius?: string;
    innerDivider?: string;
    borderStyle?: string;
    openColor?: string;
    closeColor?: string;
  };
  bodyStyle?: CSSProperties;
  summaryStyle?: CSSProperties;
  preventCloseOnOpen?: boolean;
  includeIconsBeforeBody?: boolean;
  bodyIcon?: ReactElement;
  customColor?: any;
  customIcon?: any;
  origin?: any;
}

const CustomAccordion: FC<CustomAccordionProps> = ({
  array,
  style,
  bodyStyle,
  summaryStyle,
  preventCloseOnOpen,
  includeIconsBeforeBody,
  bodyIcon,
  customColor,
  customIcon,
  origin
}: any) => {
  const iconToShow = customIcon ? faPlus : faAngleDown;
  const closeColor = customColor || '#232427';
  const openColor = customColor || '#555555'

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div id="custom-accordion" className="w-full">
      {array.map((element: { summary: string; body: JSX.Element[] }, index: number) => {
        const isOpen = openIndex === index;

        return (
          <div key={index} className="w-full">
           <div
              id={`custom-accordion-summary-${index}`}
              onClick={() => handleClick(index)}
              style={{
                backgroundColor: isOpen ? openColor : closeColor,
                borderRadius: style?.borderRadius,
                borderColor: style?.innerDivider,
                borderStyle: style?.borderStyle,
              }}
              className={`flex justify-between items-center bg-darkGray pl-5 ${
                index === 0 ? 'rounded-t-lg' : ''} ${index !== 0 && index !== array.length ? 'border-t border-white' : ''} ${isOpen ? 'accordion-open' : ''}`}
            >
              <h3 style={summaryStyle} className="text-xl my-3">
                {element.summary}
              </h3>
              {isOpen ? (
                <FontAwesomeIcon style={{ width:"0.525em" }} className={`${origin} text-xl mr-5`} icon={faTimes} />
              ) : (
                <FontAwesomeIcon style={{ width:"0.625em" }} className={`${origin} text-xl mr-5`} icon={iconToShow} />
              )}
            </div>
            {isOpen && (
              <div
                id={`custom-accordion-data-${index}`}
                className="w-full accordion-open"
                style={{
                  ...bodyStyle,
                  backgroundColor: isOpen ? openColor : closeColor,
                }}
              >
                {element.body.map((elem, index) => (
                  <div
                    key={index}
                    className="p-3 flex justify-start items-center gap-2 space-x-5 > 1 *"
                  >
                    {element.body.length > 1 && includeIconsBeforeBody && bodyIcon}
                    {elem}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CustomAccordion;
