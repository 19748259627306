import { AppNotification } from '../types'
import { observable, action, runInAction } from 'mobx'

export class NotificationStore {
  @observable
  notifications: AppNotification[] = []
  id = 0

  @action
  addNotification(title: string, desc: string = '', type: 'ok' | 'warn' | 'error' = 'ok') {
    const id = this.id++
    this.notifications.push({ id: id, type: type, title: title, desc: desc })
    setTimeout(() => {
      runInAction(() => {
        this.removeNotification(id)
      })
    }, 10000)
  }

  @action
  removeNotification(id: number) {
    this.notifications = this.notifications.filter((v) => v.id !== id)
  }
}
