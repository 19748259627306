import React from 'react'
import { Helmet } from 'react-helmet'


export const Legales = () => {
  return (
    <div className="px-8 flex flex-grow bg-gray-100 text-black py-20">
      <Helmet>
        <title>Términos y condiciones</title>
        <meta name="description" content="My new meta description" />
      </Helmet>
      <div className="container mx-auto mt-4">
        <div className="flex items-center justify-between">
          <h1 className="md:ml-4 sm:ml-0 text-2xl font-bold w-full text-center">TÉRMINOS Y CONDICIONES</h1>
        </div>
        <div className="md:ml-4 sm:ml-0 mt-6">
          <p>
            <b>
              1.0 SERVICIOS
            </b>
          </p>
          <p>
            Los  servicios  y/o  productos  que  Megatlon  ofrece  tienen  como  objetivo  mejorar  el bienestar  y  la  calidad  de  vida  de  las  personas  mediante  la  realización  de  actividades físicas y deportivas llevadas a cabo en las distintas sedes ofrecidas por Megatlon para la explotación de estas.
          </p>
          <p>
            <b>
              2.0 ACCESO A MEGATLON
            </b>
          </p>
          <p>
            De acuerdo con las condiciones de cada membresía/plan contratado, cada socio tendrá acceso  a  los  servicios  descriptos  en  los  términos  y  condiciones  al  momento  de  la contratación.
          </p>
          <p>
            <b>
              3.0 MEMBRESÍAS
            </b>
          </p>
          <p>
            <u>Classic:</u> Incluye el acceso a las sedes Almagro, Ateneo de la Juventud, Barracas, Barrio Norte, Caballito, Center, Devoto, Floresta, Racing Club y Villa Crespo.
          </p>
          <p>
            <u>Platino:</u> Incluye el acceso a las sedes Alto Palermo, Alto Rosario, Barrio Jardín, Belgrano, Center II, Distrito Arcos, Distrito Tecnológico, Gonnet, Martínez, Martínez II, Olivos, Pilar, Puerto Madero, Recoleta, Rosario, Córdoba Cerro, Córdoba Centro, Córdoba Barrio Jardín y todas las sedes de membresía Classic.
          </p>
          <p>
            <u>Black:</u> Incluye el acceso a las sedes Alcorta, La Imprenta, Núñez y todas las sedes de membresía Classic y Platino.
          </p>
          <p>
            <b>
              4.0 MODALIDAD DE CONTRATACION
            </b>
          </p>
          <p>
            <u>Anual o superior:</u> se efectúa por un plazo mínimo de 1 (un) año contado desde la fecha de  activación  por  parte  del  socio,  es  decir,  en  el  momento  que  comienza  a  utilizar  el servicio.   Solo   puede   abonarse   mediante   un   solo   pago   íntegro   de   la   modalidad contratada. Este plan no es susceptible de cancelación anticipada. Este plan supone, una vez  transcurrido  el  año,  la  continuidad  de  este  en  la  modalidad  mensual.  Vencido  el plazo de 1 (un) año inicial, al mes inmediato posterior se generarán cargos mensuales a la tarifa vigente a ese momento, que serán abonados mediante débito automático en la tarjera de débito o crédito autorizada por el socio al momento de contratar. Los precios mensuales  incluyen  todo  el  mes  en  curso,  no  pueden  fraccionarse  por  períodos  de tiempo menores a un (1) mes.
          </p>
          <p>
            <u>Mensual:</u> se  efectúa  por  un  plazo  indeterminado  de  tiempo;  renovándose  mes  a  mes con el pago de los montos correspondientes a cada período mensual. Se abona mes a mes en forma adelantada, mediante débito automático con tarjeta de débito o crédito.
          </p>
          <p>
            Los  precios  vigentes  son  establecidos  libremente  por  Megatlon,  y  podrán  variar  a  su exclusivo criterio, comunicándolo al socio con una antelación no menor a treinta (30) días  anteriores  al  de  su  entrada  en  vigor.  El  uso  del  servicio  luego  de  efectuadas  las modificaciones de precio implica la aceptación de estas.
          </p>
          <p>
            La falta de pago íntegro y en término de los cargos por el servicio, faculta a suspender automáticamente los mismos y el acceso a las sedes de Megatlon en forma inmediata; sin perjuicio de exigir la cancelación de las sumas adeudadas con más sus intereses y/o gastos administrativos que se hubieren generado; y eventualmente de comunicar tal situación a las empresas de análisis crediticios.
          </p>
          <p>
            <b>
              5.0 FORMAS DE PAGO
            </b>
          </p> 
          <p>
            <u>Pago mensual por débito automático:</u> Supone la cancelación del servicio contratado, en forma mensual y consecutiva mediante tarjeta de débito o crédito, de acuerdo con los plazos, términos y condiciones del plan seleccionado por el socio.
          </p> 
          <p>
            <u>Cancelación  total  en  pago  único:</u>  El socio pagará la totalidad del precio del PLAN contratado en único pago mediante débito en la tarjeta de débito o crédito del socio. Mediante esta modalidad de pago el socio contrata el derecho de uso del servicio por un plazo de 12 meses.
          </p>  
          <p>
            En el supuesto que el pago de la contratación del plan se realice con financiación a través del Programa “Ahora 12” del Gobierno Nacional, el socio verá reflejado total en su tarjeta de crédito dicho pago, en 12 (doce) cargos mensuales. Queda expresamente entendido y convenido que todos los débitos en la tarjeta de crédito del socio serán efectuados por la entidad emisora por cuenta y orden del Estado Nacional, no teniendo Megatlon ningún tipo de injerencia o control sobre dicha operatoria, toda vez que en la relación entre el socio y Megatlon el pago del plan es integral en un único cargo. En consecuencia, una vez vencido el plazo para ejercer el derecho de revocación la compra se considera conformada y no procederá ningún tipo de reintegro, aun cuando estuviera pendiente de débito una o más de las cuotas otorgadas por el Gobierno Nacional en el marco del Programa “Ahora 12”.
          </p>
          <p>
            <b>
              6.0 CANCELACIÓN DEL PLAN
            </b>
          </p> 
          <p>
            En las contrataciones bajo la modalidad anual se adquiere el derecho de uso del servicio ofrecido  por  Megatlon  en  un  plazo  mínimo  de  12  (doce)  meses,  por  lo  que  una  vez transcurrido el período de revocación establecido por el artículo 1110 del Código Civil y Comercial,  el  socio  no  podrá  solicitar  la  baja  o  cancelación  del  plan  contratado  hasta haberse cumplido íntegramente el período de 12 (doce) meses.
          </p> 
          <p>
            En todos los casos previsibles de cancelación, el socio deberá solicitar su cancelación en  forma  fehaciente.   El  plan  anual  contiene  una  bonificación  económica y por tanto implica la adquisición del derecho de uso del servicio por el plazo de 12 (doce) meses, motivo por el cual, una vez transcurrido el plazo de 10 (diez) días para ejercer el  derecho  de  revocación  o  arrepentimiento  conforme  se  establece  que  el  plan  no podrá ser cancelado bajo ningún concepto y de producirse contra cargos en la tarjeta de crédito informada, Megatlon se reserva el derecho de efectuar la correspondiente denuncia en el fuero criminal y el reclamo por los daños y pérdidas ocasionados.
          </p>  
          <p>
            Para  llevar  a  cabo  una  cancelación,  el  socio  debe  comunicarlo  a  Megatlon  con  una antelación mínima de treinta (30) días. En todos los casos tendrá efectos a partir del mes inmediatamente posterior al de la solicitud de la cancelación. El mes en el que se solicita  la  cancelación  se  encuentra  incluido  dentro  de  los  cargos  ya  abonados  por adelantado, pudiendo hacer uso del servicio hasta la finalización del mes.
          </p>
          <p>
            Megatlon podrá proceder a la cancelación del servicio, en el caso de que no se cumplan los términos y condiciones mencionados en este documento, falta de pago acordado, comunicándole en forma fehaciente tal situación al socio, con una anticipación mínima de treinta (30) días sin que ello genere un derecho resarcitorio en favor del socio.
          </p>
          <p>
            <b>
              7.0 DERECHO DE REVOCACIÓN
            </b>
          </p> 
          <p>
            Se notifica al socio su derecho de revocación al servicio contratado, en los términos del art. 1.110 código civil y comercial de la nación, el cual concede el derecho de revocar la contratación dentro del plazo de 10 días corridos contados a  partir de la contratación, sin ningún tipo de gasto, excepto cuando sea de aplicación lo establecido.
          </p> 
          <p>
            <b>
              8.0 REGLAS Y PAUTAS DE CONVIVENCIA
            </b>
          </p> 
          <p>
            El socio y/o sus adicionales se comprometen a cumplir las reglas y reglamentos, leyes y  decretos que se encuentren periódicamente vigentes, para el uso del servicio que se detallan a continuación:
          </p>
          <p>
            1. Cumplir con todas las pautas escritas que se publiquen en las sedes.
          </p>
          <p>
            2.  Cuidar  y  utilizar  las  instalaciones,  equipos  y  servicios  de  MEGATLON  de  manera  de responsable de forma tal que los socios podamos disfrutar de ellos.
          </p> 
          <p>
            3. Ordenar y devolver los equipos y accesorios al mismo lugar del cual fueron tomados.
          </p>
          <p>
            4. Utilizar los teléfonos celulares en el interior de las sedes en modo silencioso para no molestar a los demás socios.
          </p> 
          <p>
            5.  Utilizar  los  equipos  de  a  uno  a  la  vez  y  esperar  la  disponibilidad  de  las  máquinas respetando el orden de llegada y haciendo un uso razonable de las mismas.
          </p> 
          <p>
            6. Los socios declaran bajo juramento estar en plenas condiciones de salud para realizar actividades físicas.
          </p>
          <p>
            7. Los socios declaran bajo juramento no tener una enfermedad contagiosa que pueda perjudicar  a  terceros  y  asumen  plena  responsabilidad  en  caso  de  incumplir  con  dicha pauta,  obligándose  a  mantener  indemne  a  MEGATLON,  sus  empleados,  directores  y accionistas por cualquier reclamo originado en el incumplimiento de esta pauta.
          </p> 
          <p>
            8.  Está  prohibido  cualquier  conducta  que  sea  contraria  a  la  moral  y  a  las  buenas costumbres y que sea contraria a la pacífica convivencia entre los socios y el personal de cada una de las sedes, incluyendo sin limitación tener relaciones sexuales dentro de las sedes o sus vestuarios.  
          </p>  
          <p>
            9. Está prohibido comercializar bienes o servicios en las instalaciones.
          </p> 
          <p>
            10. Está prohibida la utilización de las sedes, instalaciones, maquinaria y/o equipos para un fin que sea distinto para el cual fueron concebidas: entrenamiento físico.
          </p> 
          <p>
            11. Está prohibido gritar, insultar o dirigirse de cualquier manera inapropiada respecto de los demás socios y empleados de la sede.
          </p> 
          <p>
            12.  Está  prohibido  agredir  de  cualquier  manera  a  otros  socios  y/o  empleados  de  las sedes.
          </p> 
          <p>
            13. Es obligatorio la utilización de ropa y calzado apropiados para la práctica deportiva y entrenamiento en general. Está prohibido ingresar a las sedes desnudo o con el torso descubierto.
          </p> 
          <p>
            14. No está permitido quedarse sentado en los equipos o máquinas sin utilizarlos cuando hay otros socios que los quieran utilizar.
          </p> 
          <p>
            15.  No  se  puede  comer,  fumar  o  ingerir  cualquier  tipo  de  drogas  dentro  de  las instalaciones de las sedes.
          </p>  
          <p>
            16. No está permitido entrar a las sedes con animales.          
          </p>     
          <p>
            17. Está prohibido ingresar a las sedes portando armas y/o explosivos de cualquier tipo.
          </p> 
          <p>
            18. Está prohibido el hurto o robo y la comisión de cualquier tipo de delitos.
          </p>
          <p>
            La enumeración de las pautas de convivencia es meramente enunciativa y no taxativa por lo que MEGATLON se reserva el derecho de ampliar, agregar, reducir o modificar las mismas de manera unilateral y conforme su misión, objeto y espíritu corporativo.
          </p>
          <p>
            En  caso  de  incumplimiento  con  las  pautas  de  convivencia  MEGATLON  podrá  aplicar cualquiera de las siguientes sanciones que estime convenientes: (i) llamado de atención; (ii) multa; (iii) suspensión; (iv) expulsión mediante la rescisión del contrato.
          </p>
          <p>
            Las sanciones podrán ser comunicadas por escrito, en forma verbal o de manera digital a  través  de  un  mail,  mensaje  de  texto  o  chat.  No  obstante  lo  cual,  en  ningún  caso,  la aplicación  de  cualquiera  de  las  sanciones  previstas  podrá  significar  que  MEGATLON renuncia  o  menoscabar  de  cualquier  manera  su  derecho  de  reclamar  por  los  daños  y perjuicios que la conducta del socio pudiera ocasionar.
          </p>
          <p>
            Sin  perjuicio  de  lo  expuesto,  MEGATLON  se  reserva  el  derecho  de  admisión  de  socios y/o  invitados  cuando  lo  estime  razonablemente  conveniente  y  a  su  exclusivo  criterio. Los   socios   utilizarán   las   instalaciones,   equipos   y   maquinarias   bajo   su   propia responsabilidad  y  se  obligan  a  mantener  indemne  y  liberaran  a  MEGATLON,  sus empleados, directores y accionistas de cualquier responsabilidad derivada de su uso.
          </p>
          <p>
            <b>
              9.0 USO DE DATOS PERSONALES 
            </b>
          </p> 
          <p>
            <b>
              SI SE ENCUENTRA UBICADO EN UNO DE LOS PAÍSES SIGUIENTES, SE APLICARÁN DETERMINADOS TÉRMINOS ESPECÍFICOS DEL PAÍS.
            </b>
          </p>
          <p>
            En Megatlon consideramos que la protección de los datos personales de nuestros usuarios es muy importante. En consecuencia, nos comprometemos a hacer un uso responsable de la información personal que nos brinden a fines de permitirles operar con seguridad y confianza en nuestra aplicación móvil.
          </p> 
          <p>
            Esta Declaración de Privacidad y Confidencialidad de la Información de Megalton (la "Declaración de Privacidad") describe la información que Megatlon recolecta y trata sobre cada usuario y lo que puede hacer con esa información.
          </p> 
          <p>
            Esta Declaración de Privacidad es parte integrante de los Términos y Condiciones Generales de Megatlon. Prestar tu consentimiento voluntario, expreso e informado a esta Declaración de Privacidad es un requisito esencial para poder contratar y/o tener cualquier tipo de relación con Megatlon.
          </p>
          <p>
            Esta Declaración de Privacidad es parte integrante de los Términos y Condiciones Generales de Megatlon. Prestar tu consentimiento voluntario, expreso e informado a esta Declaración de Privacidad es un requisito esencial para poder contratar y/o tener cualquier tipo de relación con Megatlon.
          </p> 
          <p>
            <b>
              <u>Argentina</u>
            </b>
          </p>
          <p>
            <b>
              A. Identidad y domicilio del Responsable del tratamiento de los Datos Personales.
            </b>
          </p>
          <p>
            En virtud de lo dispuesto por la Ley de Protección de Datos Personales N° 25.326 y demás disposiciones aplicables, GIMNASIOS ARGENTINOS S.A. (en adelante "Megatlon”), con domicilio para oír y recibir notificaciones, en Húsares 1984, Ciudad Autónoma de Buenos Aires, le informa que es el Responsable del uso y protección de sus datos personales, los cuales son tratados de forma estrictamente privada y confidencial. Por lo que la obtención, tratamiento y ejercicio de los derechos derivados de dicho tratamiento, se hace mediante un uso adecuado y legítimo, siempre bajo los principios de licitud, consentimiento, calidad, información, proporcionalidad, responsabilidad, lealtad y finalidad y los deberes de seguridad y confidencialidad establecidos en la Ley de Protección de Datos Personales.
          </p> 
          <p>
            En ese sentido, a continuación le indicamos de manera expresa y limitativa las categorías de datos personales que podremos recabar de usted como usuario de la aplicación:
          </p>
          <p>
            <b>
              B. Datos personales recabados y sometidos a tratamiento.
            </b>
          </p>
          <p>
            Para el desarrollo de las finalidades descritas en el presente Aviso de Privacidad, podremos recabar y solicitar las siguientes categorías de datos personales:
          </p>
          <p>
            1) Datos de contacto, incluyendo nombre, apellido, correo electrónico, número de teléfono y dirección de envío y facturación.
          </p>
          <p>
            2) Información de acceso y cuenta, incluyendo nombre de usuario, contraseña, ID de usuario único y MAC ID del celular.
          </p>
          <p>
            3) Datos personales incluyendo sexo, ciudad natal y fecha de nacimiento.
          </p>
          <p>
            4) Información de pago o tarjeta de crédito
          </p>
          <p>
            5) Imágenes, fotos y videos
          </p>
          <p>
            6) Datos sobre las características físicas, incluyendo el peso, la estatura y las medidas corporales
          </p>
          <p>
            6) Datos sobre las características físicas, incluyendo el peso, la estatura y las medidas corporales
          </p>
          <p>
            7) Datos de actividad física proporcionados por usted o generados a través de nuestra Aplicación (tiempo, duración, distancia, ubicación, cantidad de calorías)
          </p>
          <p>
            8) Preferencias personales incluyendo su lista de deseos, así como las preferencias de marketing y cookies.
          </p>
          <p>
            9) Datos de geolocalización.
          </p>
          <p>
            Asimismo, es importante informarle que no se recabarán datos personales considerados por la LEY DE PROTECCION DE DATOS PERSONALES como sensibles, para las finalidades enumeradas más adelante.
          </p>
          <p>
            <b>
             Datos personales de menores de edad.
            </b>
          </p>
          <p>
            Los servicios y/o productos que Megatlon ofrece a través de la presente aplicación, sólo se encuentran disponibles para aquellas personas que cuenten con la capacidad legal para contratar; por lo que Megatlon no recopilará datos de menores de edad, toda vez que no es de su interés, ni es acorde con sus Políticas internas contratar con personas que no cumplan con la condición de poder hacerlo legalmente. Por lo tanto, aquellas personas que no cumplan con dicha condición, deberán abstenerse de descargar la aplicación y proporcionar sus datos personales a Megatlon.
          </p>
          <p>
            Megatlon está comprometido con salvaguardar la seguridad de los datos personales de los titulares del presente aviso de privacidad, por lo que ha implementado diversas medidas de seguridad para protegerlos contra posibles vulneraciones de seguridad.
          </p>
          <p>
            <b>
              C. Finalidades del tratamiento.
            </b>
          </p>
          <p>
            Sus datos personales serán tratados exclusivamente para las siguientes finalidades:
          </p>
          <p>
            <b>
              a. Finalidades primarias.
            </b>
          </p>
          <p>
            1. Identificarlo como usuario de Megatlon.
          </p>
          <p>
            2. Creación, gestión, control y administración del registro y/o la cuenta del usuario.
          </p>
          <p>
            3. Darlo de alta en nuestros sistemas y/o bases de datos.
          </p>
          <p>
            4. Creación, gestión, control y administración del perfil del usuario.
          </p>
          <p>
            5. Hacer uso de las funcionalidades incluidas dentro de la aplicación.
          </p>
          <p>
            5. Hacer uso de las funcionalidades incluidas dentro de la aplicación.
          </p>
          <p>
            6. Brindarle acceso a la información contenida dentro de la aplicación.
          </p>
          <p>
            7. Informarle acerca de los servicios y/o productos, así como ofertas comerciales y/o beneficios ofrecidos por Megatlon, en el supuesto y momento que solicite dicha información, incluyendo sus modalidades y precios.
          </p>
          <p>
            8. Proporcionarle una cotización respecto de los servicios y/o productos ofrecidos por Megatlon en los que, en su caso, pudiese estar interesado.
          </p>
          <p>
            9. Facilitar la contratación de los servicios y/o productos ofrecidos por Megatlon en los que se encuentre interesado.
          </p>
          <p>
            10. Estadística y registro histórico de usuarios.
          </p>
          <p>
            Adicionalmente podremos tratar sus datos personales para las siguientes finalidades secundarias.
          </p>
          <p>
            <b>b. Finalidades secundarias.</b>
          </p>
          <p>
            1. En caso de que haya solicitado información respecto de los productos y/o servicios, ofertas comerciales y/o beneficios que Megatlon ofrece, y no se encuentre interesado en contratarlos en el momento y supuesto en que lo haya solicitado, posteriormente podremos enviarle actualizaciones sobre publicidad, promociones y/o información sobre nuevos productos y/o servicios, ofertas comerciales y/o beneficios que pudiesen interesarle, a través de distintos medios, como pueden ser vía telefónica, vía correo electrónico, vía chat (WhatsApp), vía SMS, así como a través de cualquier medio que solicite.
          </p>
          <p>
            Asimismo, Megatlon podrá captar, y registrar, la dirección MAC (Media Access Control) de los dispositivos móviles del Usuario, incluyendo, pero no imitándose a, teléfonos celulares, relojes inteligentes, tablets o notebooks. En este sentido, Usted acepta que su dirección MAC sea asociada a los datos personales incluidos por éste en la sección “Perfil” de la Aplicación Megatlon, y sea empleado para el envío de notificaciones y funcionalidades de la Aplicación Megatlon, así como para la realización y publicación de estudios estadísticos y de mercado.
          </p>
          <p>
            También es posible que utilicemos datos acerca de cómo usted utiliza nuestra aplicación para prevenir o detectar fraudes, abusos, usos ilegales e infracciones de nuestros Términos de uso y para cumplir con las órdenes judiciales, solicitudes gubernamentales o leyes aplicables.
          </p>
          <p>
            Al aceptar el presente aviso de privacidad, entendemos que nos otorga su consentimiento para que tratemos sus datos personales para la finalidad secundaria mencionada en el inciso b. del presente apartado.
          </p>
          <p>
            En caso de que Usted no desee que sus datos personales sean tratados para la finalidad mencionada en el inciso b. del presente apartado, Usted podrá, en todo momento, enviarnos un correo electrónico a la siguiente dirección: atencionalsocio@megatlon.com.ar dirigido a nuestro Departamento de Datos Personales, a efecto de que sus datos personales no sean tratados para tales fines.
          </p>
          <p>
            <b>
              c. Otras finalidades.
            </b>
          </p>
          <p>
            También podemos utilizar sus datos personales para otras finalidades y, para ello, le proporcionaremos un aviso específico en el momento de la recopilación y obtendremos su consentimiento cuando sea necesario.
          </p>
          <p>
            <b>
              D. Ejercicio de los derechos Protección de Datos Personales (Ley 25.326).
            </b>
          </p>
          <p>
            En todos aquellos casos legalmente procedentes, usted podrá ejercer en todo momento sus derechos de acceso, rectificación, cancelación y oposición (derechos Protección de Datos Personales) a través de los procedimientos que hemos implementado.
          </p>
          <p>
            La solicitud correspondiente deberá cumplir con los requisitos establecidos en la legislación vigente, mediante escrito dirigido a nuestro Departamento de Datos Personales, con domicilio en Húsares 1984, CABA, Argentina.
          </p>
          <p>
            La solicitud deberá contener y acompañar lo siguiente:
          </p>
          <p>
            I. Su nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud.
          </p>
          <p>
            II. Los documentos que acrediten su identidad o, en su caso, la representación legal.
          </p>
          <p>
            III. La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los Derechos de la Ley de Datos Personales; y
          </p>
          <p>
            IV. Cualquier otro elemento o documento que facilite la localización de los datos personales.
          </p>
          <p>
            Megatlon le comunicará, en un plazo máximo de veinte días hábiles, contados desde la fecha en que aquél reciba la solicitud correspondiente, la determinación adoptada. Si la solicitud resulta procedente, ésta se hará efectiva dentro de los quince (15) días hábiles siguientes a la fecha en que Megatlon Play le comunique la respuesta. En caso de que la información proporcionada en su solicitud resulte errónea o insuficiente, o no se acompañen los documentos necesarios para acreditar su identidad o la representación legal correspondiente, Megatlon, dentro de los cinco días hábiles siguientes a la recepción de su solicitud, requerirá la subsanación de las deficiencias para poder dar trámite a la misma. En estos casos, usted contará con diez días hábiles para atender el requerimiento de subsanación, contados a partir del día siguiente en que lo hubiere recibido. La solicitud correspondiente se tendrá por no presentada si usted no responde dentro de dicho plazo.
          </p>
          <p>
            Usted podrá obtener la información o datos personales solicitados a través de copias simples, documentos electrónicos en formatos convencionales (Word, PDF, etc.), mediante acceso restringido y autorizado al sistema que trata sus datos personales (acceso) o a través de cualquier otro medio legítimo que garantice y acredite el ejercicio efectivo del derecho solicitado.
          </p>
          <p>
            Alternativamente, podrá dirigir su solicitud a la dirección de correo electrónico: atencionalsocio@megatlon.com.ar, cumpliendo con todos los requisitos anteriormente enumerados, estableciendo como Asunto de la comunicación: “Protección de datos personales y/o Revocación del consentimiento”. Los plazos del procedimiento serán los mismos a que se refiere el párrafo inmediato anterior.
          </p>
          <p>
            Megatlon velará en todo momento por mantener actualizados sus datos personales, a efecto de que los mismos sean pertinentes y correctos para los fines para los cuales fueron recabados. Asimismo, y en caso de que usted actualice su información le solicitamos que, de manera oportuna, nos lo comunique.
          </p>
          <p>
            Asimismo, se les informa que la Agencia Nacional de Protección de Datos es la autoridad ejecutora de la Ley 25.326, y tiene potestad para atender los informes y reclamaciones sobre el incumplimiento de las disposiciones de protección de datos.
          </p>
          <p>
            <b>
              E. Revocación del consentimiento.
            </b>
          </p>
          <p>
            Usted podrá revocar su consentimiento para el tratamiento de sus datos personales, sin efectos retroactivos, en todos aquellos casos en que dicha revocación no suponga la imposibilidad de cumplir obligaciones derivadas de una relación jurídica vigente entre usted y Megatlon. El procedimiento para la revocación del consentimiento, en su caso, será el mismo que el establecido en el apartado inmediato anterior para el ejercicio de los derechos de la Ley Protección de Datos Personales (25.326).
          </p>
          <p>
            F. Limitaciones sobre la divulgación de sus datos personales.
          </p>
          <p>
            Le informamos que, con el objeto de que usted pueda limitar el uso o divulgación de sus datos personales, ponemos a su disposición la dirección de correo electrónico: atencionalsocio@megatlon.com.ar a efecto de ser inscripto por nuestro Departamento de Datos Personales en el listado de exclusión denominado “titulares sin fines secundarios”, a fin de que sus datos personales no sean tratados para las finalidades secundarias mencionadas en este documento.
          </p>
          <p>
            G. Medios automáticos para recabar datos personales
          </p>
          <p>
            Le informamos que para recabar sus datos personales como usuario de la aplicación móvil “Megatlon”, no se utilizarán mecanismos en medios remotos o locales de comunicación electrónica, óptica u otra tecnología, que permitan recabar sus datos personales de manera automática y simultánea al tiempo que usted hace contacto con los mismos.
          </p>
          <p>
            H. Modificaciones o actualizaciones a las presentes Políticas de Privacidad.
          </p>
          <p>
            Megatlon podrá modificar, actualizar, extender o de cualquier otra forma cambiar el contenido y alcance de las presentes Políticas de Privacidad. En tales casos, Megatlon comunicará dichos cambios a través de los canales establecidos en la presente aplicación y/o a través de la página web http://www.megatlon.com.ar, “Políticas de Privacidad Aplicación Megatlon”, y/o a través de correo electrónico si el titular y Megatlon han establecido este canal como medio de comunicación.
          </p>
          <p>
            <b>
              I. Protección y Gestión de sus Datos Personales.
            </b>
          </p>
          <p>
            Encriptación y Seguridad: Utilizamos una variedad de medidas de seguridad técnica y organizativa, incluyendo herramientas de encriptación y autenticación, para mantener la seguridad de sus datos personales. Sus datos personales están contenidos detrás de redes seguras y sólo son accesibles por un número limitado de personas que tienen derechos especiales de acceso a estos sistemas.
          </p>
          <p>
            Plazo de conservación de los Datos Personales: Conservamos sus datos personales durante el tiempo necesario para cumplir con las finalidades por las cuales los recogimos, salvo que la ley exija lo contrario.
          </p>
          <p>
            <b>
              J. Cambios a nuestra Política de Privacidad
            </b>
          </p>
          <p>
            La ley aplicable y nuestras prácticas pueden cambiar con el tiempo. Si decidimos actualizar nuestra Política, publicaremos los cambios en nuestra Aplicación. Si cambiamos materialmente la forma en que tratamos los datos personales, le proporcionaremos un aviso previo o, cuando sea legalmente necesario, solicitaremos su consentimiento antes de llevar a cabo dichos cambios. Le recomendamos encarecidamente que lea nuestra Política y se mantenga informado de nuestras prácticas.
          </p>
          <p>
            Los datos personales que se solicitan en el momento de la inscripción, o periódicamente, tienen como finalidad principalmente a) la individualización de los socios; b) la comunicación constante con ellos; c) administración y gestión comercial; d) mejora del servicio; e) fines estadísticos; f) envío de notificaciones, promociones o publicidad, entre otros. Los datos personales no serán difundidos ni empleados para un fin distinto o incompatible al tenido en cuenta al ser ingresados en las bases de datos de Megatlon; utilizándolos para todos los fines legales. 
          </p>
          <p>
            Cuando se le soliciten datos personales, se le informará en forma expresa y clara si esos datos solicitados son de carácter obligatorio o facultativo. Tenga en cuenta que ninguna persona puede ser obligada a proporcionar datos sensibles.
          </p>
          <p>
            Dichos datos se encontrarán registrados en la base de datos de la Empresa (GIMNASIOS ARGENTINOS S.A. - C.U.I.T Nº 30-68897938-9, domicilio Húsares 1984, CABA). A tales efectos, se encuentran registradas en la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, creada por la Ley N° 27.275 como Autoridad de Aplicación de la Ley de Protección de Datos Personales. El titular de los datos podrá solicitar el retiro, bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado, se le informará el nombre del responsable de la base de datos que proveyó la información. Asimismo, tiene el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que acredite un interés legítimo conforme lo establece el art. 14, inc. 3 de la Ley 25.326.
          </p>
          <p>
            Para contactar a la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA,
            cualquier interesado podrá dirigirse a la calle Av. Pte. Gral. Julio A. Roca 710, piso 2   -   Ciudad   Autónoma   de   Buenos   Aires   (C1067ABP)   o   por email: info@aaip.gob.ar.
          </p>
          <p>
            <b>
              10.0 CONSENTIMIENTO USO DE IMAGEN
            </b>
          </p>
          <p>
            Megatlon podrá publicar imágenes grupales capturadas en el desarrollo de las actividades que se realizan en las diferentes sedes para el uso de sus comunicaciones gráficas y/o digitales. El socio, en estos términos, autoriza en forma expresa e irrevocable, a perpetuidad y para todo el mundo a Gimnasios Argentinos S. A., para su marca “MEGATLON” la utilización, fijación y/o reproducción total o parcial de su imagen, voz y/o reacciones a fin de que la misma sea divulgada y/o incorporada con fines publicitarios comprometiéndose a no realizar en el futuro reclamo alguno a Gimnasios Argentinos S. A., para su marca “MEGATLON” relacionado con la autorización del uso de la imagen.
          </p>
          <p>
            <b>
              11.0 CONSENTIMIENTO PARA RECIBIR NOTIFICACIONES ELECTRONICAS
            </b>
          </p>
          <p>
            El socio solicita, acepta y autoriza a que cualquier información, aviso, notificación, modificación, revelación, promoción, publicidad, y/o cualquier otra comunicación que provengan de Megatlon y a los que se refieran estos Términos y Condiciones, y sus modificaciones, sea efectuada en forma electrónica a la dirección de correo electrónico indicada. De igual forma, el socio acuerda en que todas las notificaciones que Megatlon le entregue satisfacen cualquier requisito legal que sea necesario, como si dichas comunicaciones se hicieran por escrito. El socio se compromete a mantener actualizada su dirección de correo electrónico, y a notificar por escrito al Local cualquier modificación al respecto. La dirección de correo electrónica será válida y subsistente hasta tanto el socio notifique su modificación por escrito. 
          </p>
          <p>
            <b>
              12.0 CONFORMIDAD CON LOS TÉRMINOS Y CONDICIONES DE LA SUSCRIPCIÓN
            </b>
          </p>
          <p>
            Dejo expresa constancia de haber leído y comprendido los términos y condiciones del servicio y presto entera conformidad con la autorización de pago; con los términos y condiciones descriptos en el presente en cuanto al plan seleccionado, formas de cancelación, política de uso y reglamentos para el servicio que he seleccionado; sus términos y condiciones.
          </p>
        </div>
      </div>
    </div>
  )
}
