import React, { useEffect } from 'react'
import bannerPrincipal from '../../assets/banners/Banners home.jpg'
import bannerPrincipalMobile from '../../assets/banners/Banner-pricipal-mobile.jpg'
import sedes from '../../assets/banners/Banners-mira-sedes-y-horarios.jpg'
import asociate from '../../assets/banners/Banners-entrena-gratis-un-dia.jpg'
import { pageview } from 'react-ga'
import { Helmet } from 'react-helmet'

export const Home = () => {
  useEffect(() => {
    pageview('/')
  }, [])

  return (
    <div className="container flex flex-col mx-auto" style={{ paddingTop: '60px' }}>
      <NewsSlideShow />
    </div>
  )
}

const NewsSlideShow = (responsive: any) => {

  return (
    <div className="grid grid-cols-auto grid-rows-auto gap-3 mb-8">
      <Helmet>
        <title>Megatlon</title>
      </Helmet>
      <div className="md:col-start-1 md:col-end-3 row-start-1 row-end-2 w-full">
        <a href={'https://bit.ly/Mega_bot_webmega'}>
          <img alt="cover" className="hidden md:block object-cover" src={bannerPrincipal} />
        </a>
      </div>
      <div className="row-start-3 md:col-start-1 w-full">
        <a href='/#/sedes'>
          <img alt="nosotros-cover" className="object-cover" src={sedes} />
        </a>
      </div>
      <div className="row-start-4 md:row-start-3 md:col-start-2 w-full">
        <a href='/#/paseGratis'>
          <img alt="nosotros-cover" className="object-cover" src={asociate} />
        </a>
      </div>
      <div>
        <a href={'https://bit.ly/infowebmegatlon'}>
          <img alt="cover" className="md:hidden object-cover w-full" src={bannerPrincipalMobile} />
        </a>
      </div>
    </div>
  )
}
