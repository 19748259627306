import {AppStore} from './AppStore';
import {NotificationStore} from './NotificationStore';
import {action, observable, onBecomeObserved, onBecomeUnobserved, runInAction} from 'mobx';
import {withTokenGET, withTokenPOST} from '../utils';
import {Result} from '../lib/megaUsers';
import {
  CheckOutPaymentRequestDTO,
  CheckOutPromocionRequest,
  CheckOutSummaryResponseDTO,
  DecidirDTO,
  GetShoppingCartReq,
  ProvidersDTO
} from '../lib/megaStore';
import {DECIDIRRequest} from '../types';

const urls = {
  'getLastOrderId': '/api/service/checkout/lastOrder',
  'checkOutPayment': '/api/service/checkout/payment',
  'checkOutCodigo': '/api/service/checkout/promoCode',
  'checkOutSummary': '/api/service/checkout/summary',
  'dtoken': '/api/service/payment/public/key',
  "providers": "/api/service/payment/proveedores/list"

};

export class CheckOutStore {
  @observable
  summary: CheckOutSummaryResponseDTO = {
    iva: 0,
    total: 0,
    subtotal: 0,
    productos: [],
    promociones: []
  };

  @observable
  providers: ProvidersDTO[] =  [];

  @observable
  codigo: string = '';

  @observable
  facturar = false;

  @observable
  pagoState: 'IDLE' | 'PROCESSING' = 'IDLE';

  @observable
  showModal = false;

  @observable
  decidirToken? : string = "";

  constructor(private appStore: AppStore, private notificationStore: NotificationStore) {
    onBecomeObserved(this, 'summary', () => {this.refreshSummary()});
    onBecomeUnobserved(this, 'summary', () => {
      this.summary = {
        iva: 0,
        total: 0,
        subtotal: 0,
        productos: [],
        promociones: []
      }
    });



  }

  @action
  public refreshSummary() {
    this.checkOutSummary()
      .then(res => {
        if (res.code === 0) {
          this.summary = res.result;
        } else {
          throw new Error(res.message);
        }
      })
      .catch(e => {
        this.notificationStore.addNotification('Error', e.message, 'error');
      });

    this.getTokenRequest().then( res =>{
      if(res.code === 0){
        this.decidirToken = res.result.publicApiKey
      }else{
        this.decidirToken = "4ae76f00234843d1af5994ed4674fd76"
      }
    }).catch( err =>{
        this.notificationStore.addNotification('Error', "Error al tokenizar la tarjeta" + err, 'error');
    });

    this.getProviders().then( res =>{
      if(res.code === 0){
        console.log("Card Providers")
        console.log(res.result);
        runInAction(() =>{
          this.providers = res.result
        })
      }
    });
  }

  @action
  public setFactura(isFactura: boolean) {
    this.facturar = isFactura;
  }

  @action
  updateCodigo(codigo: string) {
    this.codigo = codigo;
  }

  @action
  public addCodigo() {
    this.checkOutCodigo({codigo: this.codigo})
      .then(res => {
        if (res.code === 0) {
          this.summary = res.result;
        } else {
          throw new Error(res.message);
        }
      })
      .catch(e => {
        this.notificationStore.addNotification('Error', e.message, 'error');
      });
    this.codigo = '';
  }

  @action
  public openModal() {
    this.showModal = true; 
  }

  @action
  public closeModal() {
    if (this.pagoState === 'PROCESSING') return;
    this.showModal = false;
  }

  @action
  public resetState() {
    this.pagoState = 'IDLE';
  }




  @action
  public async runDECIDIRFlow(payload: DECIDIRRequest, dni: string, cardProvider: number | undefined) {
    this.pagoState = 'PROCESSING';
    console.log(payload);
    const res: any = await fetch(`${process.env.REACT_APP_DECIDIR_SERVER}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'apikey': this.decidirToken !== undefined ?this.decidirToken:"4ae76f00234843d1af5994ed4674fd76"

      },
      body: JSON.stringify(payload)
    })
      .then(res => res.json());

    if (res === undefined) {
      this.resetState();
      throw new Error(res.status);
    }
    console.log("Full Payment Payload");

    this.providers.forEach(e => {
      console.log(e)


    });

    console.log("Card name ::")
    console.log(cardProvider);


    const paymentRes = await this.checkOutPayment({
      mes: payload.card_expiration_month,
      anio: payload.card_expiration_year,
      facturar: this.facturar,
      tokenPago: res.id,
      nombreTarjeta: payload.card_holder_name,
      seisPrimerosDigitos: payload.card_number.slice(0, 6),
      cuatroUltimosDigitos: payload.card_number.slice(-4),
      idProveedor: cardProvider,
      dni:dni

    });
    if (paymentRes.code !== 0) {
      this.resetState();
      throw new Error(paymentRes.message); 
    }

    this.notificationStore.addNotification('Pago exitoso');
    this.pagoState = 'IDLE';
    this.showModal = false;
  }

  async getLastOrderId(user: GetShoppingCartReq) {
    return await withTokenPOST(`${process.env.REACT_APP_STORE_SERVER}${urls.getLastOrderId}`, this.appStore.getToken, JSON.stringify(user));
  }

  async getTokenRequest() {
    return await withTokenGET<Result<DecidirDTO>>(`${process.env.REACT_APP_STORE_SERVER}${urls.dtoken}` + "?tipoGateway=DECIDIR".toString(), this.appStore.getToken);
  }

  async getProviders() {
    return await withTokenGET<Result<ProvidersDTO[]>>(`${process.env.REACT_APP_STORE_SERVER}${urls.providers}`, this.appStore.getToken);
  }

  async checkOutPayment(payment: CheckOutPaymentRequestDTO) {
    return await withTokenPOST<Result<boolean>>(`${process.env.REACT_APP_STORE_SERVER}${urls.checkOutPayment}`, this.appStore.getToken,
      JSON.stringify(payment));
  }

  async checkOutCodigo(codigo: CheckOutPromocionRequest) {
    return await withTokenPOST<Result<CheckOutSummaryResponseDTO>>(`${process.env.REACT_APP_STORE_SERVER}${urls.checkOutCodigo}`, this.appStore.getToken,
      JSON.stringify(codigo))
      .then(res => {
        if (res.code === 0) {
          res.result.promociones = [];
          return res;
        } else {
          return res;
        }
      });
  }

  async checkOutSummary() {
    return await withTokenGET<Result<CheckOutSummaryResponseDTO>>(`${process.env.REACT_APP_STORE_SERVER}${urls.checkOutSummary}`, this.appStore.getToken)
      .then(res => {
        if (res.code === 0) {
          res.result.promociones = [];

          return res;
        } else {
          return res;
        }
      });
  }
}
