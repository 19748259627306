import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { StoreContext, useStores } from '../../Context';
import { privateRoutes } from '../../routes';
import './SuccedePage.css';
import bannerKids from '../../assets/Kids-formulario.jpg';
import bannerKidsMobile from '../../assets/Kids-formulario-mobile.jpg';
import bannerTeens from '../../assets/Teens-formulario.jpg';
import bannerTeensMobile from '../../assets/Teens-formulario-mobile.jpg';
import logoMegatlon from '../../assets/Reduccion - 250X250.png';

const SucceedeKidsAndTeens = ({ userType }) => {
  const { KidsAndTeensStore } = useStores();
  const store = useContext(StoreContext);

  useEffect(() => {
    if (KidsAndTeensStore.name === undefined) {
      store.router.goTo(privateRoutes.home);
    }
  }, [KidsAndTeensStore.name, store.router]);

  return useObserver(() => (
    <div id={KidsAndTeensStore !== 'kids' ? "kids-and-teens-envio-teens" : "kids-and-teens-envio-kids"} className="kids-and-teens-success min-h-75 flex flex-col justify-center gap-5 items-center m-auto text-center">
      <img alt="cover" className='hidden md:block imagen-fondo' src={KidsAndTeensStore.membership !== 'kids' ? bannerTeens : bannerKids} />
      <img alt="cover" className='block md:hidden imagen-mobile' src={KidsAndTeensStore.membership !== 'kids' ? bannerTeensMobile : bannerKidsMobile} />
      <div className='success-text-container'>
        <div className="text-lg md:text-2xl font-extrabold">SOLICITUD ENVIADA CON ÉXITO</div>
        <div className="text-base md:text-xl mt-6 p-0 sm:p-0 sm:px-35">
          La sede te contactará a la brevedad para asesorarte<br/><br className="lg:hidden"/>
          ¡Muchas gracias!
        </div>
      </div>
      <img alt='logo' className="success-logo-megatlon" src={logoMegatlon} />
      <a className="success-mail" href='mailto:atencionalsocio@megatlon.com.ar'>atencionalsocio@megatlon.com.ar</a>
    </div>
  ));

};

export default SucceedeKidsAndTeens;