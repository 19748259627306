import {AppStore} from './AppStore';
import {ClubStore} from './ClubsStore';
import {UserStore} from './UserStore';
import {withTokenGET} from '../utils';
import {action, autorun, computed, observable, runInAction, when} from 'mobx';
import {BookedResponseDTO, CategoriaClaseDTO, ClasesLSDTO} from '../lib/megaClasses';
import {Result} from '../lib/megaUsers';
import {addDays, format, isBefore, parse, startOfDay} from 'date-fns';
import {es} from 'date-fns/locale';
import {NotificationStore} from "./NotificationStore";

const urls = {
  'disciplinesLs': '/api/service/class/special/discipline/list',
  'coachesLs': '/api/service/class/special/coach/list',
  'listLs': '/api/service/class/special/list',
  'getCategoriasClases': '/api/service/class/categories/club',
  'getClassesByIdClubAndIdCategory': '/api/service/class/club/category/list',
  'getClasses': '/api/service/class/club/list',
  'getClaseDetails': '/api/service/class/details',
  'getClaseEspecial': '/api/service/class/event/list',
  'getLink': '/api/service/class/special/{id}/link',
};

export class LSCStore {
  @observable
  clubSeleccionado: number = 42;

  @observable
  categoriasClases: CategoriaClaseDTO[] = [];

  @observable
  categoriaSeleccionada?: string;

  @observable
  clases: ClasesLSDTO[] = [];

  @observable
  claseDetail?: string;

  @observable
  claseDetailId: number | undefined;

  @observable
  urlStreaming?: string;




  @observable
  bookedClasses: BookedResponseDTO[] = [];

  constructor(private appStore: AppStore,
    private clubsStore: ClubStore,
    private userStore: UserStore,
              private notificationStore: NotificationStore) {

    /*onBecomeObserved(this, 'bookedClasses', async () => {
      await when(() => !!userStore.baseProfile);
      const res = await this.getBookedList();
      this.bookedClasses = res.result;
    });*/

    when(() => clubsStore.clubes.length > 0,
      () => {
        this.clubSeleccionado = this.clubsStore.clubes[0].clubId;
      }
    );

    autorun(() => {
      if (!this.appStore.token) return;
      this.getCategoriasClases(this.clubSeleccionado)
        .then(res => {
          runInAction(() => {
            this.categoriasClases = res.result;
          })
        })
    });

    autorun(() => {
      if (!this.appStore.token) return;
      this.getClasses()
        .then(res => {
          runInAction(() => {
            this.clases = res.result;
          })
        });
    });
  }

  @computed
  public get selectCategorias() {
    return this.categoriasClases.map(cc => ({value: cc.id, label: cc.nombre}));
  }

  @computed
  public get normalizedClasses() {
    return this.clases.map(c => {
      c.date = parse(c.fhClase, 'yyyy-MM-dd HH:mm:ss.S', new Date());
      return c
    })
  }

  @computed
  public get filteredClasses() {
    const today = startOfDay(new Date());
    const weekLater = addDays(today, 7);

    return this.normalizedClasses.filter(c => {
      if (this.categoriaSeleccionada && c.disciplina !== this.categoriaSeleccionada) return false;
      if (!isBefore(new Date(c.fhClase), weekLater)) return false;
      return true;
    })
  }

  @computed
  public get clasesDTOToClases() {
    return this.filteredClasses.map(c => {
      const disciplina = this.categoriasClases.find(cat => cat.nombre === c.disciplina);
      return {
        id: c.id,
        nombre: c.disciplina,
        imagen: c.refImagen,
        desc: disciplina?.descripcion,
        fecha: c.date,
        duracion: c.duracion,
        url: c.urlStreaming,
        fechaFormatted: format(new Date(c.fhClase), "EEE d 'de' LLLL", {locale: es}),
        horaFormatted: format(new Date(c.fhClase), 'p', {locale: es}),
        isExternalSource: c.isExternalSource
      }
    })
  }

  @action
  public setClubSelect(idClub: number) {
    this.clubSeleccionado = idClub;
  }

  @action
  public setDefaultClub() {
    this.clubSeleccionado = this.userStore.userClubOrDefault || 42;
  }

  @action
  public setCategoriaSelect(categoria: string) {
    this.categoriaSeleccionada = categoria;
  }

  @action
  public setDefaultCategoria() {
    this.categoriaSeleccionada = undefined;
  }

  @action
  public restablecer() {
    this.setDefaultClub();
    this.setDefaultCategoria();
  }

  @action
  public clearClaseDetail() {
    this.claseDetail = undefined;
  }

  @action
  public clearClaseDetailId() {
    this.claseDetailId = undefined;
  }

  @action
  public fetchClaseDetail(clase: any) {
    if (clase.url === null || clase.url === undefined) {
      this.notificationStore.addNotification("Ups!", "Esta clase aún no ha comenzado", "warn");
    } else if (clase.isExternalSource) {
      this.getLink(clase.id).then(response => {
        if (response.code === 0) {
          this.claseDetailId = clase.id;
          this.urlStreaming = response.result;
          this.claseDetail = "Open";
        } else {
          this.notificationStore.addNotification("Ups!", response.message);
        }
      }).catch(err => {
        this.notificationStore.addNotification("Ups!",
          "Ocurrió un error al obtener la clase en vivo, por favor intenta más tarde");
      });
    } else {
      this.claseDetailId = clase.id;
      this.urlStreaming = clase.url;
      this.claseDetail = "Open";
    }
  };



  /*async getCoaches(br: BookRequestDTO) {
    return await withTokenPOST<Result<boolean>>(`${process.env.REACT_APP_CLASSES_SERVER}${urls.coachesLs}`, this.appStore.getToken);
  }*/


  async getCategoriasClases(idClub: number) {
    return await withTokenGET<Result<CategoriaClaseDTO[]>>(`${process.env.REACT_APP_CLASSES_SERVER}${urls.disciplinesLs}`, this.appStore.getToken);
  }


  async getClasses() {
    return await withTokenGET<Result<ClasesLSDTO[]>>(`${process.env.REACT_APP_CLASSES_SERVER}${urls.listLs}?tipoClase=EN_VIVO`, this.appStore.getToken);
  }

  async getLink(id: number) {
    const getLinkUrl = urls.getLink.replace("{id}", id.toString());
    return await withTokenGET<Result<string>>(`${process.env.REACT_APP_CLASSES_SERVER}` + getLinkUrl, this.appStore.getToken);
  }
}
