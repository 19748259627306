import React, { useEffect } from 'react'
import { ClubResponse } from '../../lib/megaClasses'
import { useStores } from '../../Context'
import { Observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEnvelope, faMapMarkerAlt, faPhoneAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { pageview } from 'react-ga'
import { Helmet } from 'react-helmet'

export const Sedes = () => {
  const { clubsStore } = useStores()

  console.log(clubsStore);

  useEffect(() => {
    pageview('/sedes')
  }, [])

  return (
    <div className="flex flex-grow bg-black mb-10" style={{ paddingTop: '60px' }}>
      <Helmet>
        <title>Nuestras Sedes</title>
        <meta name="description" content="Horarios y actividades." />
      </Helmet>
      <Observer>
        {() => {
          return (
            <div className="container flex-grow pt-4 mx-auto ">
              <div className="m-4 flex flex-col justify-start lg:grid gap-4 grid-cols-1 grid-cols-4">
                <h1 className="ml-4 lg:mb-4 text-2xl font-bold col-start-1 col-end-3 text-center lg:text-left">SEDES</h1>
                <div
                  className="relative h-8 flex items-center rounded-xl col-start-4"
                  style={{ width: '100%', height: '45px', backgroundColor: '#232427' }}>
                  <FontAwesomeIcon icon={faSearch} className="text-white" style={{ marginLeft: '15px' }} />
                  <input
                    style={{ backgroundColor: '#232427', color: 'white' }}
                    type="search"
                    placeholder="Buscar"
                    className="w-full p-2 pl-4 outline-none rounded-xl"
                    onChange={(e) => clubsStore.filterBy(e.target.value, 'nombre')}
                  />
                </div>
              </div>
              <div className="m-4 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                {clubsStore.currentList.map((c) => (
                  <Sede key={c.clubId} {...c} />
                ))}
              </div>
            </div>
          )
        }}
      </Observer>
    </div>
  )
}

export const Sede = ({
  clubId,
  nombre,
  direccion,
  telefono,
  latitud,
  longitud,
  schedule,
  email,
  activityScheduleLink,
}: ClubResponse) => {

  return (
    <div className="relative overflow-hidden text-xs rounded-xxl" style={{ background: '#232427' }}>
      <div>
        <h2 className="mb-2 pt-5 pl-5 pr-5 text-xl font-bold md:truncate">
          {nombre.replace('Megatlon', '').toUpperCase()}
        </h2>
      </div>
      <hr style={{ width: '100%' }}></hr>
      <div className="flex flex-col text-sm p-5 justify-between" style={{ height: '220px' }}>
        <div className="sede-card-item">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white mr-3" />
          <a
            style={{ textDecoration: 'underline', color: 'white' }}
            href={`https://maps.google.com/?q=${latitud},${longitud}`}
            className="text-blue-400 cursor-pointer"
            target="_blank"
            rel="noopener noreferrer">
            {direccion}
          </a>
        </div>
        <div className="sede-card-item">
          {telefono && telefono !== "-" && (
            <>
              <FontAwesomeIcon icon={faPhoneAlt} className="text-white mr-3" />
              <a className="hover:underline" href={`tel:${telefono}`}>{telefono}</a>
            </>
          )}
        </div>
        <div className="sede-card-item">
          <FontAwesomeIcon icon={faCalendar} className="text-white mr-3" />
          {schedule ? schedule : '- - - - - - - -'}
        </div>
        <div className="sede-card-item">
          <FontAwesomeIcon icon={faEnvelope} className="text-white mr-3" />
          <a className="hover:underline" href={email ? `mailto:${email}` : '#'}>{email ? email : '- - - - - - - -'}</a>
        </div>
        <div>
          <div className="flex justify-center ">
            
            <a href={activityScheduleLink}>
              <button className="white-pill-button">VER GRILLA</button>
            </a>

          </div>
        </div>
      </div>
    </div>
  )
}
