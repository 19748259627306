import React, { useState, useContext, useCallback, useEffect } from 'react'
import { useSimpleForm } from '../../utils'
import { Input } from '../../components/Input'
import './Regreting.css'
import { Helmet } from 'react-helmet'
import { StoreContext, useStores } from '../../Context'
import { contactMessagePerson } from '../../lib/megaStore'
import { privateRoutes } from '../../routes'
import Frequentquestions from '../../components/Faqs'

export const Regreting = () => {
  const { userStore, notificationStore } = useStores()
  const store = useContext(StoreContext)
  const [isDisabled, setIsDisabled] = useState(true);
  const [submit, setSubmit] = useState(false)
  const [v, ] = useSimpleForm({
    apellido: '',
    dni: '',
    email: '',
    motivo: '',
    nombre: '',
    telefono: '',
  })
  const [formStatus, setFormStatus] = useState('ENVIAR')

  const validation = useCallback(() => {
    if (
      v.nombre?.trim() === "" ||
      v.apellido?.trim() === "" ||
      v.email?.trim() === "" ||
      v.dni?.trim() === "" ||
      v.telefono?.trim() === "" ||
      v.motivo.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  }, [v]);

  const handleInputChange = useCallback(() => {
    if (validation()) {
      console.log(isDisabled)
      setIsDisabled(true);
    } else {
      console.log(isDisabled)
      setIsDisabled(false);
    }
  }, [validation, isDisabled]);

  useEffect(() => {
    let forms = document.getElementsByTagName('form')
    for (const element of forms) {
      element.addEventListener(
        'invalid',
        function (e) {
          e.preventDefault()
        },
        true
      )
    }
  }, [])

  const frequentQuestions = [
    {
      summary: '¿Cuánto tiempo tengo para expresar el arrepentimiento de compra?',
      body: [
        <p>
          10 días corridos desde que realizaste la compra online o telefónicamente.
        </p>
      ],
    },
    {
      summary: '¿Megatlon tiene algún plazo para responder a mi arrepentimiento?',
      body: [
        <p>
          En el plazo de 24 horas hábiles, recibiras via mail una confirmación automática del trámite en proceso.
        </p>
      ],
    },
  ];


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      v.email !== "" &&
      v.telefono !== "" &&
      v.nombre !== "" &&
      v.dni !== "" && v.dni.length === 8 &&
      v.apellido !== "" &&
      v.motivo !== ""
    ) {
      console.log(v);
      setFormStatus("ENVIANDO...")
      let formBody: contactMessagePerson = {
        name: v.nombre,
        surname: v.apellido,
        phoneNumber: v.telefono,
        identityNumber: v.dni,
        email: v.email,
        clubId: 0,
        message: v.motivo
      }
      
      userStore.setRegreting(formBody).then((res) => {
        if (res.result) {
          userStore.setName(v.nombre.toUpperCase() + ' ' + v.apellido.toUpperCase());
          setFormStatus("ENVIAR")
          store.router.goTo(privateRoutes.SucceedeArrepentimientoPage)
        } else {
          notificationStore.addNotification('Error al enviar la solicitud', 'No se pudo enviar el correo.', 'warn')
          alert("false");
          setFormStatus("ENVIAR")
        }
      })
    }
  };

  return (
    <div style={{ background: 'black' }} className="flex flex-grow bg-cover">
      <Helmet>
        <title>Arrepentimiento de compra</title>
      </Helmet>
      <div className="container flex flex-col items-center flex-grow mx-auto ">
      <div className='border-white-line regreting-border'></div>
        <h1 className="ml-8 lg:mb-4 text-2xl font-bold col-start-1 col-end-3 text-centerregreting-title hidden md:block" style={{ color: 'white', paddingTop: '95px' }}>
          ARREPENTIMIENTO DE COMPRA
        </h1>
        <h1 className="block md:hidden text-2xl font-bold col-start-1 col-end-3 regreting-mobile-title text-center">ARREPENTIMIENTO DE<br/>COMPRA</h1>
        <Frequentquestions frequentQuestions={frequentQuestions} origin="faqs-regreting" />
        <div className="mb-5 w-4/5 subtitle-container">
          <h2 className="dropdown-toggle regreting-subtitle faq-title lg:text-lg lg:text-left sm:text-center sm:text-base font-bold text-center lg:text-left">COMPLETÁ TUS DATOS</h2>
        </div>
        <form
          className="flex flex-col items-center w-4/5"
          onSubmit={submitForm}
          >
          <div className="w-full grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-8 regreting-container">
            <div className="col-start-1 row-start-1">
              <Input placeholder="Nombre*" name="nombre" style={{height:'54px'}} fieldType="text" submit={submit} required={true}
                onChange={(e) => {
                  v.nombre = e.target.value.trim();
                  handleInputChange()
                }} 
              />
            </div>
            <div className="row-start-2 md:col-start-2 md:row-start-1">
              <Input placeholder="Apellido*" name="apellido" fieldType="text" submit={submit} required={true} onChange={(e) => {
                  v.apellido = e.target.value.trim();
                  handleInputChange()
                }}  
              />
            </div>
            <div className="row-start-3 md:col-start-1 md:row-start-2">
              <Input placeholder="Teléfono*" name="telefono" fieldType="tel" submit={submit} required={true} onChange={(e) => {
                  v.telefono = e.target.value.trim();
                  handleInputChange()
                }}  
              />
            </div>
            <div className="row-start-4 md:col-start-2 md:row-start-2">
              <Input placeholder="DNI*" name="dni" fieldType="tel" submit={submit} required={true} onChange={(e) => {
                  v.dni = e.target.value.trim();
                  handleInputChange()
                }}  
              />
            </div>
            <div className="row-start-5 md:col-start-1 md:col-end-3 md:row-start-3">
              <Input placeholder="Email*" name="email" fieldType="email" submit={submit} required={true} onChange={(e) => {
                  v.email = e.target.value.trim();
                  handleInputChange()
                }}  
              />
            </div>
            <div className="row-start-6 md:col-start-1 md:col-end-3 md:row-start-4">
              <textarea
                className="text-area"
                placeholder="Motivo*"
                rows={4}
                cols={30}
                maxLength={500}
                name="motivo"
                onChange={(e) => {
                  v.motivo = e.target.value.trim();
                  handleInputChange()
                }}  
                style={{ padding: '10px', fontWeight: 'normal' }}
              />
            </div>
            <div className='campos-obligatorios bda-campos-obligatorios'>
              <p className="text-sm">* Campos obligatorios</p>
            </div>
          </div>
          <div className="text-center my-6 submit-container">
            {isDisabled === true ? (
              <button
                type="submit"
                className={`white-pill-button w-1/2 self-center kids-teens-submit disabled-button disabled:opacity-50 disabled:pointer-events-none`}
                onClick={(e) => {
                  setSubmit(true);
                }}
                disabled
              >
                {formStatus}
              </button>
            ) : (
              <button
                type="submit"
                className={`white-pill-button w-1/2 self-center kids-teens-submit`}
                onClick={(e) => {
                  setSubmit(true);
                }}
              >
                {formStatus}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
