import React from 'react'
import { Helmet } from 'react-helmet'

export const Pautas = () => {
  return (
    <div className="flex flex-grow bg-gray-100 text-black py-20">
      <Helmet>
        <title>Pautas de convivencia</title>
      </Helmet>
      <div className="container mx-auto mt-4">
        <div className="flex items-center justify-between">
          <h1 className="ml-4 text-2xl font-bold w-full text-center">PAUTAS DE CONVIVENCIA</h1>
        </div>
        <div className="ml-4 mt-6">
          <div>
            {'El socio y/o sus adicionales se comprometen a cumplir las reglas y reglamentos, leyes y decretos que se encuentren periódicamente vigentes, para el uso del servicio que se detallan a continuación:'}
          </div>
          <br />
          <div>{'1.    Cumplir con todas las pautas escritas que se publiquen en las sedes.'}</div>
          <div>
            {
              '2.    Cuidar y utilizar las instalaciones, equipos y servicios de MEGATLON de manera de responsable de forma tal que los socios podamos disfrutar de ellos.'
            }
          </div>
          <div>{'3.    Ordenar y devolver los equipos y accesorios al mismo lugar del cual fueron tomados. '}</div>
          <div>
            {
              '4.    Utilizar los teléfonos celulares en el interior de las sedes en modo silencioso para no molestar a los demás socios.'
            }
          </div>
          <div>
            {
              '5.    Utilizar los equipos de a uno a la vez y esperar la disponibilidad de las máquinas respetando el orden de llegada y haciendo un uso razonable de las mismas. '
            }
          </div>
          <div>
            {
              '6.    Los socios declaran bajo juramento estar en plenas condiciones de salud para realizar actividades físicas.'
            }
          </div>
          <div>
            {
              '7.    Los socios declaran bajo juramento no tener una enfermedad contagiosa que pueda perjudicar a terceros y asumen plena responsabilidad en caso de incumplir con dicha pauta, obligándose a mantener indemne a MEGATLON, sus empleados, directores y accionistas por cualquier reclamo originado en el incumplimiento de esta pauta.'
            }
          </div>
          <div>
            {
              '8.    Está prohibido cualquier conducta que sea contraria a la moral y a las buenas costumbres y que sea contraria a la pacífica convivencia entre los socios y el personal de cada una de las sedes, incluyendo sin limitación tener relaciones sexuales dentro de las sedes o sus vestuarios.'
            }
          </div>
          <div>{'9.    Está prohibido comercializar bienes o servicios en las instalaciones. '}</div>
          <div>
            {
              '10.    Está prohibida la utilización de las sedes, instalaciones, maquinaria y/o equipos para un fin que sea distinto para el cual fueron concebidas: entrenamiento físico. '
            }
          </div>
          <div>
            {
              '11.    Está prohibido gritar, insultar o dirigirse de cualquier manera inapropiada respecto de los demás socios y empleados de la sede. '
            }
          </div>
          <div>{'12.    Está prohibido agredir de cualquier manera a otros socios y/o empleados de las sedes.'}</div>
          <div>
            {
              '13.    Es obligatorio la utilización de ropa y calzado apropiados para la práctica deportiva y entrenamiento en general. Está prohibido ingresar a las sedes desnudo o con el torso descubierto.'
            }
          </div>
          <div>
            {
              '14.    No está permitido quedarse sentado en los equipos o máquinas sin utilizarlos cuando hay otros socios que los quieran utilizar.'
            }
          </div>
          <div>
            {
              '15.   No se puede comer, fumar o ingerir cualquier tipo de drogas dentro de las instalaciones de las sedes. '
            }
          </div>
          <div>{'16.   No está permitido entrar a las sedes con animales.'}</div>
          <div>{'17.   Está prohibido ingresar a las sedes portando armas y/o explosivos de cualquier tipo.'}</div>
          <div>{'18.   Está prohibido el hurto o robo y la comisión de cualquier tipo de delitos.'}</div>
          <br />
          <div>
            {'La enumeración de las pautas de convivencia es meramente enunciativa y no taxativa por lo que MEGATLON se reserva el derecho de ampliar, agregar, reducir o modificar las mismas de manera unilateral y conforme su misión, objeto y espíritu corporativo. ' +
              'En caso de incumplimiento con las pautas de convivencia MEGATLON podrá aplicar cualquiera de las siguientes sanciones que estime convenientes: (i) llamado de atención; (ii) multa; (iii) suspensión; (iv) expulsión mediante la rescisión del contrato.'}
            {
              'Las sanciones podrán ser comunicadas por escrito, en forma verbal o de manera digital a través de un mail, mensaje de texto o chat. No obstante lo cual, en ningún caso, la aplicación de cualquiera de las sanciones previstas podrá significar que MEGATLON renuncia o menoscabar de cualquier manera su derecho de reclamar por los daños y perjuicios que la conducta del socio pudiera ocasionar. '
            }
            {
              'Sin perjuicio de lo expuesto, MEGATLON se reserva el derecho de admisión de socios y/o invitados cuando lo estime razonablemente conveniente y a su exclusivo criterio. Los socios utilizarán las instalaciones, equipos y maquinarias bajo su propia responsabilidad y se obligan a mantener indemne y liberaran a MEGATLON, sus empleados, directores y accionistas de cualquier responsabilidad derivada de su uso.'
            }
          </div>
        </div>
      </div>
    </div>
  )
}
