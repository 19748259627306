import { action, observable } from 'mobx'
import { Result } from '../lib/megaUsers'
import { withTokenPOST } from '../utils'
import { NotificationStore } from './NotificationStore'
import { AppStore } from './AppStore'
import { contactMessagePerson } from '../lib/megaStore'

export class FreepassStore {
  constructor(private appStore: AppStore, private notificationStore: NotificationStore) {}

  @observable
  name: string | undefined

  @action
  setName(newName: string) {
    this.name = newName
  }

  @action
  sendMessaje(messaje: contactMessagePerson) {
    this.sendForm(messaje)
      .then(() => {
        this.notificationStore.addNotification('Mensaje enviado con éxito')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  async sendForm(formBody: contactMessagePerson) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_USER_SERVER}/freepass`,
      this.appStore.getToken,
      JSON.stringify(formBody)
    )
  }
}
