import React, { CSSProperties } from 'react'

export interface ImageProps {
  id?: any
  imageRef?: string
  alt: string
  className?: string
  style?: CSSProperties
  placeholder?: string
  link?: string
}

export const Image = ({ id, imageRef, className = '', alt, style, placeholder }: ImageProps) => {
  return (
    <img
      id={id}
      alt={alt}
      crossOrigin="anonymous"
      src={
        imageRef !== undefined && imageRef.length > 0
          ? `${process.env.REACT_APP_RESOURCES_SERVER}/images/getImagen?ref=${imageRef}`
          : placeholder
      }
      className={className}
      style={style}
    />
  )
}
