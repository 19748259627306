import { action } from 'mobx'
import { Result } from '../lib/megaUsers'
import { withTokenPOST } from '../utils'
import { NotificationStore } from './NotificationStore'
import { AppStore } from './AppStore'
import { contactMessagePerson } from '../lib/megaStore'

export class SupportStore {
  constructor(private appStore: AppStore, private notificationStore: NotificationStore) {}

  @action
  async sendMessaje(messaje: contactMessagePerson) {
    // this.sendForm(messaje)
    //   .then(() => {
    //     this.notificationStore.addNotification('Mensaje enviado con éxito')
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
    const sendFormResponse = await this.sendForm(messaje)
    if (sendFormResponse) this.notificationStore.addNotification('Mensaje enviado con éxito')
  }

  async sendForm(formBody: contactMessagePerson) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_USER_SUPPORT}`,
      this.appStore.getToken,
      JSON.stringify(formBody)
    )
  }
}
