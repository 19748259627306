import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface LoadingProps {
  size?: string | undefined
}

export const Loading: FC<LoadingProps> = ({ size }) => {
  if (typeof size === undefined) size = 'text-5xl'
  return (
    <div className={'flex items-center justify-center w-full h-full'}>
      <FontAwesomeIcon className={'animate-spin text-gray-500 ' + size} icon={faSpinner} />
    </div>
  )
}
