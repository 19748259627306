import React from 'react'
import { SedeSelector } from '../../components/Sede'

export interface PanelContainerProps {
  title: string
  withSede?: boolean
  rounded?: 'left' | 'top'
}

export const PanelContainer: React.FC<PanelContainerProps> = ({
  children,
  title,
  withSede = true,
  rounded = 'left',
}) => {
  return (
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col md:flex-row items-center justify-between w-full mx-auto my-6 md:mx-0">
        <h2 className="text-3xl font-bold" style={{ marginLeft: '9px' }}>
          {title}
        </h2>
        {withSede ? <SedeSelector /> : null}
      </div>
      <>{children}</>
    </div>
  )
}
