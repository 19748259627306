import React, { Context } from 'react'
import { RouterStore, Store } from 'mobx-router'
import { AppStore } from './stores/AppStore'
import { UserStore } from './stores/UserStore'
import { NotificationStore } from './stores/NotificationStore'
import { CoachStore } from './stores/CoachesStore'
import { ClubStore } from './stores/ClubsStore'
import { ProductosStore } from './stores/ProductosStore'
import { CarritoStore } from './stores/CarritoStore'
import { CheckOutStore } from './stores/CheckOutStore'
import { ClasesStore } from './stores/ClasesStore'
import { LSCStore } from './stores/LSCStore'
import { OnDemandStore } from './stores/OnDemandStore'
import { FrameStore } from './stores/FrameStore'
import { RootRouterStore } from './index'
import { SupportStore } from './stores/SupportStore'
import { FreepassStore } from './stores/FreepassStore'
import { KidsAndTeensStore } from './stores/KidsAndTeensStore'

export interface RootStore extends Store {
  router: RouterStore<RootStore>
  frameStore: FrameStore
}

// @ts-ignore
export const StoreContext: Context<RootRouterStore> = React.createContext({})

export const useRouterStores = () => React.useContext(StoreContext)

const appStore = new AppStore()
const clubsStore = new ClubStore(appStore)
const userStore = new UserStore(appStore, clubsStore)
const notificationStore = new NotificationStore()

export const storesContext = React.createContext({
  appStore: appStore,
  clubsStore: clubsStore,
  notificationStore: notificationStore,
  userStore: userStore,
  coachStore: new CoachStore(appStore),
  productosStore: new ProductosStore(appStore, userStore),
  carritoStore: new CarritoStore(appStore, userStore, notificationStore),
  checkoutStore: new CheckOutStore(appStore, notificationStore),
  clasesStore: new ClasesStore(appStore, clubsStore, userStore),
  onDemandStore: new OnDemandStore(appStore),
  liveStreamingStore: new LSCStore(appStore, clubsStore, userStore, notificationStore),
  supportStore: new SupportStore(appStore, notificationStore),
  FreepassStore: new FreepassStore(appStore, notificationStore),
  KidsAndTeensStore: new KidsAndTeensStore(appStore, notificationStore),
})

export const useStores = () => React.useContext(storesContext)
