import { useObserver } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { StoreContext, useStores } from '../../Context'
import { privateRoutes } from '../../routes'

const SucceedePage = () => {
  const { FreepassStore } = useStores()
  const store = useContext(StoreContext)

  useEffect(() => {
    if (FreepassStore.name === undefined) {
      store.router.goTo(privateRoutes.home)
    }
  }, [FreepassStore.name, store.router])

  return useObserver(() => (
    <div className="min-h-75 flex flex-col justify-center gap-5 items-center max-w-90 md:w-3/5 m-auto text-center">
      <div className="text-lg md:text-2xl font-extrabold">¡MUCHAS GRACIAS {FreepassStore.name}!</div>
      <div className="text-lg md:text-2xl font-extrabold">
        LA SEDE HA RECIBIDO TU SOLICITUD DE PASE DE PRUEBA Y TE CONTACTARÁN A LA BREVEDAD.
      </div>
      <div className="text-base md:text-xl max-w-3/4">
        Te enviamos un email confirmando la solicitud de pase de prueba gratis. *
      </div>
      <div className="text-xs">
        *Términos y condiciones: el pase de prueba gratis es único e intrasferible. Puede solicitarse por un mismo
        individuo cada 6 meses. Si el pase gratis es superior a un día, estos serán activados de manera consecutiva al
        momento que el individuo se presenta en la sede.
      </div>
    </div>
  ))
}
export default SucceedePage
