import { useObserver } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Input } from '../../components/Input'
import { Loading } from '../../components/Loading'
import { CustomSelector } from '../../components/shared/CustomSelector/CustomSelector'
import { StoreContext, useStores } from '../../Context'
import { contactMessagePerson } from '../../lib/megaStore'
import { privateRoutes } from '../../routes'
import { checkForMobileDevice, useSimpleForm } from '../../utils'
import './FreePass.css'

const formInitialValue: contactMessagePerson = {
  name: '',
  surname: '',
  phoneNumber: '',
  identityNumber: '',
  email: '',
  clubId: 0,
}

const PaseGratis = () => {
  const { clubsStore, notificationStore, FreepassStore } = useStores()
  const store = useContext(StoreContext)
  const [submit, setSubmit] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const [value, onChange, , onSelectChange] = useSimpleForm(formInitialValue)

  const handleForm = (form: contactMessagePerson) => {
    setSubmit(true)
    //Validar campos vacios:
    const find = Object.keys(formInitialValue).find(
      (key) => formInitialValue[key as keyof typeof formInitialValue] === form[key as keyof typeof formInitialValue]
    )
    if (find) {
      notificationStore.addNotification(
        'Error al enviar la solicitud',
        'Todos los campos deben estar cargados.',
        'warn'
      )
    } else {
      setSpinner(true)
      try {
        FreepassStore.sendForm(form).then((res) => {
          if (res.result) {
            FreepassStore.setName(value.name!.toUpperCase() + ' ' + value.surname!.toUpperCase())
            store.router.goTo(privateRoutes.SucceedeFreePassPage)
            setSpinner(false)
          } else {
            notificationStore.addNotification('Error al enviar la solicitud', 'No se pudo enviar el correo.', 'warn')
            setSpinner(false)
          }
        })
      } catch (error) {
        console.log(error)
        setSpinner(false)
      }
    }
  }

  return useObserver(() => (
    <div className="min-h-90" style={{ paddingTop: '65px', marginTop: '10px' }}>
      <Helmet>
        <title>Pase de Prueba Gratis</title>
        <meta name="description" content="Pase de prueba gratis" />
      </Helmet>
      <div style={{ width: "100%" }}>
        <div className='border-white-line fp-line'></div>
        <h1 className="lg:mb-4 text-2xl font-bold col-start-1 col-end-3 free-pass-title desktop-title text-center">ACTIVÁ TU PASE <br className="lg:hidden"></br> DE PRUEBA GRATIS</h1>
      </div>
      <form
        className="flex flex-center flex-col justify-start items-center"
        onSubmit={(e) => {
          e.preventDefault()
          handleForm(value)
        }}>
        <div className="flex flex-col gap-8 w-3/4 mx-auto mb-5 md:mb-16 mt-3 md:mt-12">
          <div className="flex flex-col md:flex-row gap-8 w-full justify-between">
            <Input
              className="h-full"
              value={value.name}
              onChange={onChange}
              submit={submit}
              placeholder="Nombre*"
              name="name"
            />
            <Input
              className="h-full"
              value={value.surname}
              submit={submit}
              placeholder="Apellido*"
              onChange={onChange}
              name="surname"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-8 w-full justify-between">
            <Input
              className="h-full"
              value={value.phoneNumber}
              submit={submit}
              placeholder="Teléfono*"
              onChange={onChange}
              name="phoneNumber"
            />
            <Input
              className="h-full"
              value={value.identityNumber}
              placeholder="DNI*"
              submit={submit}
              onChange={onChange}
              name="identityNumber"
            />
          </div>
          <div
            style={!checkForMobileDevice() ? { height: '54px' } : undefined}
            className="flex flex-col md:flex-row gap-8 w-full justify-between">
            <Input
              className="h-full"
              value={value.email}
              submit={submit}
              placeholder="Email*"
              fieldType="email"
              onChange={onChange}
              name="email"
            />
            <div className=" w-full flex flex-col">
              <CustomSelector
                className="h-full"
                options={clubsStore.selectClubs}
                onChange={(e) => {
                  onSelectChange('clubId', e.value)
                  setSubmit(false)
                }}
                borderRadius="0rem"
                placeholder="Seleccionar sede*"
                border="1px solid white"
                backgroundColor="black"
                preventResizingOnOpen
              />
              {submit && <p className="text-red-600">{value.clubId === 0 && 'Requerido'}</p>}
            </div>
          </div>
          <div>
            <p className="text-sm">* Campos obligatorios</p>
          </div>
        </div>
        <button type="submit" className="white-pill-button mt-8 mb-8 md:my-0 fp-submit">
          {spinner ? <Loading size="text-2xl" /> : 'ENVIAR'}
        </button>
      </form>
    </div>
  ))
}
export default PaseGratis
