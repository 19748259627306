import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useObserver } from 'mobx-react-lite'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CustomAccordion from './shared/CustomAccordion/CustomAccordion'

interface FrequentQuestionsProps {
    frequentQuestions: { summary: string; body: any }[];
    origin: string;
  }
  
const Frequentquestions: React.FC<FrequentQuestionsProps> = ({ frequentQuestions, origin }) => {
    const [isOpen, setIsOpen] = useState(false);
    console.log(origin)
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return useObserver(() => (
    <div className="dropdown faq-dropdwon sm:faq-dropdwon-mobile" id={origin}>
        <div className='dropdown-container' onClick={toggleDropdown}>
            <button className="hidden md:inline-block dropdown-toggle faq-title text-lg">
                Preguntas Frecuentes
            </button>
            <button className="block lg:hidden md:hidden dropdown-toggle faq-title-mobile text-base">
                Ver Preguntas Frecuentes
            </button>
            <div className='icon-container'>
                {isOpen ? (
                    <FontAwesomeIcon className="text-xl mr-5" icon={faAngleDown} />
                ) : (
                    <FontAwesomeIcon className="text-xl mr-5" icon={faAngleRight} />
                )}
            </div>
        </div>
    {isOpen && (
        <div className="dropdown-menu">
            <div className='kids-teens-accordion'>
                <div className={'w-full px-5 lg:overflow-auto mt-10 header-button'}>
                    <CustomAccordion
                      summaryStyle={{ fontSize: '15px' }}
                      bodyStyle={{ fontSize: '14px' }}
                      array={frequentQuestions}
                      customColor ={ "#000000" }
                      customIcon = { "fa-plus" }
                      origin = { "kids-and-teens" }
                    />
                </div>
            </div>
        </div>
    )}
    </div>
  ))
}
export default Frequentquestions;