import { action, observable } from 'mobx'
import { Result } from '../lib/megaUsers'
import { withTokenPOST } from '../utils'
import { NotificationStore } from './NotificationStore'
import { AppStore } from './AppStore'
import { contactMessagePerson } from '../lib/megaStore'

export class KidsAndTeensStore {
  constructor(private appStore: AppStore, private notificationStore: NotificationStore) {}

  @observable
  name: string | undefined

  @observable
  membership: string | undefined

  @action
  setName(newName: string) {
    this.name = newName
  }

  @action
  setMembership(membership: string) {
    this.membership = membership
  }

  @action
  sendMessaje(messaje: contactMessagePerson) {
    this.sendForm(messaje)
      .then(() => {
        this.notificationStore.addNotification('Mensaje enviado con éxito')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  async sendForm(formBody: contactMessagePerson) {
    // formBody.message = "Formulario Kids & Teens";
    console.log(formBody)
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_USER_SERVER}/kidsandteens`,
      this.appStore.getToken,
      JSON.stringify(formBody)
    )
  }
}
