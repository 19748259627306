import React, { FC, useState } from 'react'
import ReactModal from 'react-modal'
import { Input } from '../../components/Input'
import { useStores } from '../../Context'
import { ProductoCarritoDTO } from '../../lib/megaStore'
import { useObserver } from 'mobx-react-lite'
import { useSimpleForm } from '../../utils'
import { max, padStart } from 'lodash-es'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const Pago = () => {
  const { checkoutStore } = useStores()

  return useObserver(() => (
    <div className="flex flex-grow flex-col w-full h-screen justify-center items-center gap-5 md:gap-0 mb-10">
      <h1 className="text-4xl font-bold text-center md:text-left w-full md:w-2/3 h-16">Realizar pago</h1>
      <div className="flex flex-col w-full md:w-2/3 rounded-lg p-6 gap-5" style={{ backgroundColor: '#232427' }}>
        <h3 className="text-2xl font-bold">Descripción</h3>
        {checkoutStore.summary.productos.map((item) => (
          <ItemPago key={item.productId} {...item} />
        ))}
        <div className="flex flex-wrap flex-col md:flex-row  items-center gap-5 ">
          <div className="w-full md:w-1/3">
            <Input
              placeholder="Código"
              fieldType="text"
              value={checkoutStore.codigo}
              onChange={(e) => checkoutStore.updateCodigo(e.target.value)}
            />
          </div>

          <button className=" white-pill-button" onClick={() => checkoutStore.addCodigo()}>
            APLICAR
          </button>

          {/* </div> */}
          {/* <div className="flex justify-around mt-4"> */}
          {checkoutStore.summary.promociones.map((p) => (
            <React.Fragment key={p.codigo}>
              <div className="w-full md:w-1/3">
                <span className="block font-bold">Descripción</span>
                <span className="block text-sm">{p.descripcion}</span>
              </div>
              {/*
        <div className="w-full text-sm text-right md:w-1/3">
          <span className="block">20%</span>
          <span className="block">$313.20 - $62.60</span>
        </div>
          */}
            </React.Fragment>
          ))}
        </div>

        <div className="flex flex-wrap flex-col md:flex-row justify-between items-center">
          {/* <div className="mx-auto"> */}
          {/*    <div className="flex justify-between">
              <div className="mb-2 mr-4 text-right">Sub Total: </div>
              <div className="mb-2">${checkoutStore.summary.subtotal?.toFixed(2)}</div>
            </div>*/}
          {/* <div className="flex justify-between">
              <div className="mb-2 mr-4 text-right">IVA: </div>
              <div className="mb-2 ">${checkoutStore.summary.iva?.toFixed(2)}</div>
            </div>*/}
          <div className="flex justify-between w-full md:w-1/3  ">
            <div className="mb-2 mr-4 font-bold text-right">Total: </div>
            <div className="mb-2 font-bold ">${checkoutStore.summary.total?.toFixed(2)}</div>
          </div>
          {/* </div> */}
          <div>
            <label className="block mb-3 text-sm text-center ">
              <span className="mr-4">¿Deseas facturar esta compra?</span>
              <input
                type="checkbox"
                checked={checkoutStore.facturar}
                onChange={(e) => checkoutStore.setFactura(e.target.checked)}
              />
            </label>
            <button className="  white-pill-button" onClick={() => checkoutStore.openModal()}>
              PAGAR AHORA
            </button>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={checkoutStore.showModal}
        onRequestClose={() => checkoutStore.closeModal()}
        className="top-0 bottom-0 w-full m-auto bg-gray-100 outline-none md:w-1/2 lg:w-1/3 xl:w-1/4 rounded-xl"
        style={{
          overlay: {
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(50, 50, 50, 0.25)',
          },
        }}>
        <PagoTarjeta />
      </ReactModal>
    </div>
  ))
}

const PagoTarjeta = () => {
  const tarjetas: { [k: string]: [number, number | number[], number] } = {
    Visa: [1, 16, 3],
    'Visa Débito': [7, 16, 3],
    Mastercard: [2, 16, 3],
    'Mastercard Débito': [1016, 16, 3],
    'American Express': [3, 15, 4],
    Maestro: [8, 18, 3],
    Naranja: [11, 16, 3],
    'Nativa Mastercard': [14, 16, 3],
    Shopping: [4, [16, 19], 3],
    Diners: [9, 14, 3],
    Cabal: [5, 16, 3],
  }

  const [tarjeta, setTarjeta] = useState<keyof typeof tarjetas>('Visa')
  const [v, onChange] = useSimpleForm({ nombre: '', numTarjeta: '', mes: '', year: '', cvv: '', dni: '' })

  const getMaxLenForNumTarjeta = (tarjeta: keyof typeof tarjetas) => {
    const len = tarjetas[tarjeta][1]
    if (Array.isArray(len)) {
      return max(len)
    } else {
      return len
    }
  }

  const { checkoutStore, notificationStore } = useStores()
  const [error, setError] = useState('')

  return useObserver(() => (
    <form
      className="p-5"
      onSubmit={(e) => {
        e.preventDefault()
        const payload = {
          card_number: v.numTarjeta,
          card_expiration_month: padStart(v.mes, 2, '0'),
          card_expiration_year: v.year,
          security_code: v.cvv,
          card_holder_name: v.nombre,
          card_holder_identification: {
            type: 'dni',
            number: v.dni,
          },
        }

        console.log('Card fs::')
        console.log(tarjeta.toString())
        console.log('Card::')

        let f1 = checkoutStore.providers.find((e) => e.nombre === tarjeta.toString().toUpperCase())

        checkoutStore.runDECIDIRFlow(payload, v.dni, f1 !== undefined ? f1.id : 0).catch((e) => {
          notificationStore.addNotification('Error', e.message, 'error')
          console.log(e)
          setError(e.message)
          checkoutStore.resetState()
        })
      }}>
      <h3 className="mb-5 text-2xl font-bold text-black"> Ingresa un método de pago </h3>
      <label className="block mb-4 font-bold text-black">
        Tipo de tarjeta
        <select
          onChange={(e) => setTarjeta((_) => e.target.value as unknown as keyof typeof tarjetas)}
          value={tarjeta}
          className="block px-4 py-2 mt-2 bg-white border border-black focus:border-black shadow-sm focus:outline-none select-arrow">
          {Object.keys(tarjetas).map((t) => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
        </select>
      </label>
      <Input
        placeholder="Nombre como aparece en la tarjeta"
        value={v.nombre}
        name="nombre"
        onChange={onChange}
        blackPlaceholder={true}
        inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
      />
      <Input
        placeholder="DNI del titular"
        value={v.dni}
        name="dni"
        onChange={onChange}
        blackPlaceholder={true}
        inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
      />
      <Input
        placeholder="Número de tarjeta"
        value={v.numTarjeta}
        name="numTarjeta"
        onChange={onChange}
        maxLength={getMaxLenForNumTarjeta(tarjeta)}
        blackPlaceholder={true}
        inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
      />
      <div className="flex">
        <div className="flex justify-between w-2/3 mr-8">
          <div className="w-5/12">
            <Input
              placeholder="Mes"
              maxLength={2}
              value={v.mes}
              name="mes"
              onChange={onChange}
              blackPlaceholder={true}
              inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
            />
          </div>
          <div className="w-5/12">
            <Input
              placeholder="Año"
              maxLength={2}
              value={v.year}
              name="year"
              onChange={onChange}
              blackPlaceholder={true}
              inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
            />
          </div>
        </div>
        <div className="w-1/3">
          <Input
            placeholder="CVV"
            value={v.cvv}
            name="cvv"
            onChange={onChange}
            maxLength={tarjetas[tarjeta][2]}
            blackPlaceholder={true}
            inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px', color: 'black' }}
          />
        </div>
      </div>
      {error !== '' ? (
        <span className="block mt-4 text-sm text-red-500">
          Error: Hubo un problema al procesar el pago. Intente de nuevo más tarde
        </span>
      ) : null}
      <div className="flex justify-center mt-10 text-xl">
        <button className="black-pill-button" type="submit" disabled={checkoutStore.pagoState === 'PROCESSING'}>
          Realizar pago
          {checkoutStore.pagoState === 'PROCESSING' ? (
            <FontAwesomeIcon className="ml-2 animate-spin" icon={faSpinner} />
          ) : null}
        </button>
      </div>
    </form>
  ))
}

const ItemPago: FC<ProductoCarritoDTO> = (v) => {
  return (
    <div className="flex w-full mb-2">
      <div className="font-bold text-center text-white bg-black" style={{ width: '30px', height: '30px' }}>
        {v.cantidad}
      </div>
      <div className="flex-grow mx-4">
        <h3 className="text-xl font-bold">{v.nombre}</h3>
        {/*opciones.length > 0 ?
        <>
          <h5 className="font-bold">Opciones</h5>
          {opciones.map((o, i) => (
            <div className="flex justify-between flex-grow px-4 italic" key={i}>
              <span>
                {o.nombre}
              </span>
              <span>
                $ {o.costo}
              </span>
            </div>
          ))}
        </>
        : null
      */}
      </div>
      <h4>${v.precio}</h4>
    </div>
  )
}
