import React, { FC, useEffect } from 'react'
import { addDays, differenceInDays, format, getHours, isSameDay, isSameHour, startOfDay } from 'date-fns'
import { es } from 'date-fns/locale'
import { ClasesStore } from '../../stores/ClasesStore'
import { useStores } from '../../Context'

import { Observer, useObserver } from 'mobx-react-lite'
import { Clase } from '../../types'
import { selectSingleMaker } from '../../utils'
import ReactModal from 'react-modal'
import { Loading } from '../../components/Loading'
import { LoginOrRegister } from '../../components/LoginOrRegister'
import { last } from 'lodash-es'

import { CustomSelector } from '../../components/shared/CustomSelector/CustomSelector'
import { pageview } from 'react-ga'

export const Clases = () => {
  const { clubsStore, clasesStore } = useStores()

  useEffect(() => {
    let sp = new URLSearchParams(window.location.search)
    let sede = parseInt(sp.get('sede') || '')
    if (!isNaN(sede)) {
      clasesStore.setClubSelect(sede)
    }
  })

  useEffect(() => {
    pageview('/clases')
  }, [])

  const selectClub = selectSingleMaker(
    clasesStore.setClubSelect.bind(clasesStore),
    clasesStore.setDefaultClub.bind(clasesStore)
  )

  const selectCategoria = selectSingleMaker(
    clasesStore.setCategoriaSelect.bind(clasesStore),
    clasesStore.setDefaultCategoria.bind(clasesStore)
  )

  return (
    <div className="flex flex-col mx-auto flex-grow w-11/12" style={{ paddingTop: '70px' }}>
      <h1 className="md:text-2xl text-3xl my-5 font-bold">GRILLA</h1>
      <div className="grid auto-rows-auto md:auto-cols-auto w-full">
        <Observer>
          {() => (
            <>
              <CustomSelector
                options={clubsStore.selectClubs}
                placeholder="Sede"
                // defaultValue={getSelectedClub()}
                onChange={selectClub}
                className={'my-2 md:col-start-1 w-11/12 justify-self-center'}
                value={clubsStore.selectClubs.find((c) => c.value === clasesStore.clubSeleccionado)}
              />

              <CustomSelector
                options={clasesStore.selectCategorias}
                placeholder="Disciplina"
                onChange={selectCategoria}
                className={'my-2 md:col-start-2 w-11/12 justify-self-center'}
                value={clasesStore.selectCategorias.find((c) => c.value === clasesStore.categoriaSeleccionada)}
              />

              <div className="hidden md:flex justify-start col-start-3">
                <button className="black-pill-button self-center " onClick={(_) => clasesStore.restablecer()}>
                  Restablecer
                </button>
              </div>
            </>
          )}
        </Observer>
      </div>

      <div className="flex-grow w-full rounded-t-xxl mt-10 " style={{ minHeight: '260px' }}>
        <Observer>
          {() => <ClasesGrid clasesStore={clasesStore} clases={clasesStore.clasesDTOToClases} weekStart={new Date()} />}
        </Observer>
      </div>
    </div>
  )
}

interface ClaseDetailProps {
  clasesStore: ClasesStore
  closeModal: () => void
}

const ClaseDetail: FC<ClaseDetailProps> = ({ clasesStore, closeModal }) => {
  const { userStore } = useStores()
  return useObserver(() => (
    <>
      {userStore.baseProfile ? (
        clasesStore.claseDetail ? (
          <div className="text-black text-center">
            <h1 className="text-xl font-bold">RESERVAS</h1>
            <p className="my-2 md:my-8">Reservá esta clase desde la Aplicación Megatlon</p>
            <button className="white-pill-button border border-black rounded-full">IR A LA APP</button>
          </div>
        ) : (
          <Loading />
        )
      ) : (
        <LoginOrRegister />
      )}
    </>
  ))
}

interface ClasesGridProps {
  clases: Clase[]
  weekStart: Date
  clasesStore: ClasesStore
}

const ClasesGrid: FC<ClasesGridProps> = ({ clases, clasesStore }) => {
  clases = clases.sort((a, b) => a.fecha.getTime() - b.fecha.getTime())
  const groupedClasses = clases.reduce((acc: Clase[][], val: Clase) => {
    if (acc.length === 0) {
      acc.push([val])
      return acc
    } else {
      const l = last(acc)
      if (!l) {
        return acc
      }
      if (isSameDay(l[0].fecha, val.fecha) && isSameHour(l[0].fecha, val.fecha)) {
        l.push(val)
        return acc
      } else {
        acc.push([val])
        return acc
      }
    }
  }, [] as Clase[][])

  const today = startOfDay(new Date())
  const days = Array.from(Array(7).keys()).map((_, i) => [format(addDays(today, i), 'EEEE d', { locale: es }), i])
  const horas = Array.from({ length: 16 }, (_, i) => i + 7)

  const getClassForDate = (d: Date) => {
    return `c-${differenceInDays(d, today)}-${getHours(d)}`
  }

  return useObserver(() => (
    <>
      <div className="items-center clases-grid mb-5">
        <ReactModal
          isOpen={!!clasesStore.claseDetailId}
          onRequestClose={() => {
            clasesStore.clearClaseDetailId()
          }}
          className="top-0 bottom-0 w-4/5 m-auto bg-gray-100 outline-none md:w-1/3 rounded-xl"
          style={{
            overlay: {
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(50, 50, 50, 0.25)',
            },
          }}>
          <div className="p-4">
            <ClaseDetail clasesStore={clasesStore} closeModal={() => clasesStore.clearClaseDetailId()} />
          </div>
        </ReactModal>
        <div className="text-xl font-bold text-center item hidden border-t border-b md:block text-xl hora-title">
          Hora
        </div>
        {days.map(([weekDay, dId], i) => (
          <div key={i} className={`item header-${dId} text-center border-t border-b`}>
            <h4 className="text-xl font-bold uppercase">{weekDay}</h4>
          </div>
        ))}
        {horas.map((h, i) => (
          <div key={i} className={`item text-center hidden md:block hora-${h}`}>
            {h} hrs
          </div>
        ))}
        {groupedClasses.flat().map((c) => {
          return (
            <div
              key={c.id}
              className={`${getClassForDate(
                c.fecha
              )} text-center flex flex-grow flex-col mb-4 md:mb-0 place-self-stretch`}>
              <div
                key={c.id}
                className="item item-clase text-center flex flex-grow flex-col pointer mb-4 md:mb-0"
                onClick={(_) => {
                  if (c.id != null) {
                    clasesStore.fetchClaseDetail(c)
                  }
                }}>
                <h6 className="mt-0 mb-auto text-xs">{format(c.fecha, 'p', { locale: es })}</h6>
                <h5 className="text-sm font-bold">{c.nombre}</h5>
              </div>
            </div>
          )
        })}
        <div className="w-full h-full item bg rounded-tl-xxl" />
      </div>
    </>
  ))
}
