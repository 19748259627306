import React from 'react'
import ReactDOM from 'react-dom'

import './tailwind.output.css'
import * as serviceWorker from './serviceWorker'

// import ReactModal from 'react-modal'
import 'mobx-react-lite/batchingForReactDom'
import { hotjar } from 'react-hotjar'
import { privateRoutes } from './routes'

import { MobxRouter, Route, RouterStore, startRouter } from 'mobx-router'
import { FrameStore } from './stores/FrameStore'
import { StoreContext } from './Context'
import App from './App'

export class RootRouterStore {
  public router: RouterStore<any>
  public frameStore: FrameStore
  public routes: { [key: string]: Route<any> }

  constructor(routes: any) {
    this.router = new RouterStore<RootRouterStore>(this)
    this.frameStore = new FrameStore()
    this.routes = routes
  }
}

hotjar.initialize(1069421, 6)

const store = new RootRouterStore(privateRoutes)

startRouter(privateRoutes, store, {
  html5history: false,
})

// ReactModal.setAppElement('#root')
ReactDOM.render(
  <StoreContext.Provider value={store}>
    <App>
      <MobxRouter store={store} />
    </App>
  </StoreContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
