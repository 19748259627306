import React, { useState, useEffect, useContext } from 'react'
import { Input } from '../../components/Input'
import { CustomSelector } from '../../components/shared/CustomSelector/CustomSelector'
import { StoreContext, useStores } from '../../Context'
import CustomAccordion from '../../components/shared/CustomAccordion/CustomAccordion'
import './Contact.css'
import { Observer } from 'mobx-react-lite'
import bannerContactCoorp from '../../assets/banners/Banners - Contacto corporativo.jpg'
import bannerContactPeople from '../../assets/banners/Banners- Contacto personas.jpg'
import { pageview } from 'react-ga'
import { contactMessageCompany, contactMessagePerson } from '../../lib/megaStore'
import { OptionTypeBase } from 'react-select'
import { Link } from 'mobx-router'
import { privateRoutes } from '../../routes'
import { Helmet } from 'react-helmet'

export const Contact = () => {
  const { clubsStore } = useStores()
  const store = useContext(StoreContext)

  const [userType, setUserType] = useState('personas')
  const [isMember, setIsMember] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [messageReadyToSend, setMessageReadyToSend] = useState(false)
  const [validSelect, setValidSelect] = useState('')
  const [motive, setmotive] = useState(0)
  const [club, setClub] = useState(0)
  const [formStatus, setFormStatus] = useState('ENVIAR')

  const { supportStore } = useStores()

  const handleSubmitForm = async (e: any) => {
    e.preventDefault()
    e.persist()
    setSubmit(true)
    setFormStatus('ENVIANDO...')
    let responsePerson: contactMessagePerson = {
      // type: 1,
    }
    let responseCompany: contactMessageCompany = {
      //type: 2,
    }

    if (validSelect !== '') {
      // if (validCaptcha) {
      if (userType === 'personas') {
        let commentInput = document.getElementById('12')! as HTMLInputElement | null

        responsePerson.type = 1
        responsePerson.name = e.target[2].value
        responsePerson.surname = e.target[3].value
        responsePerson.identityNumber = e.target[4].value
        responsePerson.phoneNumber = e.target[5].value
        responsePerson.isMember = isMember
        responsePerson.email = e.target[6].value
        responsePerson.reason = motive
        responsePerson.clubId = club
        responsePerson.message = commentInput?.value
        try {
          await supportStore.sendMessaje(responsePerson)
          setFormStatus('ENVIADO')
          setMessageReadyToSend(true)
        } catch (error) {
          console.log(error)
          setFormStatus('ENVIAR')
        }
      } else {
        responseCompany.type = 2
        responseCompany.email = e.target[2].value
        responseCompany.name = e.target[3].value
        responseCompany.surname = e.target[4].value
        responseCompany.phoneNumber = e.target[5].value
        responseCompany.companyName = e.target[6].value
        responseCompany.employees = e.target[7].value
        try {
          await supportStore.sendMessaje(responseCompany)
          setFormStatus('ENVIADO')
          setMessageReadyToSend(true)
        } catch (error) {
          console.log(error)
          setFormStatus('ENVIAR')
        }
      }
    }
  }

  useEffect(() => {
    let forms = document.getElementsByTagName('form')
    for (var i = 0; i < forms.length; i++) {
      forms[i].addEventListener(
        'invalid',
        function (e) {
          e.preventDefault()
        },
        true
      )
    }
  }, [userType])

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (submit) setSubmit(false)
  }

  const frequentQuestions = [
    {
      summary: '¿Cómo hago para asociarme a Megatlon?',
      body: [
        <p>
          Escribinos por WhatsApp haciendo{' '}
          <a className="contact-link" href="https://bit.ly/quieroasociarme01">
            clic acá.
          </a>
        </p>,
      ],
    },
    {
      summary: '¿Qué membresías me ofrece Megatlon? ',
      body: [
        <p>
          Megatlon ofrece diferentes membresías para cada uno de sus socios. Conocé cuál es la que más se adapta a vos:
        </p>,
        <p>Black, Platino, Classic, Solo Club, Red Córdoba, Red Rosario, Membresía Kids y Membresía Teens.</p>,
        <p>
          Para más información sobre planes y membresías escribinos por WhatsApp haciendo{' '}
          <a className="contact-link" href="https://bit.ly/membresias02">
            clic acá.
          </a>
        </p>,
      ],
    },
    {
      summary: '¿Qué son las Membresías Kids y Teens?',
      body: [
        <p>
          La Membresía Kids está pensada para niños de 2 a 12 años y la Membresía Teens para adolescentes de 13 a 15
          años.
        </p>,
        <p>
          Para más información sobre planes y membresías escribinos por WhatsApp haciendo{' '}
          <a className="contact-link" href="https://bit.ly/kidsteens04">
            clic acá.
          </a>
          .
        </p>,
      ],
    },
    {
      summary: '¿Cómo accedo a un pase de prueba?',
      body: [
        <p>
          Completá el formulario con tus datos para coordinar tu pase con un asesor/a haciendo{' '}
          <Link className="contact-link" router={store.router} route={privateRoutes.FreePass}>
            clic acá.
          </Link>
        </p>,
      ],
    },
    {
      summary: 'Apto médico',
      body: [
        <p>
          Para entrenar en nuestras sedes es necesario que aceptes y firmes la declaración jurada de aptitud física,
          requisito que rige para la totalidad de las sedes.
        </p>,
        <p>
          Si entrenas en las sedes ubicadas en Provincia de Buenos Aires, Córdoba y Santa Fe deberás presentar un
          certificado de Apto Médico. Este requisito no aplica para las sedes ubicadas en Capital Federal, ya que por
          ley se encuentra eximida.
        </p>,
        <p>Si tenés alguna duda envía tu consulta a atencionalsocio@megatlon.com.ar</p>,
      ],
    },
    {
      summary: '¿Cómo hago para enviar mi CV?',
      body: [<p>Enviá tu CV a postulante@megatlon.com.ar</p>],
    },
    {
      summary: '¿Qué sedes tienen pileta?',
      body: [
        <p>
          Las sedes de Megatlon que tienen pileta son: Almagro, Ateneo de la Juventud, Barracas, Barrio Norte,
          Caballito, Center I, Córdoba Barrio Jardín, Córdoba Centro, Devoto, Floresta Gonnet, La Imprenta, Núñez,
          Pilar, Racing, Rosario y Villa Crespo.
        </p>,
      ],
    },
    {
      summary: '¿Cuáles son los requisitos para usar las piletas?',
      body: [
        <p>
          Para usar la pileta el socio deberá contar con los elementos adecuados para su uso: traje y gorra de baño,
          toallón, ojotas y antiparras. Para más info, consultar en la recepción de la sede.
        </p>,
      ],
    },
    {
      summary: '¿Cuáles son los requisitos para empezar a entrenar?',
      body: [
        <p>
          Adquirido el plan, el socio deberá descargar la App, registrarse y aceptar la Declaración Jurada de Salud. Una
          vez realizado esto, podrá acceder a las sedes incluidas en su plan acercando el QR en los molinetes de acceso
          a la misma.
        </p>,
      ],
    },
    {
      summary: 'Activación Free Pass Empresas',
      body: [
        <p>
          Activá tu free pass haciendo{' '}
          <a className="contact-link" href="https://bit.ly/freepass07">
            clic acá.
          </a>{' '}
          Completá tus datos personales y el número que figura en el cartón. Si tenés algún inconveniente para activarlo
          escribinos a pases@megatlon.com.ar
        </p>,
      ],
    },
    {
      summary: '¿Qué sedes abren los domingos?',
      body: [
        <p>
          Las sedes que abren los domingos son: Alcorta, Alto Palermo, Alto Rosario, Caballito, Devoto, Distrito Arcos,
          Gonnet, La Imprenta, Núñez, Racing Club y Villa Crespo.
        </p>,
        <p>
          Para más información escribinos por WhatsApp haciendo{' '}
          <a className="contact-link" href="https://bit.ly/horarios11">
            clic acá.
          </a>
        </p>,
      ],
    },
    {
      summary: '¿Cómo hago un reclamo?',
      body: [
        <p>
          Para realizar un reclamo, completá el formulario de contacto que se encuentra en esta página y un asesor se
          comunicará con vos.
        </p>,
      ],
    },
  ]

  const motives: object[] = [
    { value: 1, label: 'Quiero conocer los planes y costos' },
    { value: 2, label: 'Quiero Realizar una consulta/sugerencia' },
    { value: 3, label: 'Quiero realizar un reclamo' }
  ]
  const handleUserType = () => {
    if (userType === 'personas') {
      setUserType('corporativo')
      setValidSelect('true')
      ;(document.getElementById('3') as HTMLInputElement).value = ''
      ;(document.getElementById('4') as HTMLInputElement).value = ''
      ;(document.getElementById('5') as HTMLInputElement).value = ''
    }
    if (userType === 'corporativo') {
      setValidSelect('')
      ;(document.getElementById('7') as HTMLInputElement).value = ''
      ;(document.getElementById('8') as HTMLInputElement).value = ''
      ;(document.getElementById('9') as HTMLInputElement).value = ''

      setUserType('personas')
    }
    setSubmit(false)
  }
  const handleMember = () => {
    setIsMember(!isMember)
    setSubmit(false)
  }
  const handleContactSelector = (e: OptionTypeBase) => {
    setmotive(e.value)
    if (submit) setSubmit(false)
    setValidSelect(e.label)
  }
  const handleFacilitySelector = (e: OptionTypeBase) => {
    setClub(e.value)
    if (submit) setSubmit(false)
  }

  const caseFormHandler = () => {
    switch (userType) {
      case 'personas':
        return (
          <div className="flex flex-col gap-8 ">
            <div className="flex flex-col lg:flex-row  gap-8 w-full justify-between">
              <Input
                id={1}
                submit={submit}
                required={true}
                onChange={onValueChange}
                placeholder="Nombre"
                fieldType="text"
                className="bg-transparent pl-1 w-full  "></Input>
              <Input
                id={2}
                submit={submit}
                onChange={onValueChange}
                required={true}
                placeholder="Apellido"
                fieldType="text"
                className="bg-transparent pl-1 w-full "></Input>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 w-full justify-between ">
              <Input
                id={3}
                submit={submit}
                required={true}
                onChange={onValueChange}
                placeholder="DNI"
                fieldType="tel"
                className="bg-transparent pl-1  w-full "></Input>
              <Input
                id={4}
                submit={submit}
                required={true}
                onChange={onValueChange}
                placeholder="Teléfono"
                fieldType="tel"
                className="bg-transparent pl-1  w-full "></Input>
            </div>
            <Input
              id={5}
              submit={submit}
              onChange={onValueChange}
              required={true}
              placeholder="Email"
              fieldType="email"
              className="bg-transparent pl-1  w-full"></Input>
            <div className="flex md:flex-row flex-col justify-between w-full gap-8 my-2">
              <div className=" w-full lg:w-2/5 flex flex-col">
                <p className="w-full mb-2">¿Sos socio?</p>
                <div className="flex w-full">
                  <div
                    className={` w-1/2 cursor-pointer h-3/5 py-4 rounded-l-lg text-center font-bold focus:outline-none socio ${
                      isMember === false ? 'bg-black color-white' : 'bg-white text-black'
                    }`}
                    onClick={handleMember}>
                    SI
                  </div>
                  <div
                    className={`w-1/2 cursor-pointer h-1/2 py-4 rounded-r-lg text-center font-bold focus:outline-none socio ${
                      isMember === true ? 'bg-black color-white' : 'bg-white text-black'
                    }`}
                    onClick={handleMember}>
                    NO
                  </div>
                </div>
              </div>
              <div className="w-full color-black">
                <p className="mb-2">Motivo de contacto</p>
                <CustomSelector
                  options={motives}
                  name={'SelectMotive'}
                  placeholder={'Selecccionar'}
                  onChange={handleContactSelector}
                  borderRadius="0rem"
                  border="1px solid white"
                  backgroundColor="black"
                  error={submit && validSelect === '' ? true : false}
                />
                {submit && <p className="text-red-600">{validSelect === '' && 'Requerido'}</p>}
              </div>
            </div>
            <div>
              <p>Seleccioná una sede</p>

              <CustomSelector
                options={clubsStore.selectClubs}
                name={'SelectClubName'}
                placeholder="Selecccionar"
                onChange={handleFacilitySelector}
                borderRadius="0rem"
                border="1px solid white"
                backgroundColor="black"
              />
            </div>
            <div className="w-full">
              <p>Dejanos un comentario</p>
              <textarea
                id={'12'}
                className="bg-transparent  h-24 w-full"
                style={{ border: '1px solid white', resize: 'none' }}
              />
            </div>
          </div>
        )

      case 'corporativo':
        return (
          <div className="flex flex-col gap-10">
            <Input
              id={6}
              submit={submit}
              required={true}
              placeholder="Email"
              onChange={onValueChange}
              fieldType="email"
              className="bg-transparent pl-1  w-full"></Input>
            <div className="flex flex-col lg:flex-row  gap-8 w-full justify-between ">
              <Input
                id={7}
                submit={submit}
                required={true}
                placeholder="Nombre"
                onChange={onValueChange}
                fieldType="text"
                className="bg-transparent pl-1 w-full "></Input>
              <Input
                id={8}
                placeholder="Apellido"
                onChange={onValueChange}
                submit={submit}
                required={true}
                fieldType="text"
                className="bg-transparent pl-1 w-full "></Input>
            </div>
            <Input
              id={9}
              submit={submit}
              required={true}
              placeholder="Teléfono"
              onChange={onValueChange}
              fieldType="tel"
              className="bg-transparent pl-1  w-full"></Input>
            <div className="flex flex-col lg:flex-row  gap-8 w-full justify-between">
              <Input
                id={10}
                submit={submit}
                required={true}
                placeholder="Nombre de empresa"
                name="nombreEmpresa"
                onChange={onValueChange}
                fieldType="text"
                className="bg-transparent pl-1 w-full  "></Input>

              <Input
                id={11}
                submit={submit}
                required={true}
                placeholder="Cantidad de empleados"
                name="empleados"
                onChange={onValueChange}
                fieldType="tel"
                className="bg-transparent pl-1 w-full "></Input>
            </div>
          </div>
        )

      default:
        break
    }
  }

  useEffect(() => {
    pageview('/contact')
  }, [])

  return (
    <div className="flex flex-col bg-black pt-24 mb-10 md:mb-4">
      <Helmet>
        <title>Contacto</title>
        <meta name="description" content="Conocé nuestros canales de atención." />
      </Helmet>
      <div className='border-white-line'></div>
      <h1 className="mb-5 text-2xl font-bold w-full lg:px-16 contact-header">
        {userType === 'personas' ? 'CONTACTO' : 'CONTACTO CORPORATIVO'}
      </h1>
      <div className=" flex md:justify-between md:flex-row flex-col gap-10 flex-grow contact-form-container items-strech">
        <Observer>
          {() => {
            return (
              <form
                name="form"
                id="form"
                className="flex flex-col gap-8 lg:w-3/5 mx-10 lg:mx-16 xl:h-full"
                onSubmit={(e) => {
                  if (!messageReadyToSend) handleSubmitForm(e)
                }}>
                <div className="flex  w-full justify-center">
                  <button
                    className={`w-full lg:w-1/2  py-3 px-1 rounded-l-lg text-center font-bold focus:outline-none ${
                      userType !== 'personas' ? 'bg-black color-white' : 'bg-white text-black'
                    }`}
                    onClick={handleUserType}>
                    <p>PERSONAS</p>
                  </button>
                  <button
                    className={`w-full lg:w-1/2  py-3 px-1 rounded-r-lg text-center font-bold focus:outline-none ${
                      userType !== 'corporativo' ? 'bg-black color-white' : 'bg-white text-black'
                    }`}
                    onClick={handleUserType}>
                    <p>CORPORATIVO</p>
                  </button>
                </div>
                {caseFormHandler()}
                {/* <ReCAPTCHA
                  className="self-center"
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY!}
                  onChange={onReCAPTCHAChange}
                  theme={'dark'}
                /> */}

                {submit && <p className="text-center">{messageReadyToSend && 'Mensaje enviado con éxito'}</p>}
                <button
                  type="submit"
                  className={`white-pill-button w-1/2 self-center`}
                  onClick={(e) => {
                    setSubmit(true)
                  }}>
                  {formStatus}
                </button>
              </form>
            )
          }}
        </Observer>
        <div className={`flex flex-col gap-8 items-center mx-10 md:w-3/5 md:mx-16 md:max-h-150 xl:max-h-95 xl:h-full`}>
          <div className="h-96 w-96">
            <img
              alt="cover"
              className="object-cover w-auto"
              src={userType === 'personas' ? bannerContactPeople : bannerContactCoorp}
            />
          </div>
          <p className="font-bold">PREGUNTAS FRECUENTES</p>
          <div className={'w-full  lg:overflow-auto'}>
            <CustomAccordion
              summaryStyle={{ fontSize: '16px' }}
              bodyStyle={{ fontSize: '14px' }}
              array={frequentQuestions}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
