import {AppStore} from './AppStore';
import {ClubResponse} from '../lib/megaClasses';
import {computed, observable, action, runInAction, when} from 'mobx';
import {withTokenGET} from '../utils';
import {Result} from '../lib/megaUsers';
import {FilterMap} from '../types';
import {has, toLower} from "lodash";

const urls = {
  'clubs': '/api/service/club/list'
};

export class ClubStore {
  @observable private filters = {} as FilterMap<ClubResponse>;
  @observable
  clubes: ClubResponse[] = [];

  constructor(private appStore: AppStore) {
    when(() => !!this.appStore.token,
      () => this.retrieveClubs());
  }

  @action
  async retrieveClubs() {
    const res = await withTokenGET<Result<ClubResponse[]>>(`${process.env.REACT_APP_CLASSES_SERVER}${urls.clubs}`, this.appStore.getToken);
    runInAction(() => {
      this.clubes = res.result;
    })
  }

  @computed
  public get selectClubs() {
    return this.clubes.map(c => ({value: c.clubId, label: c.nombre}));
  }

  @computed
  public get sedes() {
    return this.clubes;
  }

  @computed
  public get currentList() {
    return this.clubes.filter((item: any) => {
      for (let field in this.filters) {
        if (this.filters.hasOwnProperty(field)) {

          if (has(item, field)
            && (typeof item[field] == "string")) {
            //@ts-ignore
            let filter = this.filters[field];
            if (filter.trim().length === 0) {
              continue;
            }

            if (!toLower(item[field])
              .includes(toLower(filter.trim()))) {
              return false;
            }
          }
        }
      }
      return true;
    });
  }

  @action
  public filterBy = (filter: string, field: keyof ClubResponse) => {
    this.filters[field] = filter;
  };
}
