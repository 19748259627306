import {observable, action} from 'mobx';
import {Token} from '../types';

export class AppStore {
  @observable
  token?: Token;

  constructor() {
    this.acquireSession();
  }

  acquireSession = async () => {
    const maybeLoginToken = sessionStorage.getItem('login');
    if (maybeLoginToken) {
      this.updateToken(JSON.parse(maybeLoginToken) as unknown as Token);
      return;
    } 

    const payload = new URLSearchParams();

    payload.append('username', "app@megatlon.com");
    payload.append('password', "#GT3SN!RSDfeYG9@");
    payload.append('grant_type', "password");

    const response = await fetch(`${process.env.REACT_APP_USER_SERVER}/oauth/token`, {
      headers: {
        'Authorization': `Basic ${btoa('megatlon:user')}`,
        'Accept': 'application/json',
        'Content-type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      body: payload
    });
    const body = await response.json();
    this.updateToken(body as unknown as Token)
  };

  @action
  updateToken(token: Token) {
    this.token = token;
  }

  logIn = async (user: string, password: string) => {
    const payload = new URLSearchParams();

    payload.append('username', user);
    payload.append('password', password);
    payload.append('grant_type', "password");
    payload.append('app_version', "1.0");
  };

  get getToken(): Token {
    if (!this.token) {
      throw Error("Token not available?");
    }

    return this.token; 
  }
}
