import React, { useEffect, useState } from 'react'
import { PanelContainer } from './PanelContainer'
import { Input } from '../../components/Input'
import { useStores } from '../../Context'
import { useSimpleForm } from '../../utils'
import { autorun, when } from 'mobx'
import ReactModal from 'react-modal'
import { Observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Loading } from '../../components/Loading'
import editInput from '../../assets/icons/editInput.svg'
import DefaultProfileImage from '../../assets/defaultProfileImage.svg'

export const EditarPerfil = () => {
  const { userStore, notificationStore } = useStores()
  const [spinner, setSpinner] = useState(false)

  const loadModels = async (fl: FileList | null) => {
    if (fl == null) return
    setSpinner(true)
    try {
      const formData = new FormData()
      formData.append('image', fl[0])

      const post = await userStore.updateProfilePhoto(formData)
      if (post.ok) {
        userStore.retrieveProfile()
      }
    } catch (error) {
      console.log(error)
      return
    }
    setSpinner(false)
  }

  const [v, onChange, reset] = useSimpleForm({
    nombre: '',
    apellidoPaterno: '',
    mail: '',
    telefono: '',
    dni: '',
    ap: '',
    np: '',
    cnp: '',
  })

  useEffect(() => {
    autorun(async () => {
      await when(() => !!userStore.profile)
      reset({
        nombre: userStore.getPerfil.nombre,
        apellidoPaterno: userStore.getPerfil.apellidoPaterno,
        mail: userStore.getPerfil.mail,
        telefono: userStore.getPerfil.telefono,
        dni: userStore.getPerfil.dni?.toString() || '',
        ap: '',
        np: '',
        cnp: '',
      })
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container w-full mx-auto relative mb-10" style={{ paddingTop: '65px' }}>
      <PanelContainer withSede={false} title="PERFIL">
        <div className="flex justify-center flex-col items-center mb-10">
          {!spinner ? (
            <div style={{ position: 'relative' }}>
              <Observer>
                {() => (
                  <label className="rounded-full" style={{ width: '100px', height: '100px' }}>
                    <img
                      id="image"
                      src={userStore.profileImage === null ? DefaultProfileImage : userStore.profileImage}
                      style={{ width: '100px', height: '100px', objectFit: 'cover', border: '2px solid white' }}
                      alt={' '}
                      className="rounded-full"
                    />
                    {userStore.profileImage === null && (
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          loadModels(e.target.files)
                        }}></input>
                    )}
                  </label>
                )}
              </Observer>
            </div>
          ) : (
            () => <Loading />
          )}
          <h1>{v.nombre}</h1>
        </div>
        <form
          className={`grid grid-cols-1 grid-rows-7 w-3/4 m-auto md:w-full md:grid-cols-6 md:grid-rows-4 gap-6 mb-5 md:mb-16`}
          onSubmit={(e) => {
            e.preventDefault()
            userStore
              .updateProfile({
                ...v,
                dni: parseInt(v.dni),
              })
              .then((_) => {
                notificationStore.addNotification('Ok', 'Actualizado correctamente')
              })
              .catch((e) => {
                notificationStore.addNotification('Error', e.message)
              })
          }}>
          <div className="md:col-start-1 md:col-end-4 row-start-1">
            <Input placeholder="Nombre" value={v.nombre} name="nombre" onChange={onChange} icon={editInput} />
          </div>
          <div className="md:col-start-4 md:col-end-7 row-start-2 md:row-start-1">
            <Input
              placeholder="Apellido"
              value={v.apellidoPaterno}
              name="apellidoPaterno"
              onChange={onChange}
              icon={editInput}
            />
          </div>
          <div className="md:col-start-1 md:col-end-4 row-start-3 md:row-start-2">
            <Input placeholder="Teléfono" value={v.telefono} name="telefono" onChange={onChange} icon={editInput} />
          </div>
          <div className="md:col-start-4 md:col-end-7 row-start-4 md:row-start-2">
            <Input placeholder="DNI" disabled={true} value={v.dni} name="dni" onChange={onChange} />
          </div>
          <div className="md:col-start-1 md:col-end-4 row-start-5 md:row-start-3">
            <Input placeholder="Mail" disabled={true} value={v.mail} name="mail" onChange={onChange} />
          </div>
          <div className=" row-start-6 md:row-start-4 md:col-start-1 md:col-end-8 mt-5 md:mt-0 flex flex-col  md:flex-row md:justify-around items-center gap-10 ">
            <button type="submit" className="white-pill-button ">
              GUARDAR
            </button>
            <button
              type="button"
              className="white-pill-button  truncate"
              onClick={() => {
                console.log('Clicked psw change')
                userStore.setModalOpen()
              }}>
              CAMBIAR CONTRASEÑA
            </button>
          </div>
        </form>
      </PanelContainer>
      <Observer>
        {() => (
          <div className="flex flex-grow bg-cover">
            <ReactModal
              isOpen={userStore.modalOpen}
              onRequestClose={() => {
                userStore.setModalOpen()
              }}
              className="top-0 bottom-0 w-4/5 m-auto bg-white outline-none md:w-1/2 rounded-xl"
              style={{
                overlay: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'center',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(50, 50, 50, 0.25)',
                },
              }}>
              <FontAwesomeIcon
                icon={faTimes}
                className="absolute top-0 right-0 mr-6 mt-6 text-gray-400 hover:text-red-500 cursor-pointer"
                onClick={() => {}}
              />
              <div className="container flex flex-col items-center flex-grow mx-auto my-5">
                <form
                  className="w-3/4 text-black"
                  onSubmit={(e) => {
                    e.preventDefault()
                    if (v.np === v.cnp) {
                      userStore
                        .updatePassword({
                          passwordActual: v.ap,
                          passwordNuevo: v.cnp,
                        })
                        .then((res: any) => {
                          if (res.code === 0) {
                            notificationStore.addNotification('Ok', 'Actualizado correctamente')
                            userStore.setModalOpen()
                          } else {
                            notificationStore.addNotification('Alerta', res.message)
                          }
                        })
                        .catch((e) => {
                          notificationStore.addNotification('Error', e.message)
                        })
                    } else {
                      notificationStore.addNotification('Error', 'Las contraseñas no coinciden')
                    }
                  }}>
                  <Input
                    inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px' }}
                    placeholder="Contaseña actual"
                    blackPlaceholder
                    fieldType="password"
                    name="ap"
                    onChange={onChange}
                  />
                  <Input
                    inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px' }}
                    fieldType="password"
                    blackPlaceholder
                    placeholder="Nueva contraseña"
                    name="np"
                    onChange={onChange}
                  />
                  <Input
                    inputStyle={{ border: '1px solid black', paddingTop: '5px', paddingBottom: '5px' }}
                    blackPlaceholder
                    fieldType="password"
                    placeholder="Confirmar contraseña"
                    name="cnp"
                    onChange={onChange}
                  />
                  <div className="text-center mt-3 mb-3">
                    <button type="submit" className="white-pill-button border-2 border-black">
                      ACTUALIZAR
                    </button>
                  </div>
                </form>
              </div>
            </ReactModal>
          </div>
        )}
      </Observer>
    </div>
  )
}
