import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useObserver } from 'mobx-react-lite'
import React, { FC, useEffect, useState } from 'react'
import { pageview } from 'react-ga'
import CustomAccordion from '../../components/shared/CustomAccordion/CustomAccordion'
import './RRHH.css'
import { Helmet } from 'react-helmet'

const values = [
  {
    summary: 'Sinergia',
    body: [
      'Promovemos la participación de todos los miembros del equipo y nos potenciamos unos a otros. ',
      'Valoramos los buenos vínculos y la interacción armoniosa y diversa en la comunidad Megatlon.',
      'Celebramos nuestros logros en equipo. ',
    ],
  },
  {
    summary: 'Innovación',
    body: [
      'Generamos e implementamos ideas creativas para dar lugar a nuevos desarrollos y procesos de la empresa.',
      'Nos adaptamos y evolucionamos para asumir mayores desafíos. ',
      'Nos mantenemos a la vanguardia de los conocimientos técnicos y las tendencias de la industria para ser pioneros en el mercado.',
    ],
  },
  {
    summary: 'Actitud de Servicio',
    body: [
      'Nuestro compromiso con el cliente es el motor principal de nuestra gestión y trabajamos para ser reconocidos por nuestro alto estandar de calidad de servicio.',
      'Respondemos oportuna, efectiva y amablemente a las necesidades de nuestros socios, desde el inicio.',
    ],
  },
  {
    summary: 'Profesionalismo',
    body: [
      'Integramos nuestros equipos con personas competentes, éticas y comprometidas.',
      'Nos capacitamos en forma continua, y desafiamos nuestro propio éxito.',
      'Tratamos a todos los empleados de manera justa y tomamos decisiones basadas en mérito siguiendo procesos para definir el crecimiento de carrera.',
    ],
  },
  {
    summary: 'Cuidado de las personas',
    body: [
      'Ponemos a las personas primero. Garantizamos un entorno libre de riesgos cuidando la seguridad de todas las personas que ingresan a nuestras instalaciones.',
      'Actuamos de forma inmediata para resolver cualquier situación que implique un riesgo ante la integridad física de las personas.',
      'Implementamos normas, procedimientos y protocolos en forma proactiva para prevenir riesgos en materia de salud y seguridad.',
      'Ofrecemos programas de entrenamiento adecuados para cada socio que lo requiera. ',
      'Utilizamos el poder transformador del entusiasmo y motivamos a los demás a mejorar su calidad de vida.',
    ],
  },
]

const RRHH: FC = () => {
  const [navigation, setNavigation] = useState('sinergia')

  const onNavigationUpdate = (page: string) => {
    setNavigation(page)
  }

  const renderAccordion = () => {
    if (navigator.userAgent.includes('Mobi')) {
      return (
        <>
          {values && (
            <CustomAccordion
              array={values}
              includeIconsBeforeBody
              bodyIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            />
          )}
        </>
      )
    } else
      return (
        <div className="w-full grid grid-rows-auto grid-cols-auto">
          {values.map((element, index) => {
            return (
              <button
                key={index}
                style={{ minWidth: 'max-content' }}
                className={`row-start-1 col-start-${index + 1} bg-darkGray px-5 py-2 ${
                  navigation === element.summary.toLowerCase() ? 'rrhh-button-focus' : 'rrhh-button'
                }
                ${index !== 0 && `border-l border-white`}
                md:text-xl`}
                onClick={() => onNavigationUpdate(element.summary.toLowerCase())}>
                {element.summary}
              </button>
            )
          })}
          <div className="px-2 pt-2 pb-5 rounded-b-lg row-start-2 col-start-1 col-end-6 text-xl bg-lightGray w-full">
            {getOurValuesContent()}
          </div>
        </div>
      )
  }

  const getOurValuesContent = () => {
    return values.map((element, index) => {
      if (element.summary.toLowerCase() === navigation) {
        return (
          <div key={index}>
            {element.body.map((element2, index) => {
              return (
                <div key={index} className="flex justify-start items-center space-x-5 > 1 *">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <ul>{element2}</ul>
                </div>
              )
            })}
          </div>
        )
      }
      return null
    })
  }

  useEffect(() => {
    pageview('/rrhh')
  }, [])

  return useObserver(() => (
    <div className="mb-10 ">
      <Helmet>
        <title>RRHH</title>
      </Helmet>
      <div style={{ width: "100%", marginTop:"100px" }}>
        <div className='border-white-line'></div>
        <h1 className="text-center text-2xl font-bold md:font-base my-5 md:ml-10">
          ¿QUERÉS TRABAJAR CON NOSOTROS?
        </h1>
        <div className="text-center md:ml-10 md:text-2xl email">
            Envianos un correo a{' '}
            <a href="mailto:rrhh@megatlon.com.ar" className="underline">
              rrhh@megatlon.com.ar
            </a>
        </div>
      </div>
      <div className="w-5/6 mx-auto mt-10">
        <div className='image-cover'></div>
      </div>
      <div className="flex flex-col items-center w-5/6 mx-auto md:items-start">
        <h1 className="text-2xl my-5 font-bold nuestra-mision">NUESTRA MISIÓN</h1>
        <p className="w-11/12 text-xl md:w-full mision">
          Mejorar la calidad de vida de las personas a través de la actividad física, el desarrollo de vínculos sociales
          y la promoción de hábitos saludables.
        </p>
      </div>
      <div className="flex flex-col items-center w-5/6 mx-auto md:items-start">
        <h1 className="text-2xl my-5 font-bold nuestros-valores">NUESTROS VALORES</h1>
        {renderAccordion()}
      </div>
    </div>
  ))
}
export default RRHH
