import { AppStore } from './AppStore'
import { UserStore } from './UserStore'
import { action, observable, when, computed, toJS } from 'mobx'
import {
  Producto,
  ProductoCarritoDTO,
  AddCartProductRequestDTO,
  DeleteProductDTO,
  UpdateCartProductRequestDTO,
} from '../lib/megaStore'
import { withTokenPOST, withTokenGET } from '../utils'
import { Result } from '../lib/megaUsers'
import { NotificationStore } from './NotificationStore'

const urls = {
  addCartProduct: '/api/service/shoppingCart/add',
  deleteCartProduct: '/api/service/shoppingCart/delete',
  getShoppingCart: '/api/service/shoppingCart/list',
  updateCartProduct: '/api/service/shoppingCart/update',
}

export class CarritoStore {
  @observable
  cart: ProductoCarritoDTO[] = []

  constructor(private appStore: AppStore, private userStore: UserStore, private notificationStore: NotificationStore) {
    // setInterval(() => this.refreshCart(), 60000)
    when(
      () => this.userStore.isLoggedIn
      // () => this.refreshCart
    )
    when(
      () => !this.userStore.isLoggedIn,
      () => (this.cart = [])
    )
  }

  @action
  addProduct(producto: Producto, opcionId?: number) {
    this.addCartProduct({
      clubId: this.userStore.userClubOrDefault,
      cantidad: 1,
      productoId: producto.productId,
      opcionId: opcionId,
    })
      .then((res) => {
        if (res.result) {
          this.notificationStore.addNotification('Se agrego al carrito', `1x ${producto.nombre}`)
        } else {
          throw new Error(res.message)
        }
      })
      .catch((e) => this.notificationStore.addNotification('Error al agregar al carrito', e.message, 'error'))
      .finally(() => {
        this.refreshCart()
      })

    const item = this.cart.find((p) => p.productId === producto.productId)
    if (item) {
      item.cantidad += 1
    } else {
      this.cart.push({
        nombre: producto.nombre,
        extras: producto.extras,
        precio: producto.precio,
        cantidad: 1,
        productId: producto.productId,
        thumbnail: producto.imagen,
        subcategoria: producto.subcategoriaId,
      })
    }
  }

  @action
  increaseProduct(productId: number, amount: number) {
    const item = this.cart.find((i) => i.productId === productId)
    if (item) {
      item.cantidad += amount
      this.updateCartProduct({ cantidad: item.cantidad, productoId: productId })
        .then((res) => {
          if (res.code === 0) {
            this.refreshCart()
          } else {
            throw new Error(res.message)
          }
        })
        .catch((e) => {
          item.cantidad -= amount
          this.notificationStore.addNotification('Error', e.message, 'error')
        })
    }
  }

  @action
  deleteProduct(productId: number) {
    const oldCart = JSON.parse(JSON.stringify(toJS(this.cart)))
    this.cart = this.cart.filter((i) => i.productId !== productId)
    this.deleteCartProduct({ productoId: productId })
      .then((res) => {
        if (res.code === 0) {
          this.refreshCart()
        } else {
          throw new Error(res.message)
        }
      })
      .catch((e) => {
        this.cart = oldCart
        this.notificationStore.addNotification('Error', e.message, 'error')
      })
  }

  @action
  async refreshCart() {
    console.log('')
    // await when(() => this.userStore.isLoggedIn, {timeout: 2000});
    // if (!this.userStore.isLoggedIn) {return;}
    // this.getShoppingCart()
    //   .then(res => {
    //     if (res.code === 0) {
    //       this.cart = res.result;
    //     }
    //   })
  }

  @computed
  get costoCarrito() {
    return this.cart.reduce((acc, val) => acc + (val.precio || 0) * val.cantidad, 0)
  }

  @computed
  get carritoHasItems() {
    return this.cart.length > 0
  }

  @computed
  get cantidadCarrito() {
    return this.cart.reduce((acc, val) => acc + val.cantidad, 0)
  }

  async addCartProduct(item: AddCartProductRequestDTO) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_STORE_SERVER}${urls.addCartProduct}`,
      this.appStore.getToken,
      JSON.stringify(item)
    )
  }

  async deleteCartProduct(item: DeleteProductDTO) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_STORE_SERVER}${urls.deleteCartProduct}`,
      this.appStore.getToken,
      JSON.stringify(item)
    )
  }

  async getShoppingCart() {
    return await withTokenGET<Result<ProductoCarritoDTO[]>>(
      `${process.env.REACT_APP_STORE_SERVER}${urls.getShoppingCart}`,
      this.appStore.getToken
    )
  }

  async updateCartProduct(item: UpdateCartProductRequestDTO) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_STORE_SERVER}${urls.updateCartProduct}`,
      this.appStore.getToken,
      JSON.stringify(item)
    )
  }
}
