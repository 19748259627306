import {action, computed, observable} from "mobx";

export class FrameStore {
  @observable
  url?: string;

  @observable
  title?: string;

  @action
  setUrl(url: string) {
    this.url = url;
  }

  @action
  setTitle(title: string) {
    this.title = title;
  }

  @computed
  get titleUrlPair() {
    return [this.url, this.title];
  }
}
