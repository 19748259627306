import React, { FC, useEffect } from 'react'
import { useStores } from '../../Context'
import { observer, Observer, useObserver } from 'mobx-react-lite'
import { Image } from '../../components/Image'
import { Workout } from '../../types'
import ReactModal from 'react-modal'
import { Loading } from '../../components/Loading'
import { LoginOrRegister } from '../../components/LoginOrRegister'
import ReactPlayer from 'react-player'
import { OnDemandStore } from '../../stores/OnDemandStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CustomSelector } from '../../components/shared/CustomSelector/CustomSelector'
import { when } from 'mobx'

import megaIcon from '../../assets/Icono_MegatlonPlay.png'
export const OnDemand = observer(() => {
  const { onDemandStore } = useStores()
  useEffect(() => {
    const chat = document.getElementById('mychat')
    if (chat) {
      chat.hidden = true
    }
    return () => {
      if (chat) chat.hidden = false
    }
  }, [])

  useEffect(() => {
    when(
      () => onDemandStore.selectCategorias.length > 0,
      () => {
        if (onDemandStore.selectCategorias[0].value) {
          if (onDemandStore.categoriaSeleccionada === undefined)
            onDemandStore.setCategoriaSelect(onDemandStore.selectCategorias[0].value)
        }
      }
    )
  }, [onDemandStore])

  const handleActivityChange = (selectActividad: any) => {
    onDemandStore.setActividadSelect(selectActividad.value)
  }
  const handleCategoryChange = (selectCategory: any) => {
    onDemandStore.setCategoriaSelect(selectCategory.value)
  }
  return (
    <div className="bg-black  w-full min-h-screen mb-10 grid gap-5" style={{ paddingTop: '60px' }}>
      <div className="flex flex-row items-center justify-center w-full customGradient " style={{ height: '120px' }}>
        <img alt="cover" className=" object-cover" src={megaIcon} />
      </div>
      <div className="w-full flex flex-col items-center justify-between h-40 px-5 md:px-0">
        <CustomSelector
          options={onDemandStore.selectCategorias}
          placeholder={'HISTORIAL DE VIVOS'}
          onChange={handleCategoryChange}
          className={'w-full p-2 md:w-1/3'}
          value={onDemandStore.selectCategorias.find((c) => c.value === onDemandStore.categoriaSeleccionada)}
        />
        <CustomSelector
          options={onDemandStore.selectActividad}
          placeholder={'ACTIVIDAD'}
          onChange={handleActivityChange}
          className={'w-full p-2 md:w-1/3'}
          value={
            onDemandStore.selectActividad.find(
              (c: { value: number | undefined }) => c.value === onDemandStore.actividadSeleccionada
            ) || null
          }
        />
      </div>
      <div>
        <ActivityContainer />
      </div>
    </div>
  )
})

interface WorkoutDetailProps {
  onDemandStore: OnDemandStore
  closeModal: () => void
}

const ClaseDetail: FC<WorkoutDetailProps> = ({ onDemandStore }) => {
  const { userStore } = useStores()

  return useObserver(() => (
    <>
      {userStore.baseProfile ? (
        onDemandStore.workoutDetail ? (
          <div className="flex flex-col flex-grow">
            <div className="mt-auto mb-0 text-center">
              <ReactPlayer
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                url={onDemandStore.workoutDetail.liga}
                onContextMenu={(e: any) => e.preventDefault()}
                autoplay={true}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        ) : (
          <Loading />
        )
      ) : (
        <LoginOrRegister />
      )}
    </>
  ))
}

const ActivityContainer = () => {
  const { onDemandStore } = useStores()

  return (
    <div>
      <Observer>
        {() => (
          <div className="mx-5 grid grid-flow-row grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
            <ReactModal
              isOpen={!!onDemandStore.workoutDetailId}
              onRequestClose={() => {
                onDemandStore.clearWorkoutDetailId()
              }}
              className="top-1 bottom-0 w-4/5 m-auto bg-gray-100 outline-none md:w-5/6 rounded-xl"
              style={{
                overlay: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'center',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  marginTop: '5%',
                  backgroundColor: 'rgb(115,19,139)',
                },
              }}>
              <FontAwesomeIcon
                icon={faTimes}
                className="absolute top-0 right-0 mr-6 mt-6 text-gray-400 hover:text-red-500 cursor-pointer"
                onClick={() => onDemandStore.clearWorkoutDetailId()}
              />
              <div className="p-10">
                <ClaseDetail onDemandStore={onDemandStore} closeModal={() => onDemandStore.clearWorkoutDetailId()} />
              </div>
            </ReactModal>
            {onDemandStore.filteredWorkouts.map((a, i) => (
              <div
                key={i}
                onClick={(_) => {
                  if (a.idWorkout != null) {
                    onDemandStore.setWorkoutDetail(a)
                  }
                }}>
                <ActivityCard key={a.idWorkout} {...a} />
              </div>
            ))}
          </div>
        )}
      </Observer>
    </div>
  )
}

const ActivityCard: FC<Workout> = (workout) => {
  return (
    <div className="flex flex-col flex-grow w-full shadow-2xl rounded-xxl bg-white mt-8">
      <Image
        alt={workout.nombre}
        placeholder={process.env.REACT_APP_RESOURCES_SERVER + `/images/getImagen?ref=${workout.imagen}`}
        className="object-cover"
      />
    </div>
  )
}
