import React, { useContext, useState } from 'react'
import { StoreContext, useStores } from '../../Context'
import { useObserver } from 'mobx-react-lite'
import ReactModal from 'react-modal'
import { checkForMobileDevice, } from '../../utils'
import { privateRoutes } from '../../routes'

//ICONS
import { ReactComponent as CONTACTO } from '../../assets/icons/CONTACTO.svg'
import { ReactComponent as LOGINPERFIL } from '../../assets/icons/LOGINPERFIL.svg'
import { ReactComponent as RRHH } from '../../assets/icons/RRHH.svg'
import { ReactComponent as SEDES } from '../../assets/icons/SEDES.svg'
import { ReactComponent as TIENDA } from '../../assets/icons/TIENDA.svg'
import { ReactComponent as MEGATLONPLAY } from '../../assets/icons/MEGATLONPLAY.svg'
import { ReactComponent as MEGATLONLOGO } from '../../assets/MEGATLON.svg'
import { ReactComponent as VENIAMEGATLON } from '../../assets/icons/VENIAMEGATLON.svg'
import { ReactComponent as KIDSANDTEENS } from '../../assets/icons/KIDSANDTEENS.svg'
import { ReactComponent as CLOSEMENU } from '../../assets/icons/iconos_cruz.svg'
import { ReactComponent as MENUICON } from '../../assets/icons/iconos_Menu hamburguesa.svg'
import { Link } from 'mobx-router'

export const Header: React.FC = () => {
  const store = useContext(StoreContext)
  const { userStore, } = useStores()
  const [navbarOpen, setNavbarOpen] = useState(false)

  const styleFocusedItem = (page: string): string => {
    if (store.router.currentPath.replace('/', '').includes(page)) {
      return 'text-white lg:border-t lg:border-white'
    } else return 'text-white'
  }

  return useObserver(() => (
    <div
      className="container flex flex-grow items-center justify-between h-20 md:h-16 mx-auto text-xs md:text-xs sm:text-xs font-bold fixed top-0 z-20 px-8 bg-black color-white"
      style={{ maxWidth: '100vw' }}>
      <a
        className="text-center w-full text-3xl md:text-xl tracking-tight text-white lg:text-left"
        href='/#/'>
        <MEGATLONLOGO />
      </a>

      <label htmlFor="menu-toggle" className="cursor-pointer absolute right-0 mr-5 lg:hidden">
        {!navbarOpen ? (
          <div className='h-25'>
            <MENUICON style={{ height: '25px' }} />
          </div> 
        ) : (
          <div className='h-25'>
            <CLOSEMENU style={{ height: '25px' }} />
          </div>  
        )}
      </label>
      <input className="hidden" type="checkbox" id="menu-toggle" onClick={() => setNavbarOpen(!navbarOpen)} />
      <ul
        className={
          (navbarOpen ? '' : 'hidden ') +
          'bg-black text-white absolute w-full mt-16 top-0 right-0 flex flex-col h-screen bg-black lg:justify-end flex-grow list-none lg:h-full lg:relative lg:w-full lg:mt-0 lg:flex lg:items-center lg:w-auto lg:flex-row'
        }
        id="menu"
        onClick={(ev) => {
          const target = ev.target as HTMLInputElement
          if (!target.classList.contains('dropdown-mec')) setNavbarOpen(!navbarOpen)
        }}>
        <a
          href='/#/sedes'
          className="menu-item lg:border-0  lg:text-sm lg:m-0 lg:mx-5">
          <li className={`${styleFocusedItem('sedes')}`}>SEDES</li>
          <div className="menu-item-icon">{checkForMobileDevice() && <SEDES />}</div>
        </a>
        <a
          className="menu-item lg:border-0  lg:text-sm  lg:m-0 lg:mx-5"
          href="/#/paseGratis">
          <li className={`${styleFocusedItem('paseGratis')}`}>PASE DE PRUEBA</li>
          <div className="menu-item-icon">{checkForMobileDevice() && <VENIAMEGATLON />}</div>
        </a>
        <a
          className="menu-item lg:border-0  lg:text-sm  lg:m-0 lg:mx-5"
          href='/#/kidsandteens'>
          <li className={`${styleFocusedItem('kidsandteens')}`}>KIDS & TEENS</li>
          <div className="menu-item-icon" style={{ fill: 'white' }}>{checkForMobileDevice() && <KIDSANDTEENS />}</div>
        </a>
        <a
          className="menu-item lg:border-0  lg:text-sm  lg:m-0 lg:mx-5"
          href='/#/rrhh'>
          <li className={`${styleFocusedItem('rrhh')}`}>RRHH</li>
          <div className="menu-item-icon">{checkForMobileDevice() && <RRHH />}</div>
        </a>
        <a
          className="menu-item lg:border-0  lg:text-sm  lg:m-0 lg:mx-5"
          href='/#/contact'>
          <li className={styleFocusedItem('contact')}>CONTACTO</li>
          <div className="menu-item-icon">{checkForMobileDevice() && <CONTACTO />}</div>
        </a>
        {userStore.isLoggedIn && (
          <>
            <Link
              className="menu-item lg:border-0 lg:text-sm lg:m-0 lg:mx-5"
              router={store.router}
              route={privateRoutes.onDemand}>
              <li className={`${styleFocusedItem('onDemand')}`}>
                <p>MEGATLON PLAY</p>
              </li>
              <div className="menu-item-icon">{checkForMobileDevice() && <MEGATLONPLAY />}</div>
            </Link>
            <Link
              className="menu-item lg:border-0  lg:text-sm  lg:m-0 lg:mx-5"
              router={store.router}
              route={privateRoutes.perfil}>
              <li className={`${styleFocusedItem('perfil')}`}>PERFIL</li>
              <div className="menu-item-icon">{checkForMobileDevice() && <LOGINPERFIL />}</div>
            </Link>
            <Link
              className="menu-item lg:border-0  lg:text-sm  lg:m-0 lg:mx-5"
              router={store.router}
              route={privateRoutes.carrito}>
              <li className={`${styleFocusedItem('carrito')}`}>CARRITO</li>
              <div className="menu-item-icon">{checkForMobileDevice() && <TIENDA />}</div>
            </Link>
          </>
        )}
      </ul>
      <div className="flex flex-grow bg-cover">
        <ReactModal
          isOpen={userStore.modalMoreInfo}
          onRequestClose={() => {
            userStore.setModalMoreInfo()
          }}
          className="top-0 bottom-0 w-4/5 m-auto bg-gray-100 outline-none md:w-1/3 rounded-xl"
          style={{
            overlay: {
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(50, 50, 50, 0.25)',
            },
          }}>
          <div className="p-4 font-bold text-center">
            <p>Preguntas Frecuentes</p>
          </div>
          <div className="p-4">
            <div className="text-center">
              <button className="white-pill-button center-pill" onClick={() => userStore.setModalMoreInfo()}>
                Ok!
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    </div>
  ))
}
