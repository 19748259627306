import React, { FC, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { StoreContext, useStores } from '../../Context'
import { useObserver } from 'mobx-react-lite'
import { ProductoCarritoDTO } from '../../lib/megaStore'
import { Link } from 'mobx-router'
import { privateRoutes } from '../../routes'

const megatlon = require('../../assets/megatlon_footer.png')

export const Carrito = () => {
  const { carritoStore } = useStores()
  const store = useContext(StoreContext)

  useEffect(() => {
    carritoStore.refreshCart()
    // eslint-disable-next-line
  }, [])

  return useObserver(() => (
    <div className="flex flex-grow flex-col w-full justify-center items-center pt-24 md:pt-0 my-4 min-h-90 xl:min-h-screen">
      <h1 className="text-4xl font-bold text-center md:text-left w-full md:w-2/3 h-16">Carrito</h1>

      <div
        className="flex flex-col self-center p-4 w-full md:w-2/3 rounded-lg items-center"
        style={{ backgroundColor: '#232427' }}>
        {!carritoStore.carritoHasItems ? <div className="self-center">No hay nada en el carrito.</div> : null}
        {carritoStore.cart.map((item) => (
          <ItemCarrito {...item} key={item.productId} />
        ))}
        <div className="flex mt-0 md:mt-10 w-full flex-col md:flex-row justify-around">
          <div>
            {/* <div className="flex justify-between">
              <div className="mb-2 mr-4 text-right">Sub Total: </div>
              <div className="mb-2">${subtotal}</div>
            </div>
            <div className="flex justify-between">
              <div className="mb-2 mr-4 text-right">IVA: </div>
              <div className="mb-2 ">${iva}</div>
            </div> */}

            {carritoStore.carritoHasItems ? (
              <div className="flex justify-between text-xl ">
                <div className="mb-2 mr-4 font-bold text-right">Total:</div>
                <div className="mb-2 font-bold ">${carritoStore.costoCarrito}</div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col self-center">
            {carritoStore.carritoHasItems ? (
              <Link router={store.router} route={privateRoutes.pago}>
                <button className="white-pill-button">Realizar Pago</button>
              </Link>
            ) : null}
            <Link router={store.router} route={privateRoutes.home}>
              <button className="white-pill-button mt-2">Seguir Comprando</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ))
}

const ItemCarrito: FC<ProductoCarritoDTO> = (p) => {
  const { carritoStore } = useStores()
  return (
    <div className="flex w-full mb-4">
      <div className="w-20">
        <img alt={p.nombre} src={megatlon} className="max-w-full border border-gray-400" />
      </div>
      <div className="flex-grow mx-4">
        <h3 className="text-xl font-bold">{p.nombre}</h3>
        <h4>${p.precio}</h4>
        {/* {       opciones.length > 0 ? 
        <>
          <h5 className="font-bold">Opciones</h5>
           {opciones.map((o, i) => (
            <div className="flex justify-between flex-grow px-4 italic" key={i}>
              <span>
                {o.nombre}
              </span>
              <span>
                $ {o.costo}
              </span>
            </div>
          ))} 
        </>
     : null} */}
      </div>
      <div className="flex mt-4 ml-auto mr-0">
        {p.cantidad > 1 ? (
          <button
            style={{ width: '30px', height: '30px' }}
            className="font-bold text-center bg-white text-black"
            onClick={(_) => carritoStore.increaseProduct(p.productId, -1)}>
            -
          </button>
        ) : null}
        <div style={{ width: '30px', height: '30px' }} className="text-center border border-white text-black">
          {p.cantidad}
        </div>
        <button
          style={{ width: '30px', height: '30px' }}
          className="font-bold text-center bg-white text-black"
          onClick={(_) => carritoStore.increaseProduct(p.productId, 1)}>
          +
        </button>
      </div>
      <div className="flex mt-4 ml-4 mr-0">
        <button style={{ width: '30px', height: '30px' }} onClick={(_) => carritoStore.deleteProduct(p.productId)}>
          <FontAwesomeIcon icon={faTrashAlt} className="text-gray-700 cursor-pointer hover:text-red-500" />
        </button>
      </div>
    </div>
  )
}
