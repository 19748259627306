import {AppStore} from './AppStore';
import {CoachesResponsetDTO} from '../lib/megaClasses';
import {observable, onBecomeObserved, action, runInAction, autorun, computed, has, onBecomeUnobserved, IReactionDisposer} from 'mobx';
import {withTokenPOST} from '../utils';
import {Result} from '../lib/megaUsers';
import {FilterMap} from '../types';
import {toLower} from 'lodash-es';

const urls = {
  'coaches': '/api/service/coach/list'
};

export class CoachStore {
  @observable 
  private filters = {} as FilterMap<CoachesResponsetDTO>;

  @observable
  coaches: CoachesResponsetDTO[] = [];
  
  @observable
  clubSeleccionado: string = '60'; 

  constructor(private appStore: AppStore) {
    let dispose: IReactionDisposer;
    onBecomeObserved(this, 'coaches', () => {
      dispose = autorun(() => {
        if (!!appStore.token) {
          this.retrieveCoaches(); 
        }
      }); 
    });

    onBecomeUnobserved(this, 'coaches', () => {
      if (dispose) {
        dispose(); 
      }
    })
  }

  @action
  async retrieveCoaches() {
    const coaches = await withTokenPOST<Result<CoachesResponsetDTO[]>>(`${process.env.REACT_APP_CLASSES_SERVER}${urls.coaches}`, this.appStore.getToken,
      JSON.stringify({clubId: this.clubSeleccionado}));
    runInAction(() => {
      this.coaches = coaches.result;
    })
  } 

  @computed
  public get currentList() {
    return this.coaches.filter((item: any) => {
      for (let field in this.filters) {
        if (this.filters.hasOwnProperty(field)) {

          if (has(item, field)
            && (typeof item[field] == "string")) {
            //@ts-ignore
            let filter = this.filters[field];
            if (filter.trim().length === 0) {
              continue;
            }

            if (!toLower(item[field])
              .includes(toLower(filter.trim()))) {
              return false;
            }
          }
        }
      }
      return true;
    });
  }

  @action
  public filterBy = (filter: string, field: keyof CoachesResponsetDTO) => {
    this.filters[field] = filter;
  };
}
