import { AppStore } from './AppStore'
import { ClubStore } from './ClubsStore'
import { UserStore } from './UserStore'
import { withTokenPOST, withTokenGET } from '../utils'
import { observable, when, runInAction, autorun, computed, action, onBecomeObserved } from 'mobx'
import {
  BookRequestDTO,
  BookedResponseDTO,
  CancelClassRequestDTO,
  CategoriaClaseDTO,
  ClaseCategoRequestDTO,
  ClaseDTO,
  ClaseEventoResponse,
} from '../lib/megaClasses'
import { Result } from '../lib/megaUsers'
import { parse, isBefore, addDays, startOfDay, format } from 'date-fns'
import { es } from 'date-fns/locale'

const urls = {
  bookClass: '/api/service/class/book',
  getBookedList: '/api/service/class/book/list',
  cancelClass: '/api/service/class/cancel',
  getCategoriasClases: '/api/service/class/categories/club',
  getClassesByIdClubAndIdCategory: '/api/service/class/club/category/list',
  getClasses: '/api/service/class/club/list',
  getClaseDetails: '/api/service/class/details',
  getClaseEspecial: '/api/service/class/event/list',
}

export class ClasesStore {
  @observable
  clubSeleccionado: number = 4

  @observable
  categoriasClases: CategoriaClaseDTO[] = []

  @observable
  categoriaSeleccionada?: number

  @observable
  clases: ClaseDTO[] = []

  @observable
  claseDetail: Record<string, any> | undefined = undefined

  @observable
  claseDetailId: number | undefined

  @observable
  bookedClasses: BookedResponseDTO[] = []

  @observable
  memberStat?: boolean

  @observable
  confirmClass: boolean = true

  @observable
  dj: boolean = false

  constructor(private appStore: AppStore, private clubsStore: ClubStore, private userStore: UserStore) {
    onBecomeObserved(this, 'bookedClasses', async () => {
      await when(() => !!userStore.baseProfile)
      const res = await this.getBookedList()
      this.bookedClasses = res.result
    })

    when(
      () => clubsStore.clubes.length > 0,
      () => {
        this.clubSeleccionado = this.clubsStore.clubes[0].clubId
      }
    )

    autorun(() => {
      if (!this.appStore.token) return
      this.getCategoriasClases(this.clubSeleccionado)
        .then((res) => {
          runInAction(() => {
            if (res.result != null && res.result.length > 0) {
              this.categoriasClases = res.result
              this.categoriaSeleccionada = this.categoriasClases[0].id
            }

            autorun(() => {
              if (!this.appStore.token) return
              this.getClassesByIdClubAndIdCategory({
                categoryId: this.categoriaSeleccionada,
                clubId: this.clubSeleccionado,
              })
                .then((res) => {
                  runInAction(() => {
                    // console.log('Getting filtered classes ')
                    if (res.result != null) {
                      this.clases = res.result
                    }
                  })
                })
                .catch((err) => {
                  console.log('Error al obtener las clases por categoría y club')
                  console.log(err.message)
                })
            })
          })
        })
        .catch((err) => {
          console.log('Error al obtener las categorías por club:')
          console.log(err.message)
        })
    })
  }

  @computed
  public get selectCategorias() {
    return this.categoriasClases ? this.categoriasClases.map((cc) => ({ value: cc.id, label: cc.nombre })) : []
  }

  private getCategoria(idCategoria: number) {
    return this.categoriasClases.filter((categoria) => categoria.id === idCategoria)[0]
  }

  @computed
  public get normalizedClasses() {
    return this.clases !== null
      ? this.clases.map((c) => {
          c.date = parse(c.fecha, 'yyyy-MM-dd HH:mm:ss.S', new Date())
          return c
        })
      : []
  }

  @computed
  public get filteredClasses() {
    const today = startOfDay(new Date())
    const weekLater = addDays(today, 7)

    return this.normalizedClasses.filter((c) => {
      if (this.categoriaSeleccionada && c.disciplinaId !== this.categoriaSeleccionada) return false
      if (!isBefore(c.date, weekLater)) return false
      return true
    })
  }

  @computed
  public get clasesDTOToClases() {
    // console.log("filtered classes cat::");
    // console.log(this.filteredClasses.filter(e => {
    //   console.log("Element to filter::");
    //   console.log(e);
    //   this.categoriasClases.map(  t => t.id).includes(e.disciplinaId)
    // }));
    return this.filteredClasses
      .filter((e) => this.categoriasClases.map((t) => t.id).includes(e.disciplinaId))
      .map((c) => {
        const disciplina = this.categoriasClases ? this.categoriasClases.find((cat) => cat.id === c.disciplinaId) : null
        return {
          id: c.claseId,
          nombre: disciplina?.nombre || '',
          imagen: disciplina?.imagen,
          desc: disciplina?.descripcion,
          fecha: c.date,
          disponibilidad: c.disponibilidad,
          duracion: c.duracion,
          fechaFormatted: format(c.date, "EEE d 'de' LLLL", { locale: es }),
          horaFormatted: format(c.date, 'p', { locale: es }),
        }
      })
  }

  @computed
  public get clasesAgendadas() {
    return this.bookedClasses.map((bc) => {
      bc.date = parse(bc.fechaHora, 'yyyy-MM-dd HH:mm:ss.S', new Date())
      bc.fechaHoraFormatted = format(bc.date, "EEE d 'de' LLLL p", { locale: es })
      bc.club = this.clubsStore.clubes.find((c) => `${c.clubId}` === bc.clubId)?.nombre
      return bc
    })
  }

  @action
  public setClubSelect(idClub: number) {
    this.clubSeleccionado = idClub
  }

  @action
  public setDefaultClub() {
    this.clubSeleccionado = this.userStore.userClubOrDefault || 42
  }

  @action
  public setCategoriaSelect(idCategoria: number) {
    this.categoriaSeleccionada = idCategoria
  }

  @action
  public setDefaultCategoria() {
    this.categoriaSeleccionada = undefined
  }

  @action
  public restablecer() {
    this.setDefaultClub()
    this.setDefaultCategoria()
  }

  @action
  public clearClaseDetail() {
    this.claseDetail = undefined
  }

  @action enableConfirmClass() {
    this.confirmClass = !this.confirmClass
  }

  @action openDjModal() {
    this.dj = !this.dj
  }

  @action
  public clearClaseDetailId() {
    this.claseDetailId = undefined
  }

  @action
  public fetchClaseDetail(clase: any) {
    this.claseDetail = undefined
    this.claseDetailId = clase.id
    this.claseDetail = this.filteredClasses.filter(() => clase.id === this.claseDetailId)[0]
    this.claseDetail.disciplina = clase.nombre
  }

  async bookClass(br: BookRequestDTO) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.bookClass}`,
      this.appStore.getToken,
      JSON.stringify(br)
    )
  }

  async getBookedList() {
    return await withTokenPOST<Result<BookedResponseDTO[]>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.getBookedList}`,
      this.appStore.getToken
    )
  }

  async cancelClass(cr: CancelClassRequestDTO) {
    return await withTokenPOST<Result>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.cancelClass}`,
      this.appStore.getToken,
      JSON.stringify(cr)
    )
  }

  async getCategoriasClases(idClub: number) {
    return await withTokenGET<Result<CategoriaClaseDTO[]>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.getCategoriasClases}/${idClub}`,
      this.appStore.getToken
    )
  }

  async getClassesByIdClubAndIdCategory(ccr: ClaseCategoRequestDTO) {
    return await withTokenPOST<Result<ClaseDTO[]>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.getClassesByIdClubAndIdCategory}`,
      this.appStore.getToken,
      JSON.stringify(ccr)
    )
  }

  async getClasses(clubId: number) {
    return await withTokenPOST<Result<ClaseDTO[]>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.getClasses}`,
      this.appStore.getToken,
      JSON.stringify({ clubId: clubId })
    )
  }

  async getClaseDetails(claseId: number) {
    return await withTokenPOST<Result<Record<string, any>>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.getClaseDetails}`,
      this.appStore.getToken,
      JSON.stringify({ claseId: claseId })
    )
  }

  async getClaseEspecial(clubId: string) {
    return await withTokenPOST<Result<ClaseEventoResponse[]>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.getClaseEspecial}`,
      this.appStore.getToken,
      JSON.stringify({ clubId: clubId })
    )
  }
}
