import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { Input } from '../../components/Input'
import { CustomSelector } from '../../components/shared/CustomSelector/CustomSelector'
import { StoreContext, useStores } from '../../Context'
import { Observer } from 'mobx-react-lite'
import { contactMessagePerson } from '../../lib/megaStore'
import { privateRoutes } from '../../routes'
import './KidsAndTeens.css'
import { useSimpleForm } from '../../utils'
import { Helmet } from 'react-helmet'
import Frequentquestions from '../../components/Faqs'


const formInitialValue: contactMessagePerson = {
  name: '',
  surname: '',
  phoneNumber: '',
  identityNumber: '',
  email: '',
  clubId: 0,
  membership: '',
}

export const KidsAndTeens = () => {
  const { clubsStore, notificationStore, KidsAndTeensStore } = useStores()
  const store = useContext(StoreContext)

  const [userType, setUserType] = useState('')
  const [submit, setSubmit] = useState(false)
  const [formStatus, setFormStatus] = useState('ENVIAR')
  const [isDisabled, setIsDisabled] = useState(true);
  const [value, , , onSelectChange] = useSimpleForm(formInitialValue)
  const [selectorBorder, setSelectorBorder] = useState("1px solid white");
  const [, setResetSelect] = useState(false);
  type Option = {
    label: string | number | null | undefined;
    value: string | number | null | undefined;
  };
  const [, setSelectedOption] = useState<Option | undefined >(undefined);
  const [selectedClubOption, setSelectedClubOption] = useState<Option | undefined | number>(undefined);
  const [selectKey, setSelectKey] = useState(0);
  const [selectedMembership, setSelectedMembership] = useState<{ label: string | number | null | undefined; value: string | number | null | undefined; } | undefined>(undefined);
  const [isValidationReady, setIsValidationReady] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleClick = () => {
    setSelectorBorder(selectedClubs.length > 0 ? "1px solid white" : "1px solid red");
  };

  const handleCombinedClick = () => {
    handleClick();
    handleScrollClick();
  };

  const validation = useCallback(() => {
    console.log(selectedClubOption);
    if (
      value.name?.trim() === "" ||
      value.surname?.trim() === "" ||
      value.phoneNumber?.trim() === "" ||
      value.identityNumber?.trim() === "" ||
      value.email?.trim() === "" ||
      selectedClubOption === 0 ||
      selectedClubOption === undefined ||
      value.membership?.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  }, [selectedClubOption, value]);
  

  const handleInputChange = useCallback(() => {
    if (validation()) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [validation]);  

  const handleSubmitForm = (form: contactMessagePerson) => {
    setSubmit(true)
    setFormStatus('ENVIANDO...')
    let find = validation();

    if (find) {
      notificationStore.addNotification(
        'Error al enviar la solicitud',
        'Todos los campos deben estar cargados.',
        'warn'
      )
      setFormStatus('ENVIAR')
    } else {
      if (form.membership === "kids") {
        form.message = "Formulario Kids & Teens (Kids)";
      } else{
        form.message = "Formulario Kids & Teens (Teens)";
      }
      try {
        KidsAndTeensStore.sendForm(value).then((res) => {
          if (res.result) {
            KidsAndTeensStore.setName(value.name!.toUpperCase() + ' ' + value.surname!.toUpperCase())
            KidsAndTeensStore.setMembership(value.membership!)
            store.router.goTo(privateRoutes.SucceedeKidsAndTeensPage, { userType: userType})
            setFormStatus('ENVIADO')
          } else {
            notificationStore.addNotification('Error al enviar la solicitud', 'No se pudo enviar el formulario.', 'warn')
            setFormStatus('ENVIAR')
          }
        })
      } catch (error) {
        console.log(error)
        setFormStatus('ENVIAR')
      }
    }
  }

  const membresias: object[] = [
    { value: "kids", label: 'Kids (de 2 a 12 años)' },
    { value: "teens", label: 'Teens (de 13 a 15 años)' }
  ]

  const clubs = clubsStore.selectClubs;

  let clubNames: any[] = [];

  if (userType === "kids") {
    clubNames = ["Almagro", "Ateneo de la Juventud", "Barracas", "Barrio Norte", "Caballito", "Devoto", "Floresta", "La Imprenta", "Núñez", "Racing Club", "Rosario", "Villa Crespo", "Barrio Jardín"];
  } else if (userType === "teens") {
    clubNames = ["Almagro", "Ateneo de la Juventud", "Barracas", "Barrio Norte", "Caballito", "Devoto", "Floresta", "Gonnet", "La Imprenta", "Núñez", "Pilar", "Racing Club", "Rosario", "Villa Crespo"];
  }

  const selectedClubs = clubs.filter((club) => clubNames.some((name) => club.label.includes(name) && !club.label.includes("Caballito II") && !club.label.includes("Alto Rosario")));

  const targetElementRef = useRef<HTMLDivElement>(null);

  const camposObligatorios = useRef<HTMLDivElement>(null);

  const handleScrollClick = () => {
    if(value.membership){
      if (targetElementRef.current) {
        targetElementRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',   
        });
      }
    }
  };

  useEffect(() => {
    let forms = document.getElementsByTagName('form')
    for (var i = 0; i < forms.length; i++) {
      console.log(forms[i]);
      forms[i].addEventListener(
        'invalid',
        function (e) {
          e.preventDefault()
        },
        true
      )
    }
  }, [userType])

  useEffect(() => {
    setResetSelect(false); 
  }, [value.membership]);

  useEffect(() => {
    setIsValidationReady(true);
  }, []);

  useEffect(() => {
    if (isValidationReady) {
      handleInputChange();
    }
  }, [selectedClubOption, isValidationReady, handleInputChange]);
  
  
  useEffect(() => {
    if (selectedMembership !== undefined && isMenuOpen === true) {
      targetElementRef.current?.classList.remove('hidden');
    } else {
      targetElementRef.current?.classList.add('hidden', "white-space");
    }

    if(selectedMembership === undefined && isMenuOpen){
      camposObligatorios.current?.classList.add('campos-obligatorios-margin');
    } else{
      camposObligatorios.current?.classList.remove('campos-obligatorios-margin');
    }
  }, [selectedMembership, isMenuOpen]);
  

  const frequentQuestions = [
    {
      summary: '¿Qué son las Membresías Kids y las Membresías Teens y qué edades comprenden?',
      body: [
        <p>
          La Membresía Kids es un tipo de membresía especialmente destinada al desarrollo integral de niños y niñas de 2 a 12 años a través del deporte. Tiene como objetivo que se inicien de forma temprana en la actividad física y adquieran hábitos saludables desde pequeños. 
        </p>,
        <p>
          La Membresía Teens es una membresía destinada especialmente a chicas y chicos de 13 a 15 años. El objetivo es acompañar a los adolescentes y guiarlos en su entrenamiento y desarrollo físico para que sigan adquiriendo hábitos saludables en esta etapa tan fundamental de la vida.
        </p>
      ],
    },
    {
      summary: '¿Qué sedes están incluídas en las Membresías Kids y las Membresías Teens?',
      body: [
        <p>
          Las sedes que incluyen Membresía Kids son: Almagro, Ateneo de la Juventud, Barracas, Barrio Norte, Caballito, Devoto, Floresta, La Imprenta, Núñez, Racing Club, Rosario, Villa Crespo y Barrio Jardín (Córdoba).
        </p>,
        <p>
          Las sedes que incluyen Membresía Teens son: Almagro, Ateneo de la Juventud, Barracas, Barrio Norte, Caballito, Devoto, Floresta, Gonnet, La Imprenta, Núñez, Pilar, Racing Club, Rosario y Villa Crespo.
        </p>
      ],
    },
    {
      summary: '¿Qué actividades ofrecen las Membresías Kids y las Membresías Teens?',
      body: [
        <p>
          Las actividades que ofrece la Membresía Kids son: Aeróbica infantil, básquet, escuela de natación,escuelas deportivas, fútbol, iniciación deportiva, patín, taekwon-do y voley.
        </p>,
        <p>
          Las actividades que ofrece la Membresía Teens son: Baile, básquet, escuelas deportivas, funcional, natación, patín, pileta libre y sala de musculación.
        </p>,
        <p>
          <em>Importante: Las actividades varían según la sede que elijas porque cada una maneja su propio cronograma.</em> 
        </p>
      ],
    },
    {
      summary: '¿Cómo obtener las Membresías Kids y las Membresías Teens?',
      body: [
        <p>
          Para adquirir una Membresía Kids, o una Membresía Teens, deberás ponerte en contacto con la sede deseada. Esto lo podrás hacer presencial, por teléfono o por mail.
        </p>,
        <p>
          Consultá los datos de contacto de cada sede en:  <a href='https://megatlon.com/#/sedes'><u>https://megatlon.com/#/sedes</u></a>.
        </p>
      ],
    },
    {
      summary: '¿En qué horarios se dictan las actividades de las Membresías Kids y las Membresías Teens?',
      body: [
        {
          key: "Parrafo",
          label: "Elegí la sede deseada y descargá la grilla de actividades y horarios de cada una."
        },
        {
          key: "ParrafoCursiva",
          label: "Importante: Por dudas y/o inquietudes sobre horarios y actividades te sugerimos que te pongas en contacto directo con la sede ya que el cronograma de clases es administrado por la misma."
        },
        {
          key: "Parrafo",
          label: "MEMBRESÍA KIDS:"
        },
        {
          key: "Almagro",
          label: "Almagro",
          href: "https://bit.ly/KIDS-ALMAGRO"
        },
        {
          key: "Ateneo",
          label: "Ateneo de la Juventud",
          href: "https://bit.ly/KIDS-ATENEO"
        },
        {
          key: "Barracas",
          label: "Barracas",
          href: "https://bit.ly/KIDS-BARRACAS"
        },
        {
          key: "Barrio Norte",
          label: "Barrio Norte",
          href: "https://bit.ly/KIDS-BNORTE"
        },
        {
          key: "Caballito",
          label: "Caballito",
          href: "https://bit.ly/KIDS-CABALLITO"
        },
        {
          key: "Devoto",
          label: "Devoto",
          href: "https://bit.ly/KIDS-DEVOTO"
        },
        {
          key: "Floresta",
          label: "Floresta",
          href: "https://bit.ly/KIDS-FLORESTA"
        },
        {
          key: "Imprenta",
          label: "La Imprenta",
          href: "https://bit.ly/KIDS-LAIMPRENTA"
        },
        {
          key: "Nuñez",
          label: "Núñez",
          href: "https://bit.ly/KIDS-NUNEZ"
        },
        {
          key: "Racing",
          label: "Racing Club",
          href: "https://bit.ly/KIDS-RACING"
        },
        {
          key: "Rosario",
          label: "Rosario",
          href: "https://bit.ly/KIDS-ROSARIO"
        },
        {
          key: "V.crespo",
          label: "Villa Crespo",
          href: "https://bit.ly/KIDS-VCRESPO"
        },
        {
          key: "Córdoba Barrio Jardín",
          label: "Barrio Jardín (Córdoba)",
          href: "https://bit.ly/KIDS-BJARDIN"
        },
        {
          key: "Parrafo",
          label: "MEMBRESÍA TEENS:",
        },        
        {
          key: "Almagro",
          label: "Almagro",
          href: "https://bit.ly/TEENS-ALMAGRO"
        },
        {
          key: "Ateneo de la juventud",
          label: "Ateneo de la juventud",
          href: "https://bit.ly/TEENS-ATENEO"
        },
        {
          key: "Barracas",
          label: "Barracas",
          href: "https://bit.ly/TEENS-BARRACAS"
        },
        {
          key: "Barrio Norte",
          label: "Barrio Norte",
          href: "https://bit.ly/TEENS-BNORTE"
        },
        {
          key: "Caballito",
          label: "Caballito",
          href: "https://bit.ly/TEENS-CABALLITO"
        },
        {
          key: "Devoto",
          label: "Devoto",
          href: "https://bit.ly/TEENS-DEVOTO"
        },
        {
          key: "Floresta",
          label: "Floresta",
          href: "https://bit.ly/TEENS-FLORESTA"
        },
        {
          key: "Gonnet",
          label: "Gonnet",
          href: "https://bit.ly/TEENS-GONNET"
        },
        {
          key: "La Imprenta",
          label: "La Imprenta",
          href: "https://bit.ly/TEENS-LAIMPRENTA"
        },
        {
          key: "Nuñez",
          label: "Núñez",
          href: "https://bit.ly/TEENS-NUNEZ"
        },
        {
          key: "Pilar",
          label: "Pilar",
          href: "https://bit.ly/TEENS-PILAR"
        },
        {
          key: "Racing Club",
          label: "Racing Club",
          href: "https://bit.ly/TEENS-RACING"
        },
        {
          key: "Rosario",
          label: "Rosario",
          href: "https://bit.ly/TEENS-ROSARIO"
        },
        {
          key: "Villa Crespo",
          label: "Villa Crespo",
          href: "https://bit.ly/TEENS-VCRESPO"
        },
        
      ].map((item) => {
        if (item.key.includes("Parrafo")) {
          if(item.label.includes("MEMBRESÍA")){
            return <p key={item.key}><b>{item.label}</b></p>;
          } 
          if (item.key.includes("Cursiva")) {
            return <p key={item.key}><em>{item.label}</em></p>;
          }
          return <p key={item.key}>{item.label}</p>;
        } else {
          return (
            <p key={item.key}>
              <a className="contact-link" href={item.href}>{item.label}</a>
            </p>
          );
        }
      }),
  }];

  const KidsAndTeensForm = () => {
        return (
          <div className="flex flex-col gap-8 form-kids-teens">
            <Helmet>
              <title>Kids & Teens</title>
              <meta name="description" content="Kids and teens" />
            </Helmet>
            <div className="flex flex-col lg:flex-row  gap-8 w-full justify-between with-select kids-teens-row">
              <Input
                submit={submit}
                required={true}
                onChange={(e) => {
                  value.name = e.target.value.trim();
                  handleInputChange() 
                }}  
                placeholder="Nombre*"
                fieldType="text"
                name='name'
                className="bg-transparent kids-teens-input pl-1 w-full white-placeholder"></Input>
              <Input
                submit={submit}
                onChange={(e) => {
                  value.surname = e.target.value.trim();
                  handleInputChange()
                }}  
                required={true}
                placeholder="Apellido*"
                fieldType="text"
                name="surname"
                className="bg-transparent kids-teens-input pl-1 w-full white-placeholder"></Input>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 w-full justify-between kids-teens-row">
              <Input
                submit={submit}
                required={true}
                onChange={(e) => {
                  value.phoneNumber = e.target.value.trim();
                  handleInputChange()
                }}  
                placeholder="Teléfono*"
                fieldType="tel"
                name="phoneNumber"
                className="bg-transparent kids-teens-input pl-1  w-full white-placeholder"></Input>
              <Input
                submit={submit}
                required={true}
                onChange={(e) => {
                  value.identityNumber = e.target.value.trim();
                  handleInputChange()
                }}    
                placeholder="DNI*"
                fieldType="tel"
                name="identityNumber"
                className="bg-transparent kids-teens-input pl-1  w-full white-placeholder"></Input>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 w-full justify-between kids-teens-row">
              <Input
                className="bg-transparent kids-teens-input pl-1 h-full w-full white-placeholder"
                required={true}
                submit={submit}
                placeholder="Email*"
                fieldType="email"
                onChange={(e) => {
                  value.email = e.target.value.trim();
                  handleInputChange()
                }}                   
                name="email"
              />
            </div>
            <div className="flex flex-col lg:flex-row gap-8 w-full with-select kids-teens-row" style={{ height: '54px' }}>
              <div className=" w-full flex flex-col kids-teens-select">
                <CustomSelector
                  options={membresias}
                  placeholder="Seleccionar membresía*"
                  onChange={(e) => {
                    onSelectChange('membership', e.value)
                    if(selectedMembership !== e.value){
                      setSelectedMembership(e.value)
                      onSelectChange('clubId', null)
                      setResetSelect(true)
                      setSelectedOption(undefined)
                      setSelectKey(prevKey => prevKey + 1);
                      setSelectedClubOption(undefined)
                    }
                    setUserType(e.value)
                    handleInputChange()            
                  }}                 
                  borderRadius="4px"
                  border="1px solid white"
                  backgroundColor="black"
                  className='pl-1 w-full white-placeholder'
                  error={onclick && value.clubId === 0 ? true : false}
                  name="membership"
                  isSearchable={false}
                />
                {submit && <p className="text-red-600">{value.clubId === 0 && 'Requerido'}</p>}
              </div>
              <div className=" w-full flex flex-col kids-teens-select" onTouchStart={handleCombinedClick} onClick={handleCombinedClick}>
                <CustomSelector
                  key={selectKey}
                  options={selectedClubs}
                  placeholder="Seleccionar sede*"
                  onChange={(e) => {
                    setSelectedClubOption(e.value);
                    onSelectChange('clubId', e.value)
                    if(selectedClubOption){
                      handleInputChange()
                    }
                    setSubmit(false)
                  }}  
                  borderRadius="4px"
                  border={selectorBorder}
                  backgroundColor="black"
                  className='pl-1 w-full white-placeholder'
                  error={onclick && value.membership === undefined ? true : false}
                  name="clubId"
                  preventResizingOnOpen
                  noOptionsMessage='Deberás elegir tu membresía'
                  isSearchable={false}
                  origin="Kids & teens"
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={isMenuOpen}
                  hasOptions={selectedMembership !== undefined ? true : false}
                />
                {submit && <p className="text-red-600">{value.clubId === 0 && 'Requerido'}</p>}
              </div>
            </div>
            <div className='campos-obligatorios' ref={camposObligatorios}>
              <p className="text-sm">* Campos obligatorios</p>
            </div>
          </div>
        )
  }

  return (
    <div className="md:flex bg-black pt-24 mb-10 md:mb-4">
      <div className="w-2/5 hidden md:block teens-banner">
      </div>
      <div className='border-white-line'></div>
      <h1 className="block md:hidden text-2xl font-bold col-start-1 col-end-3 kids-teens-mobile-title text-center">MEMBRESÍA KIDS<br/>& TEENS</h1>
      <div className="md:hidden kids-banner-mobile">
      </div>
      <div className="w-full sm:w-3/5 kids-teens-image">
        <h1 className="hidden lg:block ml-8 lg:mb-4 text-2xl font-bold col-start-1 col-end-3 text-center" onClick={handleScrollClick}>MEMBRESÍA KIDS & TEENS</h1>
        <Observer>
          {() => {
            return (
              <form
                name="form"
                id="form kids-and-teens-form"
                className=""
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmitForm(value)
                }}
              >
                <Frequentquestions frequentQuestions={frequentQuestions} origin="kids-and-teens" />
                <div className="container">
                  <h2 className="dropdown-toggle faq-title lg:text-lg sm:text-base font-bold datos text-center lg:text-left">COMPLETÁ TUS DATOS PARA <br className="lg:hidden"/> RECIBIR INFORMACIÓN</h2>
                  {KidsAndTeensForm()}
                  {isDisabled === true ? (
                    <button
                      type="submit"
                      className={`white-pill-button w-1/2 self-center kids-teens-submit disabled-button disabled:opacity-50 disabled:pointer-events-none`}
                      onClick={(e) => {
                        setSubmit(true)
                      }}
                      disabled
                      // ref={targetElementRef}
                    >{formStatus}</button>
                  ) : (
                    <button
                      type="submit"
                      className={`white-pill-button w-1/2 self-center kids-teens-submit`}
                      onClick={(e) => {
                        setSubmit(true)
                      }}
                    >{formStatus}</button>
                  )}  
                </div>
                <div ref={targetElementRef} className={`hidden`} style={{ marginTop: '210px', position:"relative", zIndex: 9999 }}></div>
              </form>
            )
          }}
        </Observer>
      </div>
    </div>
  );
}