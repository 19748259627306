import React, { FC } from 'react'
import './App.css'
import { Header } from './components/Header/Header'
import { Notifications } from './components/Notifications/Notifications'
import { Footer } from './components/Footer/Footer'
import { hot } from 'react-hot-loader/root'
import { Image } from './components/Image'

const whatsappIcon = require('./assets/WhatsApp-Logo.svg')

const App: FC = ({ children }) => {
  return (
    <div className="relative flex flex-col justify-between flex-grow">
      <Header />
      <>{children}</>
      <Notifications />

      <Footer />
      <a href="https://bit.ly/Mega_bot_webmega" className="stickyImg">
        <Image alt={'whatsapp icon'} placeholder={whatsappIcon} />
      </a>
    </div>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
