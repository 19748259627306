import React from 'react'
import { Image } from '../../components/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import qr from '../../assets/qr-afip.jpg'
import medicus from '../../assets/medicus.png'
import './Footer.css'
import { ReactComponent as MEGATLONLOGO } from '../../assets/MEGATLON.svg'
import { ReactComponent as EmpresaB50 } from '../../assets/EmpresaB_50px.svg'
import { ReactComponent as MejoresEmpresas } from '../../assets/Logo-mejores-empresas.svg'

export const Footer: React.FC = () => {
  const SocialMediaIcons = () => {
    return(
      <>
        <a href="https://www.instagram.com/megatlon/">
          <div>
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        </a>
        <a href="https://www.facebook.com/Megatlon/">
          <div>
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
        </a>
        <a href="https://www.youtube.com/channel/UCNU3ljQ8sKREQZpTv8mdM6w">
          <div>
            <FontAwesomeIcon icon={faYoutube} />
          </div>
        </a>
        <a href="https://br.linkedin.com/company/megatlon?trk=public_profile_experience-item_profile-section-card_image-click">
          <div>
            <FontAwesomeIcon icon={faLinkedin} />
          </div>
        </a>
      </>
    )
  }

  const FirstColumnLinks = () => {
    return (
      <>
        <a className='footer-link' href='/#/sedes'>
          <p style={{ width: 'fit-content' }}>SEDES</p>
        </a>
        <a className='footer-link' href='/#/paseGratis'>
          <p style={{ width: 'fit-content' }}>PASE DE PRUEBA</p>
        </a>
        <a className='footer-link' href='/#/kidsandteens'>
          <p style={{ width: 'fit-content' }}>KIDS & TEENS</p>
        </a>
        <a className='footer-link' href='/#/rrhh'>
          <p style={{ width: 'fit-content' }}>RRHH</p>
        </a>
        <a className='footer-link' href='/#/contact'>
          <p style={{ width: 'fit-content' }}>CONTACTO</p>
        </a>
      </>
    )
  }

  const SecondColumnLinks = () => {
    return (
      <>
        <a href='/#/legales' className="footer-link">
          <p className="footer-links second-column">Términos y condiciones</p>
        </a>
        <a href='/#/pautas' className="footer-link">
          <p className="footer-links second-column">Pautas de convivencia</p>
        </a>
        <a href='/#/Boton-de-Arrepentimiento' className="footer-link">
          <p className="footer-links second-column">Botón de arrepentimiento</p>
        </a>
        <a href={require('../../assets/ACUERDO ACYMA MEGATLON.pdf')} className="footer-link">
          <p className="footer-links second-column">Acuerdo ACYMA</p>
        </a>
      </>
    )
  }

  const FooterMobile = () => {
    return (
      <div className="grid grid-cols-2 pt-6 pb-4 footer-mobile">
            <div className='w-full logo-and-sm'>
              <div className='logo-footer-mobile'>
                <MEGATLONLOGO width="100%" />
              </div>
              <div className="flex flex-row justify-between text-2xl mobile">
                <SocialMediaIcons />
              </div>
              <hr style={{ width: "40%", marginTop: "20px", border: "solid 1px white" }}></hr>
            </div>
            <div className="flex mobile-footer-links-container">
              <div className="w-45 font-bold">
                <FirstColumnLinks />
              </div>
              <div className="w-55 mobile-second-column">
                <SecondColumnLinks />
                <div className="flex flex-row justify-start items-center gap-8 mejores-empresas-div">
                    <EmpresaB50 height="50px" />
                    <MejoresEmpresas height="35px" className='mejores-empresas-mobile' />
                </div>
              </div>
            </div>
            <div className='flex w-full data-fiscal-mobile'>
                <a href={'http://qr.afip.gob.ar/?qr=FNQn1yu3uxxe-toXov2ncw,,'}>
                  <Image alt={'QR'} placeholder={qr} className="object-scale-down qr-data-mobile" />
                </a>
                <p className='copyright-mobile'>Megatlon ® Copyright 2023<br></br>
                Todos los derechos reservados<br></br>
                GIMNASIOS ARGENTINOS SA<br></br>
                CUIT: 30-68897938-9<br></br>
                Pasco 48, Capital Federal, Argentina</p>
            </div>
      </div>
    )
  }

  const FooterDesktop = () => {
    return (
      <div
        className="grid grid-cols-4 footer-desktop"
        style={{ width: '100%', borderTop: '2px solid white', padding: '30px 0', height: '260px' }}>
        <div className="col-start-1 col-end-2 flex flex-row justify-center">
          <div className="flex flex-col" style={{ width: '220px' }}>
            <div>
              <MEGATLONLOGO width="100%" />
            </div>
            <div className="flex flex-row justify-between text-4xl" style={{ fontSize: '1.6rem', marginTop: '9px' }}>
              <SocialMediaIcons />
            </div>
            <div className="flex qr-container">
              <div className="w-1/4 flex items-start">
                <a href={'http://qr.afip.gob.ar/?qr=FNQn1yu3uxxe-toXov2ncw,,'}>
                  <Image alt={'QR'} placeholder={qr} className="object-scale-down qr-data" />
                </a>
              </div>
              <div className="w-3/4 flex items-end justify-end">
                <p className='copyright'>Megatlon ® Copyright 2023<br></br>
                Todos los derechos reservados<br></br>
                GIMNASIOS ARGENTINOS SA<br></br>
                CUIT: 30-68897938-9<br></br>
                Pasco 48, Capital Federal, Argentina</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-start-2 col-end-3 flex flex-row justify-end">
          <div className="flex flex-col w-2/3 justify-start second-column">
            <FirstColumnLinks />
          </div>
        </div>
        <div className="col-start-3 flex flex-row justify-end flex-nowrap last-column">
          <div className="flex flex-col w-1/2 justify-start last-column-container">
            <SecondColumnLinks />
            <div className="flex flex-row justify-start items-center gap-8 images-container mejores-empresas-div">
                <div className="w-45">
                  <EmpresaB50 className='b-50' />
                </div>
                <div className="flex-1">
                  <MejoresEmpresas className='logo-mejores-empresas' />
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <div style={{ borderTop: '1px solid white', borderBottom: '1px solid white', backgroundColor: '#2D2B2B' }}>
          <div className="footer-title flex flex-col md:flex-row md:justify-center items-center">
            <p>Nuestros Partner</p>
          </div>
          <div className="partner-slider flex flex-row justify-center ">
            <div className="flex justify-center items-center">
              <a href='https://medicus.com.ar/images_envios/flyersociosmegatlon.pdf' target='_blank' rel='noopener noreferrer'>
                <Image alt={'Medicus'} placeholder={medicus} className="object-scale-down p-8 partner-image" />
              </a>
            </div>
          </div>
        </div>
        <FooterDesktop />
        <FooterMobile />
      </div>
    </>
  )
}
