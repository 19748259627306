import React, { useContext } from 'react'
import email from '../../assets/mail.svg'
import { Input } from '../../components/Input'
import { StoreContext, useStores } from '../../Context'
import { useSimpleForm } from '../../utils'
import { Link } from 'mobx-router'
import { privateRoutes } from '../../routes'

export const Recupera = () => {
  const store = useContext(StoreContext)
  const { userStore, notificationStore } = useStores()
  const [v, handleChange] = useSimpleForm({ email: '' })

  return (
    <div className="flex flex-col flex-grow space-between bg-cover pt-24 gap-5 items-center  h-screen">
      <h2 className=" text-2xl text-center font-extrabold">RESTABLECÉ TU CONTRASEÑA</h2>

      <form
        className="w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/4 flex flex-col gap-5  h-screen max-h-90 justify-center"
        onSubmit={(e) => {
          e.preventDefault()
          userStore
            .requestRestorePassword(v.email)
            .then((res) => {
              if (res.code === 0) {
                notificationStore.addNotification('Gracias', 'Se te enviará un correo al mail proporcionado.')
                store.router.goTo(privateRoutes.home)
              } else {
                notificationStore.addNotification('Error', res.message, 'error')
              }
            })
            .catch((e) => {
              notificationStore.addNotification('Error', e.message, 'error')
            })
        }}>
        <div>
          <Input
            placeholder="Ingresá tu mail"
            icon={email}
            // className="mb-10"
            name="email"
            value={v.email}
            onChange={handleChange}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="white-pill-button">
            ENVIAR
          </button>
        </div>
      </form>

      <Link router={store.router} route={privateRoutes.home} className="text-white font-bold text-center w-full h-20">
        ¿Ya eres miembro? LogIn
      </Link>
    </div>
  )
}
