import React, { useContext } from 'react'
import { StoreContext, useStores } from '../../Context'
import { useObserver } from 'mobx-react-lite'
import { CustomSelector } from '../../components/shared/CustomSelector/CustomSelector'
import { Link } from 'mobx-router'
import { privateRoutes } from '../../routes'

export const Reservas = () => {
  const { clasesStore, notificationStore, clubsStore, userStore } = useStores()
  const store = useContext(StoreContext)

  const selectClub = (e: any) => {
    clubsStore.clubes.forEach((element) => {
      if (e === element.nombre) {
        userStore.setSede(element.clubId)
      }
    })
  }

  return useObserver(() => (
    <div className=" flex flex-grow flex-col mx-4 gap-5 my-10" style={{ paddingTop: '65px' }}>
      <div className="md:flex md:flex-row w-full md:justify-between">
        <h1 className="text-4xl font-bold text-center md:text-left w-full md:w-2/3 h-16">Reservas</h1>
        <div className="md:w-1/3">
          <CustomSelector options={clubsStore.selectClubs} placeholder="Sede" onChange={selectClub} />
        </div>
      </div>

      <div className="p-4 bg-mainGray rounded-lg flex flex-col gap-5 ">
        <h3 className="text-xl font-bold">Próximas</h3>
        <ul>
          {clasesStore.bookedClasses.length === 0 ? 'No tienes ninguna clases reservada aún' : ''}
          {clasesStore.clasesAgendadas.map((bc) => (
            <li className="mt-6">
              <h2 className="mr-2 text-xl font-bold">
                {bc.club} | {bc.disciplina}
              </h2>
              <span>
                {bc.fechaHoraFormatted} - {bc.coach}
              </span>
              <div>
                <button
                  className="white-pill-button"
                  onClick={(_) => {
                    clasesStore
                      .cancelClass({ idUsuarioClase: bc.claseId + '' })
                      .then((res) => {
                        if (res.code === 0) {
                          notificationStore.addNotification('Clase cancelada')
                          clasesStore.getBookedList()
                        } else {
                          throw new Error(res.message)
                        }
                      })
                      .catch((e) => {
                        notificationStore.addNotification('Ups!', e.message, 'error')
                      })
                  }}>
                  Cancelar
                </button>
              </div>
            </li>
          ))}
        </ul>

        <Link className="white-pill-button self-center text-center" router={store.router} route={privateRoutes.clases}>
          Ver clases
        </Link>
      </div>
    </div>
  ))
}
