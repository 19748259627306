import React, { useState, CSSProperties, FC } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface InputProps {
  placeholder: string
  icon?: string
  iconStyle?: CSSProperties
  inputStyle?: CSSProperties
  blackPlaceholder?: boolean
  value?: string
  onChange?: (e: any) => void
  className?: string
  fieldType?: 'text' | 'password' | 'email' | 'number' | 'tel'
  name?: string
  style?: CSSProperties
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  maxLength?: number
  disabled?: boolean
  required?: Boolean
  dni?: Boolean
  submit?: Boolean
  id?: number
  onClick?: (e: any) => void
}

const ErrorLabel: FC<any> = (props: any) => {
  return <p className={`text-red-600`}>{props.children}</p>
}

export const Input = ({
  placeholder,
  icon,
  iconStyle,
  blackPlaceholder,
  onChange,
  value,
  onFocus,
  onBlur,
  inputStyle,
  name,
  fieldType = 'text',
  className = '',
  id,
  disabled = false,
  required,
  submit,
}: InputProps) => {
  const [isFocused, setFocus] = useState(false)
  const [hasContent, setContentStatus] = useState('')
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleClick = () => {
    setShowPassword(!showPassword)
  }

  const validationHandler = () => {
    switch (placeholder.replace(/\*/gi, '')) {
      case 'Nombre':
        if (!error && !hasContent) setError(true)
        return <>{!hasContent ? <ErrorLabel>Requerido</ErrorLabel> : ''}</>
      case 'Apellido':
        if (!error && !hasContent) setError(true)
        return <>{!hasContent ? <ErrorLabel>Requerido</ErrorLabel> : ''}</>
      case 'Nombre de empresa':
        if (!error && !hasContent) setError(true)
        return <>{!hasContent ? <ErrorLabel>Requerido</ErrorLabel> : ''}</>

      case 'Teléfono':
        if (!error && !hasContent) setError(true)
        if (!error && hasContent.length < 6) setError(true)
        if (!error && hasContent.length > 12) setError(true)
        return (
          <>
            {hasContent ? (
              isNaN(Number(hasContent)) ? (
                <ErrorLabel>No es un número de teléfono válido</ErrorLabel>
              ) : hasContent.length < 6 ? (
                <ErrorLabel>No es un número de teléfono válido</ErrorLabel>
              ) : hasContent.length > 12 ? (
                <ErrorLabel>No es un número de teléfono válido</ErrorLabel>
              ) : (
                ''
              )
            ) : (
              <ErrorLabel>Requerido</ErrorLabel>
            )}
          </>
        )
      case 'Cantidad de empleados':
        if (!error && !hasContent) setError(true)
        if (!error && isNaN(Number(hasContent))) setError(true)

        return (
          <>
            {hasContent ? (
              isNaN(Number(hasContent)) ? (
                <ErrorLabel>Debe ser un valor numérico</ErrorLabel>
              ) : (
                ''
              )
            ) : (
              <ErrorLabel>Requerido</ErrorLabel>
            )}
          </>
        )
      case 'DNI':
        if (!error && !hasContent) setError(true)
        if (!error && hasContent.length !== 8) setError(true)

        return (
          <>
            {hasContent ? (
              isNaN(Number(hasContent)) ? (
                <ErrorLabel>No es un número de DNI válido</ErrorLabel>
              ) : (
                hasContent.length !== 8 && <ErrorLabel>No es un DNI válido</ErrorLabel>
              )
            ) : (
              <ErrorLabel>Requerido</ErrorLabel>
            )}
          </>
        )
      case 'Password': {
        return <>{!hasContent && <ErrorLabel>Requerido</ErrorLabel>}</>
      }
      case 'Email':
        let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (!error) {
          if (!hasContent) setError(true)
          if (!hasContent.match(mailformat)) {
            setError(true)
          }
        }

        return (
          <>
            {hasContent ? (
              !hasContent.match(mailformat) && <ErrorLabel>Debe ingresar un mail válido</ErrorLabel>
            ) : (
              <ErrorLabel>Requerido</ErrorLabel>
            )}
          </>
        )
      default:
        return
    }
  }
  const maxLengthFunction = () => {
    let maxLength = 100
    switch (placeholder) {
      case 'DNI':
        maxLength = 8
        break
      case 'Telefono':
        maxLength = 12
        break
      case 'Número de la tarjeta':
        maxLength = 16
        break
      case 'MM/YY':
        maxLength = 4
        break
      case 'CVV':
        maxLength = 3
        break

      default:
        break
    }
    return maxLength
  }
  return (
    <>
      <div className={`w-full h-full relative`}>
        <label
          className={`  ${className} material-input border-none flex items-center py-3 bg-transparent
        ${isFocused || hasContent ? 'material-input-focused bg-transparent ' : 'bg-transparent'}
        `}
          style={{ border: error ? 'solid 1px red' : 'solid 1px white' }}>
          <input
            id={`${id}`}
            required={required ? true : false}
            minLength={placeholder === 'DNI' ? 8 : placeholder === 'Teléfono' ? 6 : 1}
            maxLength={maxLengthFunction()}
            type={placeholder === 'Contraseña' ? (showPassword ? 'text' : 'password') : fieldType}
            disabled={disabled}
            style={inputStyle}
            defaultValue={value}
            placeholder={placeholder}
            className={`w-full pl-3 bg-transparent focus:outline-none focus:shadow-none focus:bg-transparent active:bg-transparent
            ${blackPlaceholder ? 'white-input' : 'black-input'}
            `}
            onChange={(e) => {
              if (onChange) {
                onChange(e)
                setContentStatus(e.target.value)
                if (error) setError(false)
              }
            }}
            onFocus={(e) => {
              setFocus(true)
              if (onFocus) {
                onFocus(e)
              }
            }}
            onBlur={(e) => {
              setFocus(false)
              if (onBlur) {
                onBlur(e)
              }
            }}
            name={name ? name : placeholder}
          />
          {icon ? (
            placeholder === 'Contraseña' ? (
              <i className="absolute right-0 pb-1" onClick={handleClick}>
                {showPassword ? (
                  <FontAwesomeIcon icon={faEye} className="text-white mr-3 text-lg" />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} className="text-lightGray mr-3 text-lg" />
                )}
              </i>
            ) : (
              <i className="absolute right-0 pb-1">
                <img
                  alt="icono"
                  src={icon}
                  className="text-lightGray mr-3 text-lg"
                  style={{ width: '1.20rem', height: '1.20rem' }}
                />
              </i>
            )
          ) : (
            ''
          )}
        </label>
        <div className="relative" style={{ height: '1rem' }}>
          {submit && validationHandler()}
        </div>
      </div>
    </>
  )
}
