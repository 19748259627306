import { Link } from 'mobx-router'
import React, { useContext } from 'react'
import { StoreContext, useStores } from '../Context'
import { privateRoutes } from '../routes'

export const LoginOrRegister = () => {
  const { productosStore } = useStores()
  const store = useContext(StoreContext)

  return (
    <div>
      <h1 className="mb-8 text-2xl font-bold text-black text-center">
        Inicia sesión o regístrate para finalizar tu compra
      </h1>
      <div className="flex justify-around">
        <Link router={store.router} route={privateRoutes.home}>
          <button
            style={{ width: '10rem' }}
            className="white-pill-button border border-black"
            onClick={() => productosStore.toggleModal()}>
            Login
          </button>
        </Link>
        <Link router={store.router} route={privateRoutes.home}>
          <button style={{ width: '10rem' }} className="white-pill-button border border-black">
            Registrarse
          </button>
        </Link>
      </div>
    </div>
  )
}
