import {rotate, withTokenPOST} from '../utils';
import {AppStore} from './AppStore';
import {useState} from 'react';
import {CategoriaDTO, Producto} from '../lib/megaStore';
import {action, computed, observable, onBecomeObserved, onBecomeUnobserved, runInAction, toJS, when} from 'mobx';
import {UserStore} from './UserStore';
import {Result} from '../lib/megaUsers';
import {take, throttle} from 'lodash-es';

const urls = {
  'getPackages': '/api/service/products/packages',
  'getProducts': '/api/service/products/products',
};

export const useProductosStore = (appStore: AppStore,
                                  userStore: UserStore) => {
  const [store] = useState(() => new ProductosStore(appStore, userStore));
  if (!store) {
    throw new Error('uninitialized productos store');
  }

  return store;
};


export class ProductosStore {
  @observable
  packages: CategoriaDTO[] = [];

  @observable
  products: CategoriaDTO[] = [];

  @observable
  slideshowIndex: number = 0;

  @observable
  productWithOptions: Producto | undefined = undefined;

  @observable
  productInfo: Producto | undefined = undefined;

  @observable
  modalAbierto = false;

  @observable
  modalMasInfoAbierto = false;

  @observable
  clubId = 0;

  @observable
  planesSlidesAmount = 3;

  constructor(private appStore: AppStore,
              private userStore: UserStore) {
    const slidesAmountAdjuster = () => {
      runInAction(() => {
        if (window.innerWidth < 800) {
          this.planesSlidesAmount = 1;
        } else if (window.innerWidth >= 800 && window.innerWidth <= 1100) {
          this.planesSlidesAmount = 2;
        } else {
          this.planesSlidesAmount = 3;
        }
      });
    };
    const throttledResizeListener = throttle(slidesAmountAdjuster, 16);

    onBecomeObserved(this, 'packages', () => {
      this.retrievePackagesAndProducts();
      slidesAmountAdjuster();
      window.addEventListener('resize', throttledResizeListener)
    });
    onBecomeUnobserved(this, 'packages', () => {
      window.removeEventListener('resize', throttledResizeListener);
    });
  }


  @action
  public async retrievePackagesAndProducts() {
    await when(() => !!this.userStore.userClubOrDefault);
    const [packages, products] = await Promise.all([
      this.getPackages(`${this.userStore.userClubOrDefault}`),
      this.getProducts(`${this.userStore.userClubOrDefault}`),
    ]);

    this.packages = packages.result;
    this.products = products.result;
  }

  @computed
  get getProductsOfPackages(): Producto[] {
    return this.packages.flatMap(categoria =>
      categoria.subCategorias.flatMap(sc =>
        sc.productos.map(p => ({
          subcategoriaId: sc.subcategoriaId,
          subcategoriaNombre: sc.nombre,
          subcategoriaDesc: sc.descripcion,
          subcategoriaImagen: sc.imagen,
          ...p
        })))).sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
  }

  @computed
  get getPackagesSlide(): Producto[] {
    const ret = toJS(this.getProductsOfPackages);
    return take(rotate(ret, this.slideshowIndex % ret.length), this.planesSlidesAmount);
  }

  @action
  public toggleModal(setModal?: boolean) {
    this.modalAbierto = (setModal !== undefined ? setModal : !this.modalAbierto);
  }

  @action
  public toggleInfoModal(show?: boolean, selectedProduct?: Producto) {
    this.modalMasInfoAbierto = (show !== undefined ? show : !this.modalMasInfoAbierto);
    this.productInfo = selectedProduct;
  }

  @action
  public packageSlideNext() {
    this.slideshowIndex++;
  }

  @action
  public packageSlidePrev() {
    this.slideshowIndex--;
  }

  @action
  public setProductoDetail(p?: Producto) {
    this.productWithOptions = p;
  }

  @computed
  get getProductsOfProducts(): Producto[] {
    return this.products.flatMap(categoria =>
      categoria.subCategorias.flatMap(sc =>
        sc.productos.map(p => ({
          subcategoriaId: sc.subcategoriaId,
          subcategoriaNombre: sc.nombre,
          subcategoriaDesc: sc.descripcion,
          subcategoriaImagen: sc.imagen,
          ...p
        })))).sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
  }

  async getPackages(clubId: string): Promise<Result<CategoriaDTO[]>> {
    return await withTokenPOST<Result<CategoriaDTO[]>>(`${process.env.REACT_APP_STORE_SERVER}${urls.getPackages}`,
      this.appStore.getToken, JSON.stringify({clubId: clubId}));
  }

  async getProducts(clubId: string): Promise<Result<CategoriaDTO[]>> {
    return await withTokenPOST<Result<CategoriaDTO[]>>(`${process.env.REACT_APP_STORE_SERVER}${urls.getProducts}`,
      this.appStore.getToken, JSON.stringify({clubId: clubId}));
  }
} 
