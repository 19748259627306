import React from 'react'
import { AppNotification } from '../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimes, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useStores } from '../../Context'
import { Observer } from 'mobx-react-lite'

export const Notifications = () => {
  const { notificationStore } = useStores()

  return (
    <div className="z-50 fixed bottom-0 left-0 flex flex-col mb-10 ml-auto mr-10">
      <Observer>
        {() => (
          <>
            {' '}
            {notificationStore.notifications.map((n) => (
              <Notification {...n} key={n.id} />
            ))}{' '}
          </>
        )}
      </Observer>
    </div>
  )
}

const Notification: React.FC<AppNotification> = ({ id, title, type, desc }) => {
  const icon = {
    ok: faCheckCircle,
    warn: faExclamationCircle,
    error: faTimesCircle,
  }

  const { notificationStore } = useStores()

  return (
    <div
      className="flex items-center p-2 ml-4 mb-4 shadow-lg rounded-lg notification "
      style={{ boxShadow: '1px 1px 2px 1px #CCC', background: 'black' }}>
      <FontAwesomeIcon icon={icon[type]} className={`icon ${type}`} />
      <div>
        <h3 className="font-bold" style={{ color: 'red' }}>
          {title}
        </h3>
        <p className="text-sm" style={{ color: 'white' }}>
          {' '}
          {desc}{' '}
        </p>
      </div>
      <FontAwesomeIcon
        icon={faTimes}
        className="ml-auto mr-4 text-sm text-gray-400 cursor-pointer"
        onClick={(_) => notificationStore.removeNotification(id)}
      />
    </div>
  )
}
