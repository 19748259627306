import React, { useState } from 'react'
import { useStores } from '../Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Observer, useObserver } from 'mobx-react-lite'

export const SedeSelector = () => {
  const { clubsStore, userStore } = useStores()
  const [isOpen, setOpen] = useState(false)

  return useObserver(() => (
    <div className="relative">
      <button className="relative w-full white-pill-button focus:outline-none" onClick={() => setOpen((v) => !v)}>
        <Observer>
          {() => {
            const club = clubsStore.clubes.find((c) => userStore.userClubOrDefault === c.clubId)
            return <>{club ? club.nombre : '...'}</>
          }}
        </Observer>
        <div
          style={{ width: '25px', height: '25px' }}
          className="absolute top-0 right-0 mt-2 mr-2 text-gray-900 bg-white rounded-full">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-sm" />
        </div>
      </button>
      {isOpen ? (
        <ul
          className="absolute top-0 right-0 z-10 w-full mt-10 overflow-y-auto border border-b border-gray-200 rounded-lg"
          style={{ maxHeight: '500px' }}>
          {clubsStore.clubes.map((c) => (
            <li
              key={c.clubId}
              className="flex items-center px-6 py-2 font-bold bg-black hover:bg-white hover:text-black pointer group"
              onClick={(_) => {
                userStore.setSede(c.clubId)
                setOpen(false)
              }}>
              {c.nombre}
              {userStore.userClubOrDefault === c.clubId ? (
                <FontAwesomeIcon icon={faStar} className="ml-auto mr-2 text-sm text-white group-hover:text-black" />
              ) : null}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  ))
}
