import { useObserver } from 'mobx-react-lite';
import React from 'react';
import './Regreting.css';
import bannerRegreting from '../../assets/Boton de arrepentimiento escritorio.jpg';
import bannerRegretingMobile from '../../assets/Botón de arrepentimiento mobile.jpg';

const SucceedeRegretingPage = () => {

  return useObserver(() => (
    <div className="relative min-h-75 flex flex-col justify-center gap-5 items-center m-auto text-center">
      <img alt="cover" className='hidden md:block imagen-fondo' src={bannerRegreting} />
      <img alt="cover" className='block md:hidden' src={bannerRegretingMobile} />
      <div className='success-text-container'>
        <div className="text-lg md:text-2xl font-extrabold">SOLICITUD ENVIADA CON ÉXITO</div>
        <div className="text-base md:text-xl mt-6 p-0 sm:p-0 sm:px-35">
          Hemos recibido tu solicitud de arrepentimiento de compra.<br/><br className="lg:hidden"/>
          El equipo de atención al cliente se encuentra trabajando en tu caso.<br/><br className="lg:hidden"/>
          Te enviamos un mail confirmando la solicitud.
        </div>
      </div>
      <div className='list'>
        <p>Te recordamos los requisitos para avanzar con tu trámite:</p>
        <p>1. Solicitar el arrepentimiento dentro de los 10 días corridos de realizar la compra.</p>
        <p>2. Exclusivo para compras online o telefónicas.</p>
      </div>
      <a className="success-mail" href='mailto:atencionalsocio@megatlon.com.ar'>atencionalsocio@megatlon.com.ar</a>
    </div>
  ));

};

export default SucceedeRegretingPage;