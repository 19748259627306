import React from 'react'
import { ReactCreditCard } from '@repay/react-credit-card'
import '@repay/react-credit-card/dist/react-credit-card.css'
import { FOCUS_TYPE } from '@repay/react-credit-card/dist/ReactCreditCard'
import { Input } from '../../components/Input'
import { faCcMastercard, faCcVisa, faCcAmex } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
type CardType = 'mastercard' | 'visa' | 'amex'
interface UsuarioFormaPago {
  id: number
  number: string
  cardType: CardType
}

export const FormaPago = () => {
  const [values, setValues] = React.useState({
    name: '',
    number: '',
    expiration: '',
    cvc: '',
  })
  const handleChange = React.useCallback(
    (event) => {
      const { name, value } = event.target
      setValues((v) => ({ ...v, [name]: value }))
    },
    [setValues]
  )

  const handleChangeOnlyNums = React.useCallback(
    (ev) => {
      const { name, value } = ev.target
      if (value.length === 0 || !isNaN(parseInt(value))) {
        setValues((v) => ({ ...v, [name]: value }))
      }
    },
    [setValues]
  )

  const [focused, setFocus] = React.useState<FOCUS_TYPE | undefined>(undefined)
  const handleFocus = React.useCallback((event) => setFocus(event.target.name as FOCUS_TYPE), [setFocus])
  const handleBlur = React.useCallback(() => setFocus(undefined), [setFocus])

  const formasPago: UsuarioFormaPago[] = [{ id: 1, cardType: 'mastercard', number: '2055' }]

  const getCardIcon = (cardType: CardType) => {
    switch (cardType) {
      case 'mastercard':
        return faCcMastercard
      case 'visa':
        return faCcVisa
      case 'amex':
        return faCcAmex
      default:
        return faCcVisa
    }
  }

  return (
    <div className="flex flex-grow flex-col w-full justify-center items-center text-center md:text-left md:h-full md:pt-12 pt-24 my-8 ">
      {/* <LateralBar />  */}

      <h1 className="text-4xl font-bold text-center md:text-left w-full md:w-2/3 h-16">Tus Compras</h1>
      <div className="w-full md:w-2/3 p-4 rounded-lg" style={{ backgroundColor: '#232427' }}>
        <div className="flex flex-wrap justify-between  flex-grow">
          <div className="w-full md:w-1/2 flex flex-col items-center ">
            <h3 className="mb-5 text-lg font-bold text-center md:text-start">Agrega una tarjeta de débito/crédito</h3>
            <ReactCreditCard {...values} focused={focused} />
            <h3 className="my-5 text-lg font-bold text-center md:text-start">Tus formas de pago</h3>
            <ul className="w-1/2 ">
              {formasPago.map((fp) => (
                <li className="flex justify-between w-full  " key={fp.id}>
                  <div>
                    <FontAwesomeIcon className="mr-4 text-lg" icon={getCardIcon(fp.cardType)} />
                    •••• {fp.number}
                  </div>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col flex-grow gap-5 md:gap-0 justify-around  w-full p-5 md:w-1/2">
            <Input
              name="name"
              placeholder="Titular de la tarjeta"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.name}
            />
            <Input
              name="number"
              placeholder="Número de la tarjeta"
              onChange={handleChangeOnlyNums}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.number}
            />
            <div className="flex flex-col md:flex-row justify-around gap-5 md:gap-0">
              <div className="w-full md:w-1/3">
                <Input
                  name="expiration"
                  placeholder="MM/YY"
                  onChange={handleChangeOnlyNums}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={values.expiration}
                />
              </div>
              <div className="w-full md:w-1/3">
                <Input
                  name="cvc"
                  placeholder="CVV"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={values.cvc}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
