import { AppStore } from './AppStore'
import { withTokenGET } from '../utils'
import { action, autorun, computed, observable, runInAction } from 'mobx'
import { CategoriaWorkoutDTO } from '../lib/megaClasses'
import { Result } from '../lib/megaUsers'

const urls = {
  workouts: '/api/service/workout/categories',
}

export class OnDemandStore {
  @observable
  categoriasWorkouts: CategoriaWorkoutDTO[] = []

  normalizedWorkouts: any[] = []

  @observable
  categoriaSeleccionada?: number | undefined

  @observable
  actividadSeleccionada?: number | undefined

  @observable
  workoutDetail: any = undefined

  @observable
  workoutDetailId: number | undefined

  constructor(private appStore: AppStore) {
    autorun(() => {
      if (!this.appStore.token) return
      this.getCategoriasWorkouts().then((res) => {
        runInAction(() => {
          this.categoriasWorkouts = res.result
          this.normalizedWorkouts = []
          res.result.forEach((el) => (this.normalizedWorkouts[el.idCategoriaWorkout!] = el))
          this.normalizedWorkouts.forEach((cat) => {
            let disciplinas: any[] = []
            cat.tiposClase.forEach((nivel: { tiposClase: any[] }) => {
              nivel.tiposClase.forEach((disciplina) => {
                if (disciplina.workouts.length > 0) {
                  if (disciplinas[disciplina.idDisciplinaWorkout]) {
                    disciplinas[disciplina.idDisciplinaWorkout] = disciplinas[disciplina.idDisciplinaWorkout].concat(
                      disciplina.workouts
                    )
                  } else {
                    disciplinas[disciplina.idDisciplinaWorkout] = {
                      idDisciplinaWorkout: disciplina.idDisciplinaWorkout,
                      nombre: disciplina.nombre,
                      workouts: disciplina.workouts,
                    }
                  }
                }
              })
            })
            this.normalizedWorkouts[cat.idCategoriaWorkout!].disciplinas = disciplinas
          })
        })
      })
    })
  }

  @computed
  public get selectCategorias() {
    let res = this.categoriasWorkouts.map((cc) => ({ value: cc.idCategoriaWorkout, label: cc.nombre }))
    // console.log('Selected category func::', res)
    return res
  }

  @computed
  public get selectActividad() {
    // console.log("Inside select Actividad function");
    if (this.categoriaSeleccionada) {
      let workouts = this.normalizedWorkouts[this.categoriaSeleccionada].disciplinas.map(function (disc: any) {
        return { value: disc.idDisciplinaWorkout, label: disc.nombre }
      })
      workouts = workouts.filter(function (el: any) {
        return el
      })
      return workouts
    }
    return []
  }

  @computed
  public get filteredWorkouts() {
    let workouts: any[] = []
    if (this.categoriaSeleccionada) {
      if (!this.actividadSeleccionada) {
        let disciplinas = this.normalizedWorkouts[this.categoriaSeleccionada!].disciplinas.filter(function (el: any) {
          return el
        })
        disciplinas.forEach(function (disc: any) {
          workouts = workouts.concat(disc.workouts)
        })
      } else {
        workouts =
          this.normalizedWorkouts[this.categoriaSeleccionada!].disciplinas[this.actividadSeleccionada!].workouts
      }
    }

    return workouts
  }

  @action
  public clearWorkoutDetail() {
    this.workoutDetail = undefined
  }

  @action
  public clearWorkoutDetailId() {
    this.workoutDetailId = undefined
  }

  @action
  public setWorkoutDetail(workout: any) {
    this.workoutDetailId = workout.idWorkout
    this.workoutDetail = workout
  }

  @action
  public setCategoriaSelect(idCategoria: number) {
    // console.log("SetCategoriaSelect:: ", idCategoria);
    this.categoriaSeleccionada = idCategoria
    this.actividadSeleccionada = undefined
  }

  @action
  public setActividadSelect(idActividad: number) {
    this.actividadSeleccionada = idActividad
  }

  @action
  public setDefaultCategoria() {
    this.categoriaSeleccionada = undefined
  }

  async getCategoriasWorkouts() {
    return await withTokenGET<Result<CategoriaWorkoutDTO[]>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.workouts}`,
      this.appStore.getToken
    )
  }
}
