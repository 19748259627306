import React from 'react'
import { Route } from 'mobx-router'
import { Home } from './containers/Home/Home'
import { Recupera } from './containers/Recupera/Recupera'
import { EditarPerfil } from './containers/usuario/EditarPerfil'
import { Reservas } from './containers/usuario/Reservas'
import { FormaPago } from './containers/usuario/FormaPago'
import { Clases } from './containers/Clases/Clases'
import { Pago } from './containers/usuario/Pago'
import { Sedes } from './containers/Sedes/Sedes'
import { OnDemand } from './containers/OnDemand/OnDemand'
import { Regreting } from './containers/Regreting/Regreting'
import RRHH from './containers/RRHH/RRHH'
import { Contact } from './containers/Contact/Contact'
import { KidsAndTeens } from './containers/KidsAndTeens/KidsAndTeens'
import { Carrito } from './containers/usuario/Carrito'
import { Legales } from './containers/Legales/Legales'
import { Pautas } from './containers/Pautas/Pautas'
import FreePass from './containers/FreePass/FreePass'
import SucceedePage from './containers/FreePass/SucceedePage'
import SucceedeKidsAndTeens from './containers/KidsAndTeens/SucceedeKidsAndTeens'
import SucceedeRegretingPage from './containers/Regreting/SucceedePage'

const baseUrl = `${process.env.REACT_APP_CLASSES_SERVER}/api/service/class/`;

const generateSedeURL = (sedeNumber: number) => {
  return `${baseUrl}${sedeNumber}/pdf`;
};

const sedeToURL: { [sede: string]: string } = {
  alcorta: generateSedeURL(true ? 88 : 43),
  almagro: generateSedeURL(true ? 89 : 44),
  altopalermo: generateSedeURL(true ? 90 : 45),
  altorosario: generateSedeURL(true ? 91 : 46),
  ateneo: generateSedeURL(true ? 92 : 47),
  barracas: generateSedeURL(true ? 93 : 48),
  barrionorte: generateSedeURL(true ? 94 : 50),
  belgrano: generateSedeURL(true ? 95 : 51),
  caballito: generateSedeURL(true ? 96 : 52),
  center: generateSedeURL(true ? 98 : 55),
  center2: generateSedeURL(true ? 99 : 56),
  devoto: generateSedeURL(true ? 103 : 60),
  arcos: generateSedeURL(true ? 104 : 61),
  distritotecnologico: generateSedeURL(true ? 105 : 62),
  floresta: generateSedeURL(true ? 106 : 63),
  gonnet: generateSedeURL(true ? 107 : 64),
  laimprenta: generateSedeURL(true ? 108 : 65),
  martinez: generateSedeURL(true ? 109 : 66),
  martinez2: generateSedeURL(true ? 110 : 67),
  nunez: generateSedeURL(true ? 111 : 68),
  olivos: generateSedeURL(true ? 112 : 69),
  pilar: generateSedeURL(true ? 113 : 70),
  puertomadero: generateSedeURL(true ? 114 : 71),
  racing: generateSedeURL(true ? 115 : 72),
  recoleta: generateSedeURL(true ? 116 : 73),
  rosario: generateSedeURL(true ? 117 : 74),
  villacrespo: generateSedeURL(true ? 118 : 75),
  outdoor: generateSedeURL(true ? 132 : 0),
  cordoba: generateSedeURL(true ? 133 : 49),
  cordobacentro: generateSedeURL(true ? 134 : 57),
  cordobacerro: generateSedeURL(true ? 135 : 58),
};

export const privateRoutes = {
  home: new Route({
    path: '/',
    component: <Home />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  recupera: new Route({
    path: '/recupera',
    component: <Recupera />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  clases: new Route({
    path: '/clases',
    component: <Clases />,

    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  sedes: new Route({
    path: '/sedes',
    component: <Sedes />,

    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  FreePass: new Route({
    path: '/paseGratis',
    component: <FreePass />,

    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  SucceedeFreePassPage: new Route({
    path: '/success-confirmation',
    component: <SucceedePage />,

    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  SucceedeKidsAndTeensPage: new Route({
    path: '/success-kids-teens',
    component: <SucceedeKidsAndTeens userType={undefined} />,

    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),

  legales: new Route({
    path: '/legales',
    component: <Legales />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  pautas: new Route({
    path: '/pautas',
    component: <Pautas />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  rrhh: new Route({
    path: '/rrhh',
    component: <RRHH />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  contact: new Route({
    path: '/contact',
    component: <Contact />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  kidsandteens: new Route({
    path: '/kidsandteens',
    component: <KidsAndTeens />,
    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  perfil: new Route({
    path: '/mimegatlon/perfil',
    component: <EditarPerfil />,
    beforeEnter: () => {
      const userIsLoggedIn = !!sessionStorage.getItem('login')
      if (!userIsLoggedIn) window.location.replace('/')
    },
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  reservas: new Route({
    path: '/mimegatlon/reservas',
    component: <Reservas />,
    beforeEnter: () => {
      const userIsLoggedIn = sessionStorage.getItem('login')
      if (!userIsLoggedIn) window.location.replace('/')
    },
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  formaPago: new Route({
    path: '/mimegatlon/formapago',
    component: <FormaPago />,
    beforeEnter: () => {
      const userIsLoggedIn = sessionStorage.getItem('login')
      if (!userIsLoggedIn) window.location.replace('/')
    },
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  pago: new Route({
    path: '/pago',
    component: <Pago />,
    beforeEnter: () => {
      const userIsLoggedIn = sessionStorage.getItem('login')
      if (!userIsLoggedIn) window.location.replace('/')
    },
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  onDemand: new Route({
    path: '/onDemand',
    component: <OnDemand />,
    beforeEnter: () => {
      let userIsLoggedIn = sessionStorage.getItem('login')
      if (!userIsLoggedIn) window.location.replace('/')
    },
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  carrito: new Route({
    path: '/carrito',
    component: <Carrito />,
    beforeEnter: () => {
      const userIsLoggedIn = sessionStorage.getItem('login')
      if (!userIsLoggedIn) window.location.replace('/')
    },
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  arrepentimiento: new Route({
    path: '/Boton-de-Arrepentimiento',
    component: <Regreting />,
    onEnter: () => {
      window.scroll(0, 0)
    },
  }),
  SucceedeArrepentimientoPage: new Route({
    path: '/Boton-de-Arrepentimiento-Solicitud-Enviada',
    component: <SucceedeRegretingPage />,

    onEnter: () => {
      window.scrollTo(0, 0)
    },
  }),
  sedesDetalle: new Route({
    path: '/sedes/:id/horarios/descargar',
    component: <Sedes />,
    onEnter: (_route, _params, store) => {
      console.log(baseUrl);
      let sedeParam = store.router.params ?? {};
      let nombreSede = sedeParam.id as string
      if (sedeToURL.hasOwnProperty(nombreSede)) {
        window.location.href = sedeToURL[nombreSede]
      } else {
        window.location.href = '/'
      }
    },
  }),
  unknown: new Route({
    path: '**',
    component: <Home />,
  }),
};
