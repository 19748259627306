import {Token, SelectLV} from './types';
import {useState, useCallback, useEffect} from 'react';
import {ValueType} from 'react-select';

export const withTokenPOST = async <T>(url: string, token: Token, payload?: BodyInit | null, headers?: HeadersInit) => {
  return await withTokenReq<T>(url, 'POST', token, payload, headers);
};

export const withTokenGET = async <T>(url: string, token: Token, headers?: HeadersInit) => {
  return await withTokenReq<T>(url, 'GET', token, null, headers);
};

export const withTokenPUT = async <T>(url: string, token: Token, payload?: BodyInit | null, headers?: HeadersInit) => {
  return await withTokenReq<T>(url, 'PUT', token, payload, headers);
};

export const withTokenReq = async <T>(url: string, method: string, token: Token, payload?: BodyInit | null, headers?: HeadersInit) => {

  const defaultHeaders = {
    'Authorization': `Bearer ${token.access_token}`,
    'Access-Control-Allow-Origin':'*',
    'Accept': 'application/json',
    'Content-type': 'application/json'
  };
  const finalHeaders = Object.assign({}, defaultHeaders, headers);

  const req = await fetch(url,
    {
      headers: finalHeaders,
      method: method,
      body: payload
    }
  );
  if (req.status > 299 || req.status < 199) {
    let error = await req.json();
    throw Error(error.message);
  }

  return await req.json() as Promise<T>;
};

export const useSimpleForm = <S>(initialState: S): [S, (e: any) => void,(e: S) => void,  (key:any, value:any) => void  , (e: any) => void] => {
  const [formValues, setValues] = useState(initialState);
  const handleChange = useCallback(
    event => {
      const {name, value} = event.target;
      setValues(v => ({...v, [name]: value}));
    },
    [setValues]
  );

  const handleChangeOnlyNums = useCallback(
    event => {
      const {name, value} = event.target;
      if (value.length === 0 || !isNaN(parseInt(value))) {
        setValues(v => ({...v, [name]: value}));
      }
    }, [setValues]
  );

  const handleChangesOnSelect = useCallback(
    (key, value) => {
        setValues(v => ({...v, [key]: value}))
    }, [setValues]
  )

  return [formValues, handleChange,  setValues, handleChangesOnSelect, handleChangeOnlyNums];
};

// @ts-ignore
export const selectSingleMaker = <T>(onSelect: (v: T) => void, onUndefined: () => void): (v: ValueType<SelectLV<T>>, ) => void => {
  return v => {
    if (v && !Array.isArray(v)) {
      // @ts-ignore
      onSelect(v.value);
    } else {
      onUndefined();
    }
  }
};


export function checkForMobileDevice() {
  if(navigator.userAgent.includes('Mobi')) return true
  else return false
}

export function cycle<T>(array: T[], n: number): T[] {
  return array.splice(-n % array.length).concat(array);
}

export function rotate<T>(arr: T[], n: number): T[] {
  return arr.slice(n, arr.length).concat(arr.slice(0, n));
}

export const useLocationSearch = () => {
  const [path, setPath] = useState(window.location.search);
  const listenToPopstate = () => {
    const winPath = window.location.search;
    setPath(winPath);
  };
  useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);
  return path;
};
