import { AppStore } from './AppStore'
import { action, observable, autorun, computed, when } from 'mobx'
import { withTokenPOST, withTokenGET, withTokenPUT } from '../utils'
import { Token } from '../types'
import {
  UsuarioProfile,
  RegistroUsuarioRequest,
  Result,
  GenerarPasswordRequest,
  ActualizarPasswordRequest,
  ActualizarUsuarioRequest,
  UserDataResponse,
  FreeTrier,
} from '../lib/megaUsers'
import { ClubStore } from './ClubsStore'
import { contactMessagePerson } from '../lib/megaStore'

const urls = {
  oauth: '/oauth/token',
  profileMe: '/api/service/profile/me',
  confirmacionPassword: '/api/service/user/password/generate/confirm',
  restorePassword: '/api/service/user/password/retrieve',
  updatePassword: '/api/service/user/password/update',
  userRegister: '/api/service/user/register',
  updateProfile: '/api/service/user/update',
  baseProfile: '/api/service/profile/getUserBaseData',
  profileImage: '/api/service/profileImage',
  updateProfilePhoto: '/api/service/profile/photo/update',
  freeTrial: '/api/service/member/status',
  memberStatus: '/api/service/member/status',
  membership: '/api/service/workout/user/package/status',
  regreting: '/api/service/admin/special/actions/regreting/notify',
}

export class UserStore {
  @observable
  profile?: UsuarioProfile

  @observable
  baseProfile?: UserDataResponse

  @observable
  profileImage?: any

  @observable
  sedeSeleccionada: number = 0

  @observable
  modalOpen: boolean = false

  @observable
  modalRegreting: boolean = false

  @observable
  modalMoreInfo: boolean = false

  @observable
  name: string | undefined

  constructor(private appStore: AppStore, private clubStore: ClubStore) {
    autorun(() => {
      if (!!this.appStore.token) {
        this.retrieveProfile()
      }
    })
    when(
      () => this.clubStore.sedes.length > 0,
      () => {
        this.sedeSeleccionada = this.clubStore.sedes[0].clubId
      }
    )
  }

  @action
  async logIn(user: string, password: string) {
    const payload = new URLSearchParams()

    payload.append('username', user)
    payload.append('password', password)
    payload.append('grant_type', 'password')
    payload.append('app_version', '1.0')

    const token = await withTokenPOST<Token>(
      `${process.env.REACT_APP_USER_SERVER}${urls.oauth}`,
      this.appStore.getToken,
      payload,
      {
        Authorization: `Basic ${btoa('megatlon:user')}`,
        'Content-type': 'application/x-www-form-urlencoded',
      }
    )

    autorun(() => {
      this.getStatus().then((res) => {
        sessionStorage.setItem('playStat', JSON.stringify(res.result))
      })
    })

    sessionStorage.setItem('login', JSON.stringify(token))
    this.appStore.updateToken(token)
  }

  @action
  async freeTrier() {
    return await withTokenGET<Result<FreeTrier>>(
      `${process.env.REACT_APP_USER_SERVER}${urls.freeTrial}`,
      this.appStore.getToken
    )
  }

  @action
  async retrieveProfile() {
    Promise.all([
      withTokenGET<Result<UsuarioProfile>>(
        `${process.env.REACT_APP_USER_SERVER}${urls.profileMe}`,
        this.appStore.getToken
      ),
      this.getBaseProfile(),
      this.getProfileImage(),
    ]).then(([profile, baseProfile, profileImage]) => {
      this.profile = profile!.result
      this.baseProfile = baseProfile!.result
      if (profileImage.ok) {
        profileImage.blob().then((blob) => {
          this.profileImage = URL.createObjectURL(blob)
        })
      } else this.profileImage = null
    })
  }

  @action
  async memberStatus() {
    return await withTokenGET<Result<FreeTrier>>(
      `${process.env.REACT_APP_USER_SERVER}${urls.memberStatus}`,
      this.appStore.getToken
    )
  }

  @action
  async updateProfile(aur: ActualizarUsuarioRequest) {
    console.log(aur)
    /*const res = await this.updateProfileReq(aur);
    if (res.result) {
      this.profile = Object.assign({}, this.profile, aur);
    }*/
  }

  @action
  setName(newName: string) {
    this.name = newName
  }

  @action
  setSede(clubId: number) {
    this.sedeSeleccionada = clubId
  }

  @action
  setModalOpen() {
    this.modalOpen = !this.modalOpen
    console.log('Modal open state:', this.modalOpen)
  }

  @computed
  get isLoggedIn() {
    return !!sessionStorage.getItem('login')
  }

  @action
  setModalRegreting() {
    this.modalRegreting = !this.modalRegreting
  }

  @action
  setModalMoreInfo() {
    this.modalMoreInfo = !this.modalMoreInfo
  }
  @computed
  get userClubOrDefault(): number | undefined {
    return (this.sedeSeleccionada !== 0 && this.sedeSeleccionada) || this.baseProfile?.idClub || undefined
  }

  async signUp(up: RegistroUsuarioRequest) {
    return await this.userOp<Result>('userRegister', 'POST', JSON.stringify(up))
  }

  async restorePassword(rp: GenerarPasswordRequest) {
    return await this.userOp<Result<boolean>>('confirmacionPassword', 'POST', JSON.stringify(rp))
  }

  async requestRestorePassword(email: string) {
    return await withTokenGET<Result<boolean>>(
      `${process.env.REACT_APP_USER_SERVER}${urls.restorePassword}?email=${email}`,
      this.appStore.getToken
    )
  }

  async updatePassword(apr: ActualizarPasswordRequest) {
    return await this.userOp('updatePassword', 'PUT', JSON.stringify(apr))
  }

  async updateProfileReq(aur: ActualizarUsuarioRequest) {
    return await this.userOp<Result<boolean>>('updateProfile', 'PUT', JSON.stringify(aur))
  }

  async getBaseProfile() {
    return await this.userOp<Result<UserDataResponse>>('baseProfile', 'GET')
  }

  async getProfileImage() {
    return await fetch(`${process.env.REACT_APP_USER_SERVER}${urls.profileImage}`, {
      headers: {
        Accept: 'image/png;image/jpg',
        Authorization: `Bearer ${this.appStore.getToken.access_token}`,
      },
    })
  }

  async updateProfilePhoto(payload: FormData) {
    return await fetch(`${process.env.REACT_APP_USER_SERVER}${urls.profileImage}`, {
      body: payload,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.appStore.getToken.access_token}`,
      },
    })
  }

  async getStatus() {
    return await withTokenGET<Result<boolean>>(
      `${process.env.REACT_APP_CLASSES_SERVER}${urls.membership}`,
      this.appStore.getToken
    )
  }

  async setRegreting(formBody: contactMessagePerson) {
    return await withTokenPOST<Result<boolean>>(
      `${process.env.REACT_APP_USER_SERVER}/regretting`,
      this.appStore.getToken,
      JSON.stringify(formBody)
    )
  }

  async userOp<T>(url: keyof typeof urls, method: 'GET' | 'POST' | 'PUT', payload?: string) {
    switch (method) {
      case 'GET':
        return await withTokenGET<T>(`${process.env.REACT_APP_USER_SERVER}${urls[url]}`, this.appStore.getToken)
      case 'POST':
        return await withTokenPOST<T>(
          `${process.env.REACT_APP_USER_SERVER}${urls[url]}`,
          this.appStore.getToken,
          payload
        )
      case 'PUT':
        return await withTokenPUT<T>(
          `${process.env.REACT_APP_USER_SERVER}${urls[url]}`,
          this.appStore.getToken,
          payload
        )
    }
  }

  @computed
  get getPerfil(): UsuarioProfile {
    if (this.profile) {
      return this.profile
    } else {
      throw Error('Not logged in')
    }
  }
}
